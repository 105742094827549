/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFeed } from '@creative/elements/feed/feeds.utils';
import {
    ISerializedVersionProperty,
    IVersionProperty,
    IWidgetText,
    OneOfVersionableProperties
} from '@domain/creativeset/version';
import { FeededReference, IFeed, IFeedStep } from '@domain/feed';
import { IFontStyle, SerializedFontStyle } from '@domain/font';
import { OneOfDataNodeValues } from '@domain/nodes';
import {
    INLINE_STYLED_TEXT,
    OneOfElementPropertyKeys,
    propertyToUnitMap,
    WidgetCustomPropertyKeys,
    widgetCustomPropertyToUnitMap
} from '@domain/property';
import {
    IBorder,
    IPadding,
    IShadow,
    ITextShadow,
    SerializedShadow,
    SerializedTextShadow
} from '@domain/style';
import { isUrl } from '@studio/utils/url';
import { Color } from '../color';
import { parseColor } from '../color.utils';
import {
    deserializeVersionedText,
    serializeInlineStyledText,
    serializeVersionedText
} from './text-serializer';
import { serializeWidgetTextValue } from './versions/widgetText-serializer';

export function splitSerializedShadowValue<T extends SerializedShadow | SerializedTextShadow>(
    str: string
): T {
    return str.split(/,(?![^(]*\))/) as T;
}

export function deserializePropertyValue(
    propertyName: OneOfElementPropertyKeys | WidgetCustomPropertyKeys | string,
    value: any,
    isKeyFrame = false
): OneOfDataNodeValues {
    const unit =
        propertyToUnitMap[propertyName as OneOfElementPropertyKeys] ||
        widgetCustomPropertyToUnitMap[propertyName];

    if (value === null) {
        value = undefined;
    }

    // Should maybe not affect all types?
    if (typeof value === 'string' && isKeyFrame) {
        return value;
    }
    switch (unit) {
        case 'number':
            return parseFloat(value);
        case 'boolean':
            return typeof value === 'string' ? value.toLowerCase() === 'true' : value;
        case 'FontStyle': {
            if (value === undefined) {
                return undefined;
            }
            const fontStyle = (
                typeof value === 'string' ? value.split(' ') : []
            ) as SerializedFontStyle;
            let src = fontStyle[3];
            try {
                // Support old font url that where encoded with base64 without data prefix.
                //
                // Note: that exported HTML creatives can have base64 encoded fonts which begins with data prefix.
                if (!src.startsWith('data') && !isUrl(src)) {
                    if (typeof window !== 'undefined') {
                        src = atob(src);
                    } else {
                        src = Buffer.from(src, 'base64').toString();
                    }
                }
            } catch {
                // eslint-disable-next-line no-empty
            }

            return {
                id: fontStyle[0],
                weight: parseInt(fontStyle[1], 10),
                style: fontStyle[2],
                src,
                fontFamilyId: fontStyle[4]
            } satisfies IFontStyle;
        }
        case 'ImageAsset': {
            try {
                value = JSON.parse(value);
                if (typeof value === 'object') {
                    return value;
                }
            } catch {
                const imageAsset = (value as string).split(' ');

                // old images are missing gen ai flag. We have to detect that in order
                // to know if name is on 4th or 5th position
                const hasGenAiFlag = imageAsset[4]?.match(/^(true|false|undefined)$/);
                return {
                    id: imageAsset[0],
                    url: imageAsset[1],
                    width: parseInt(imageAsset[2], 10),
                    height: parseInt(imageAsset[3], 10),
                    isGenAi: !!hasGenAiFlag && imageAsset[4] === 'true',
                    name: hasGenAiFlag ? imageAsset[5] : imageAsset[4]
                };
            }
            break;
        }
        case 'Padding': {
            const padding = value.split(' ') || [];
            return {
                top: padding[0] ? parseFloat(padding[0]) : 0,
                right: padding[1] ? parseFloat(padding[1]) : 0,
                bottom: padding[2] ? parseFloat(padding[2]) : 0,
                left: padding[3] ? parseFloat(padding[3]) : 0
            } as IPadding;
        }
        case 'Border': {
            const border = value.split(' ');
            return border.length > 1
                ? ({
                      thickness: parseInt(border[0], 10),
                      style: border[1],
                      color: border[2]
                  } as IBorder)
                : undefined;
        }
        case 'Radius':
            return JSON.parse(value);
        case 'Shadow[]': {
            const shadows = value ? splitSerializedShadowValue<SerializedShadow>(value) : undefined;
            return shadows
                ? shadows.map((s: string) => {
                      const shadow = s.split(' ') as SerializedShadow;
                      return {
                          offsetX: parseInt(shadow[0], 10),
                          offsetY: parseInt(shadow[1], 10),
                          blur: parseInt(shadow[2], 10),
                          spread: parseInt(shadow[3], 10),
                          color: parseColor(shadow[4])
                      } as IShadow;
                  })
                : undefined;
        }
        case 'TextShadow[]': {
            if (Array.isArray(value)) {
                return value;
            }
            const textShadows = value
                ? splitSerializedShadowValue<SerializedTextShadow>(value)
                : undefined;
            return textShadows
                ? textShadows.map((s: string) => {
                      const shadow = s.split(' ') as SerializedTextShadow;
                      return {
                          offsetX: parseInt(shadow[0], 10),
                          offsetY: parseInt(shadow[1], 10),
                          blur: parseInt(shadow[2], 10),
                          color: parseColor(shadow[3])
                      } as ITextShadow;
                  })
                : undefined;
        }
        case 'Color':
            return parseColor(value);
        case 'Feed':
        case 'variable':
            return deserializeFeedString(value);
        case 'CustomProperty[]': {
            let decodedValue: string;
            if (typeof window !== 'undefined') {
                decodedValue = JSON.parse(atob(value));
            } else {
                decodedValue = Buffer.from(value, 'base64').toString();
            }
            return decodedValue;
        }
        case 'FilterMap':
        case 'ImageSettings':
        case 'State[]':
        case 'Animation[]':
        case 'VideoAsset':
        case 'VideoSettings':
        case 'Action[]':
            if (typeof value === 'string') {
                return value.length ? JSON.parse(value) : value;
            }
            return value;
        case 'text': {
            // Elements doesn't have "content" and value will instead be fetched from Version.properties
            if (value === undefined) {
                return undefined;
            }
            return typeof value === 'string' ? deserializeVersionedText(value) : value;
        }
        case 'WidgetText':
        case 'widgetText': {
            if (typeof value === 'string') {
                return value.length ? JSON.parse(value) : value;
            }
            return value;
        }
        default:
            return value;
    }
}

/**
 * Serialize propertyValue to a string
 * @param propertyName
 * @param value
 */
export function serializePropertyValue(propertyName: string, value: any): string | undefined {
    let unit =
        propertyToUnitMap[propertyName as OneOfElementPropertyKeys] ||
        widgetCustomPropertyToUnitMap[propertyName];

    if (propertyName === 'id' || propertyName === 'kind' || propertyName === FeededReference.Image) {
        unit = 'string';
    }

    switch (unit) {
        case 'number':
            return `${value}`;
        case 'string':
        case 'enum':
            return value;
        case 'boolean':
            return value.toString();
        case 'FontStyle': {
            const fontStyle = value as IFontStyle;
            const src = fontStyle.src;
            return `${fontStyle.id} ${fontStyle.weight} ${fontStyle.style} ${src} ${fontStyle.fontFamilyId}`;
        }
        case 'Border': {
            const border = value as IBorder;
            return border ? `${border.thickness} ${border.style} ${border.color}` : '';
        }
        case 'Padding': {
            const padding = value as IPadding;
            return `${padding.top} ${padding.right || ''} ${padding.bottom || ''} ${
                padding.left || ''
            }`;
        }
        case 'Shadow[]': {
            const shadows = value as IShadow[];
            return shadows
                ? shadows
                      .map(s => `${s.offsetX} ${s.offsetY} ${s.blur} ${s.spread} ${s.color}`)
                      .join(',')
                : '';
        }
        case 'TextShadow[]': {
            const textShadow = value as ITextShadow[] | undefined;
            return textShadow
                ? textShadow.map(s => `${s.offsetX}px ${s.offsetY}px ${s.blur}px ${s.color}`).join(',')
                : '';
        }
        case 'Color': {
            const color = value as Color;
            if (!color) {
                return '';
            } else {
                return color.toString();
            }
        }
        case INLINE_STYLED_TEXT:
            return serializeInlineStyledText(value);
        case 'text':
            return serializeVersionedText(value);
        case 'WidgetText':
        case 'widgetText': // VP widgetText
            return serializeWidgetTextValue(value);
        case 'variable':
            if (typeof value === 'object') {
                const variable = { ...value, type: 'text' };
                return JSON.stringify(variable);
            }
            return value;
        case 'Feed':
            return serializeFeedValue(value);
        case 'CustomProperty[]': {
            let encodedValue: string | undefined;

            const trimmedValue = Array.isArray(value)
                ? value.map(val => ({ ...val, value: val.value.trim() }))
                : value;
            if (typeof window !== 'undefined') {
                encodedValue = btoa(JSON.stringify(trimmedValue));
            } else {
                encodedValue = Buffer.from(trimmedValue).toString('base64');
            }
            return encodedValue;
        }
        case 'FilterMap':
        case 'State[]':
        case 'Animation[]':
        case 'Action[]':
        case 'ImageSettings':
        case 'VideoSettings':
        case 'VideoAsset':
        case 'ImageAsset':
        case 'Radius':
            return JSON.stringify(value);
        default:
            return value;
    }
}

export function deserializeVersionProperty(
    versionProperty: IVersionProperty | ISerializedVersionProperty
): IVersionProperty {
    let newValue = deserializePropertyValue(
        versionProperty.name,
        versionProperty.value
    ) as OneOfVersionableProperties;
    if (!newValue) {
        throw new Error(`Couldn't deserialize version property`);
    }
    if (versionProperty.name === 'widgetText') {
        newValue = {
            text: (newValue as IWidgetText).text
        };
    }
    return {
        id: versionProperty.id,
        name: versionProperty.name,
        value: newValue
    };
}

export function deserializeVersionProperties(
    versionProperties: Array<IVersionProperty | ISerializedVersionProperty> = []
): IVersionProperty[] {
    return versionProperties.map(versionProperty => deserializeVersionProperty(versionProperty));
}

export function deserializeFeedString(value: unknown): IFeed {
    if (!!value && typeof value !== 'string' && typeof value === 'object' && 'path' in value) {
        return value as IFeed;
    }

    if (typeof value === 'string') {
        try {
            const feed = JSON.parse(value);
            if (!('path' in feed)) {
                // Continue to old deserialization
                throw new Error();
            }

            return feed;
        } catch (_) {
            const feedValues = value.split(' ');
            const feed: IFeed = {
                id: feedValues[0],
                path: feedValues[1] ?? '',
                step: {
                    start: parseInt(feedValues[2] ?? 1, 10),
                    size: parseInt(feedValues[3] ?? 1, 10),
                    occurrence: (feedValues[4] ?? 'loop') as IFeedStep['occurrence']
                },
                fallback: feedValues[5] ?? '',
                type: 'text'
            };
            return feed;
        }
    }

    return createFeed('');
}

export function serializeFeedValue(feed: unknown): string {
    if (typeof feed === 'object') {
        return JSON.stringify(feed);
    }

    if (typeof feed === 'string') {
        return feed;
    }

    return JSON.stringify(createFeed(''));
}
