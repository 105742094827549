import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Role, UserPermission } from '@domain/user';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';

const forbiddenEditRoles = [
    Role.TextEditor,
    Role.TextEditorExtended,
    Role.Publisher,
    Role.Analyzer,
    Role.PublisherExtended
];

@Injectable({ providedIn: 'root' })
export class UserService {
    canChangeDesign = false;
    loaded$ = this.store.select(UserSelectors.getUserLoaded);
    error$ = this.store.select(UserSelectors.getUserError);
    isEmployee$ = this.store.pipe(UserSelectors.getIsEmployeeWhenLoaded);
    role$ = this.store.select(UserSelectors.getRole);
    private permissions$ = this.store.pipe(UserSelectors.getPermissionsWhenLoaded);
    private userRole: Role | undefined;

    constructor(private store: Store) {
        this.role$.subscribe(userRole => {
            this.userRole = userRole;
            this.canChangeDesign = !userRole || !forbiddenEditRoles.includes(userRole);
        });
    }

    loadUser(accountSlug: string): void {
        this.store.dispatch(UserActions.loadUser({ accountSlug }));
    }

    getIsEmployee(): Signal<boolean> {
        return toSignal(this.isEmployee$, { initialValue: false });
    }

    /**
     * This will wait until the user is loaded and then return its permissions.
     * In showcase mode, the user is not loaded,and defaults to an empty array.
     */
    async getPermissions(): Promise<UserPermission[]> {
        return firstValueFrom(this.permissions$);
    }

    /**
     * Be aware this will return false in showcase mode, the user is not loaded and hence no permissions.
     */
    async hasPermission(permission: UserPermission, requireEmployee?: boolean): Promise<boolean> {
        const userPermissions = await this.getPermissions();

        return requireEmployee
            ? userPermissions.includes(permission) && (await this.isEmployee())
            : userPermissions.includes(permission);
    }

    hasRoleAccess(roles: Role[]): boolean {
        return roles.some(role => role === this.userRole);
    }

    /**
     * This will wait until the user is loaded and then return the isEmployee flag.
     * In showcase mode, the user is not loaded and defaults to false.
     */
    async isEmployee(): Promise<boolean> {
        return firstValueFrom(this.isEmployee$);
    }
}
