import { serializePropertyValue } from '@creative/serialization/property-value-serializer';
import { ZBrandElement } from '@domain/brand/brand-library';
import { zodOptional } from '@zod/zod';
import { z } from 'zod';

const ZCreationElement = ZBrandElement.omit({
    id: true,
    created: true,
    modified: true
});

const ZUpdateElement = ZBrandElement.omit({
    created: true,
    modified: true
});

type ElementCreateOrUpdate = z.infer<typeof ZCreationElement> | z.infer<typeof ZUpdateElement>;

function serializeBrandlibraryElementProperties<Element extends ElementCreateOrUpdate>(
    element: Element
): Element {
    element.properties.forEach(
        property => (property.value = serializePropertyValue(property.name, property.value))
    );

    return element;
}

export const ZElementCreationVariables = z.object({
    element: ZCreationElement.transform(serializeBrandlibraryElementProperties),
    originalElementId: zodOptional(z.string()),
    brandId: z.string()
});

export const ZElementUpdateVariables = z.object({
    element: ZUpdateElement.transform(serializeBrandlibraryElementProperties),
    brandId: z.string()
});

export const ZCreateFolderVariables = z.object({
    name: z.string(),
    brandLibraryId: z.string(),
    parentFolderId: zodOptional(z.string())
});
export type CreateElementInBrandLibraryVariables = z.infer<typeof ZElementCreationVariables>;
export type UpdateElementInBrandLibraryVariables = z.infer<typeof ZElementUpdateVariables>;
export type CreateBrandLibraryFolderVariables = z.infer<typeof ZCreateFolderVariables>;
