import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIConfirmDialogService, UINotificationService } from '@bannerflow/ui';
import { deserializeImageAsset, deserializeVideoAsset } from '@data/deserialization/asset';
import { ImageAssetDtoV2, VideoAssetDtoV2 } from '@domain/api/generated/sapi';
import {
    ImageLibraryAsset,
    INewBrandLibraryElement,
    OneOfLibraryAssets,
    VideoLibraryAsset
} from '@domain/brand/brand-library';
import { INewElement } from '@domain/creativeset';
import { ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { createBrandlibraryElement } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';
import { filterSVGFiles, isImageFile, isMP4File, isVideoFile } from '@studio/utils/media';
import { loadImageFile, loadVideoFile } from '@studio/utils/upload';
import { removeFileExtension } from '@studio/utils/url';
import { filter, firstValueFrom, Subject, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { UserService } from '../../../shared/user/state/user.service';
import { HeavyVideoService } from '../video';

export interface IUploadAssetOptions {
    files: File[];
    parentFolderId?: string;
    shouldUpdateMediaLibrary?: boolean;
    isDirectCanvasUpload?: boolean;
    isGenAi?: boolean;
}

interface ICreateUploadRequest {
    asset: OneOfLibraryAssets;
    element: NewUploadElement;
    file: File;
    formData: FormData;
    isGenAi?: boolean;
}

type NewUploadElement = INewElement | INewBrandLibraryElement;

export interface IPlaceholderElement
    extends INewElement,
        Pick<INewBrandLibraryElement, 'parentFolderId'> {
    progress?: number;
}

export interface IUploadElement {
    element: NewUploadElement;
    placeholder: IPlaceholderElement;
}

export type OneOfMediaFileUploadDtos = ImageAssetDtoV2 | VideoAssetDtoV2;

interface IAssetUploadStateBase {
    element: NewUploadElement;
    uploadProcessId: string;
    shouldUpdateMediaLibrary: boolean;
}

export interface IAssetUploadLoadedState extends IAssetUploadStateBase {
    status: 'LOAD_ASSET_FILE_STARTED';
    size: ISize;
    file: File;
    url: string;
    asset: OneOfLibraryAssets;
}

export interface IAssetUploadAfterProgressState extends IAssetUploadStateBase {
    status: 'AFTER_PROGRESS';
    newAsset: OneOfLibraryAssets;
    asset: OneOfLibraryAssets;
}

export interface IAssetUploadInProgressState extends IAssetUploadStateBase {
    status: 'IN_PROGRESS';
    progress: number;
    asset: OneOfLibraryAssets;
}

export interface IAssetUploadFailState extends IAssetUploadStateBase {
    status: 'FAIL';
    isLastUploadFail: boolean;
    file: File;
    asset: OneOfLibraryAssets;
}

export interface IAssetUploadCompleteState extends IAssetUploadStateBase {
    status: 'COMPLETE';
    placeholder: IPlaceholderElement;
}

export type IAssetUploadState =
    | IAssetUploadAfterProgressState
    | IAssetUploadInProgressState
    | IAssetUploadFailState
    | IAssetUploadCompleteState
    | IAssetUploadLoadedState;

@Injectable({ providedIn: 'root' })
export class AssetUploadService {
    private _uploadProgress$ = new Subject<IAssetUploadState>();
    uploadProgress$ = this._uploadProgress$.asObservable();
    private uploadQueue: IUploadElement[] = [];
    private queueProcessing = false;
    private uploadInProgress = false;
    private filesLoadFailCount = 0;
    private filesCompletFailCount = 0;
    private files: File[] = [];
    private uploadProcessId = '';
    private shouldUpdateMediaLibrary = false;

    private get UPLOAD_BASE_URL(): string {
        return `${environment.origins.sapi}/asset/upload`;
    }

    private get IMAGE_UPLOAD_URL(): string {
        return `${this.UPLOAD_BASE_URL}/image`;
    }

    private get VIDEO_UPLOAD_URL(): string {
        return `${this.UPLOAD_BASE_URL}/video`;
    }

    private logger = new Logger('AssetUploadService');

    constructor(
        private creativesetDataService: CreativesetDataService,
        private uiNotificationService: UINotificationService,
        private http: HttpClient,
        private uiConfirmDialogService: UIConfirmDialogService,
        private heavyVideoService: HeavyVideoService,
        private userService: UserService,
        private brandLibraryDataService: BrandLibraryDataService
    ) {
        this.uploadProgress$.pipe(takeUntilDestroyed()).subscribe(({ status }) => {
            if (status === 'LOAD_ASSET_FILE_STARTED') {
                this.uploadInProgress = true;
            }
            if (status === 'COMPLETE' || status === 'FAIL') {
                this.uploadInProgress = false;
            }
        });
    }

    private reset(): void {
        this.queueProcessing = false;
        this.uploadQueue = [];
        this.files = [];
        this.filesLoadFailCount = 0;
        this.filesCompletFailCount = 0;
    }

    private onHandleError(imageId: string, message = 'Could not upload asset'): void {
        this.uiNotificationService.open(message, {
            type: 'error',
            placement: 'top',
            autoCloseDelay: 5000
        });
        if (this.uploadInProgress && this.brandLibraryDataService.brandLibrary) {
            const failedImageUploads = this.brandLibraryDataService.brandLibrary.images.filter(
                image => image.id !== imageId
            );
            this.brandLibraryDataService.mutateData('images', failedImageUploads);
        }
    }

    async getRejectedSVGFiles(files: File[]): Promise<File[]> {
        let rejectedFiles: File[] = [];

        const heavySVGs = files.filter(file => file.size * Math.pow(2, -10) > 50);

        if (heavySVGs.length) {
            const result = await this.uiConfirmDialogService.confirm({
                headerText: 'SVG IMAGE SIZE WARNING',
                confirmText: 'Proceed anyway',
                discardText: 'Cancel upload',
                text: 'Your SVG file is larger than 50 kb. Please note that SVG files are not optimized or compressed so the full image file weight will be added to your creative.'
            });

            if (result !== 'confirm') {
                rejectedFiles = heavySVGs;
            }
        }
        return rejectedFiles;
    }

    async uploadAssets(uploadOptions: IUploadAssetOptions): Promise<string | void> {
        const { parentFolderId, shouldUpdateMediaLibrary, isGenAi } = uploadOptions;
        const brandLibrary = this.brandLibraryDataService.brandLibrary;
        let { files } = uploadOptions;

        if (this.uploadInProgress) {
            this.uiNotificationService.open(
                `Please wait for current upload to finish before uploading new files.`,
                {
                    type: 'warning',
                    placement: 'top',
                    autoCloseDelay: 5000
                }
            );
            return;
        }

        const svgFiles = filterSVGFiles(files);
        if (svgFiles.length) {
            const rejectedSVGs = await this.getRejectedSVGFiles(svgFiles);
            files = files.filter(file => !rejectedSVGs.includes(file));
        }

        files = await this.heavyVideoService.promptRemoveVideoUploads(
            files,
            uploadOptions.isDirectCanvasUpload
        );

        if (files.length === 0) {
            // Check instance to not let it override other notification
            if (!this.uiNotificationService.notificationRef?.instance) {
                this.uiNotificationService.open(`No files to upload.`, {
                    type: 'warning',
                    placement: 'top',
                    autoCloseDelay: 5000
                });
            }
            return;
        }

        this.uploadProcessId = uuidv4();
        this.files = files;

        this.shouldUpdateMediaLibrary = !!shouldUpdateMediaLibrary;

        for (const file of files) {
            // Create a dummy imageAsset so that we have anything to reference in the element while uploading
            const id = uuidv4();
            const {
                brand: { id: brandId }
            } = this.creativesetDataService;

            let asset: OneOfLibraryAssets;

            const isImage = isImageFile(file);
            const isVideo = isVideoFile(file);

            if (isImage) {
                asset = this.newImageLibraryAsset(id, isGenAi);

                if (shouldUpdateMediaLibrary) {
                    if (!brandLibrary) {
                        throw new Error(
                            'Could not update in Brand libary. Brand library is not yet initialized.'
                        );
                    }

                    const images = [...brandLibrary.images, asset];
                    this.brandLibraryDataService.mutateData('images', images);
                }
            } else if (isVideo) {
                if (!(await this.userService.hasPermission('StudioVideoLibrary'))) {
                    return;
                }

                asset = this.newVideoLibraryAssset(id);

                if (shouldUpdateMediaLibrary) {
                    if (!brandLibrary) {
                        throw new Error(
                            'Could not update in Brand libary. Brand library is not yet initialized.'
                        );
                    }

                    const videos = [...brandLibrary.videos, asset];
                    this.brandLibraryDataService.mutateData('videos', videos);
                }
            } else {
                this.uploadInProgress = false;
                throw new Error('No valid file to upload.');
            }

            const { id: value } = asset;

            const type = isImage ? ElementKind.Image : ElementKind.Video;

            const element = createBrandlibraryElement({
                type,
                name: removeFileExtension(file.name),
                parentFolderId,
                created: new Date().toISOString(),
                properties: [
                    {
                        name: `${type}Reference`,
                        unit: 'id',
                        value,
                        versionPropertyId: undefined
                    }
                ]
            });

            this.logger.verbose(`Loading asset file[${file.name}]`);
            const loaded = (base64Data: string, size: ISize, formData: FormData): void => {
                this.logger.verbose(`${file.name} loaded.`);

                this._uploadProgress$.next({
                    status: 'LOAD_ASSET_FILE_STARTED',
                    shouldUpdateMediaLibrary: this.shouldUpdateMediaLibrary,
                    element,
                    asset,
                    size,
                    file,
                    url: base64Data,
                    uploadProcessId: this.uploadProcessId
                });
                this.uploadAsset({ asset, element, file, formData, isGenAi });
            };
            if (type === ElementKind.Image) {
                loadImageFile({
                    file,
                    brandId,
                    loaded,
                    error: message => {
                        this.onHandleFailedAssetUpload();
                        this.onPostError(message, asset, element, file);
                    }
                });
            } else if (type === ElementKind.Video) {
                const allowHeavyVideos = await this.userService.hasPermission('AllowHeavyVideos');

                loadVideoFile({
                    file,
                    brandId,
                    allowHeavyVideos,
                    loaded,
                    error: message => {
                        this.onHandleFailedAssetUpload();
                        this.onPostError(message, asset, element, file);
                    }
                });
            }
        }
        return this.uploadProcessId;
    }

    private onHandleFailedAssetUpload(): void {
        this.filesLoadFailCount++;
        if (!this.queueProcessing && this.files.length === this.filesLoadFailCount) {
            this.reset();
        }
    }

    private uploadAsset({ asset, element, file, formData, isGenAi }: ICreateUploadRequest): void {
        this.logger.verbose('Creating upload request');
        const isMP4Video = isMP4File(file);
        const isImage = isImageFile(file);

        if (isImage && isGenAi) {
            formData.set('isGenAi', 'true');
        }

        if (!isImage && !isMP4Video) {
            throw new Error('Invalid asset type. Only image and MP4 video assets can be uploaded.');
        }

        const url = isMP4Video ? this.VIDEO_UPLOAD_URL : this.IMAGE_UPLOAD_URL;
        this.http
            .post<OneOfMediaFileUploadDtos>(url, formData, {
                reportProgress: true,
                observe: 'events'
            })
            .pipe(
                tap(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round((100 * event.loaded) / (event.total || 1));
                        (element as IPlaceholderElement).progress = percentDone;
                        this._uploadProgress$.next({
                            status: 'IN_PROGRESS',
                            asset,
                            element,
                            shouldUpdateMediaLibrary: false,
                            uploadProcessId: this.uploadProcessId,
                            progress: percentDone
                        });
                    }
                }),
                filter(event => event.type === HttpEventType.Response)
            )
            .subscribe({
                next: async event => {
                    if (event.type === HttpEventType.Response && event.body) {
                        await this.onAssetUpload(event.body, file, asset, element);
                    }
                },
                error: (error: unknown) => this.onPostError(error, asset, element, file)
            });
    }

    private async onAssetUpload(
        body: OneOfMediaFileUploadDtos,
        file: File,
        asset: OneOfLibraryAssets,
        element: INewElement | INewBrandLibraryElement
    ): Promise<void> {
        const isImage = isImageFile(file);
        const isVideo = isVideoFile(file);

        if (isImage) {
            const newAsset = deserializeImageAsset(body as ImageAssetDtoV2);
            this.uploadFinished(asset, newAsset, ElementKind.Image, file, element);
        } else if (isVideo) {
            const newAsset = deserializeVideoAsset(body as VideoAssetDtoV2);
            this.uploadFinished(asset, newAsset, ElementKind.Video, file, element);
        }
    }

    private onPostError(
        error: unknown,
        asset: OneOfLibraryAssets,
        element: IPlaceholderElement,
        file: File
    ): void {
        this.filesCompletFailCount++;
        const errorMessage = this.determineErrorMessage(error);
        this.onHandleError(asset.id, errorMessage);

        if (!this.queueProcessing && this.files.length === this.filesCompletFailCount) {
            this.reset();
        }

        this._uploadProgress$.next({
            status: 'FAIL',
            shouldUpdateMediaLibrary: this.shouldUpdateMediaLibrary,
            file,
            element,
            asset,
            isLastUploadFail: !this.uploadInProgress,
            uploadProcessId: this.uploadProcessId
        });
    }

    private uploadFinished(
        originalAsset: OneOfLibraryAssets,
        newAsset: OneOfLibraryAssets,
        type: ElementKind.Image | ElementKind.Video,
        file: File,
        placeholderElement: IPlaceholderElement
    ): void {
        this.logger.verbose('Upload request done');

        const newElement = createBrandlibraryElement({
            type,
            name: removeFileExtension(file.name),
            parentFolderId: placeholderElement.parentFolderId,
            properties: [
                {
                    name: `${type}Reference`,
                    unit: 'id',
                    value: newAsset.id,
                    versionPropertyId: undefined
                }
            ]
        });

        const target: IUploadElement = {
            element: newElement,
            placeholder: placeholderElement
        };
        this._uploadProgress$.next({
            status: 'AFTER_PROGRESS',
            shouldUpdateMediaLibrary: this.shouldUpdateMediaLibrary,
            element: newElement,
            asset: originalAsset,
            newAsset,
            uploadProcessId: this.uploadProcessId
        });
        this.uploadQueue.push(target);
        this.createMediaElements();
    }

    private async createMediaElements(): Promise<void> {
        if (this.queueProcessing) {
            return;
        }
        this.queueProcessing = true;
        while (this.uploadQueue.length) {
            const target: IUploadElement = this.uploadQueue[0];

            if (this.shouldUpdateMediaLibrary) {
                await firstValueFrom(this.brandLibraryDataService.createElement(target.element));
            }

            this._uploadProgress$.next({
                status: 'COMPLETE',
                ...target,
                shouldUpdateMediaLibrary: this.shouldUpdateMediaLibrary,
                uploadProcessId: this.uploadProcessId
            });
            this.uploadQueue.shift();
        }
        this.reset();
        return Promise.resolve();
    }

    private newImageLibraryAsset(id: string, isGenAi = false): ImageLibraryAsset {
        return {
            id,
            thumbnail: {
                url: '',
                width: 0,
                height: 0
            },
            original: {
                url: '',
                width: 0,
                height: 0
            },
            url: '',
            width: 0,
            height: 0,
            created: new Date().toISOString(),
            fileSize: 0,
            isGenAi,
            name: ''
        };
    }

    private newVideoLibraryAssset(id: string): VideoLibraryAsset {
        return {
            id,
            thumbnail: {
                url: '',
                width: 0,
                height: 0
            },
            url: '',
            width: 0,
            height: 0,
            fileSize: 1,
            name: '',
            created: new Date().toISOString(),
            durationInMilliseconds: 0
        };
    }

    private determineErrorMessage(error: unknown): string {
        if (error instanceof HttpErrorResponse) {
            return error.error;
        } else if (typeof error === 'string') {
            return error;
        } else {
            return 'Could not upload asset';
        }
    }
}
