import { HTTP_INTERCEPTORS, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ApolloClientOptions, ApolloLink, from, InMemoryCache } from '@apollo/client/core';
import { provideAuth0 } from '@auth0/auth0-angular';
import { provideSentinelService, withLoggerConfig, withNewRelic } from '@bannerflow/sentinel';
import { LogLevelType } from '@bannerflow/sentinel-logger';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RelationValidationEvent } from '@studio/monitoring/events';
import { Container } from '@studio/utils/di';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AUTH0_CONFIG } from './core/auth/auth.config';
import { CoreModule } from './core/core.module';
import { Error404Component, MaintenanceComponent, NavigationGuard, OopsComponent } from './pages';
import { ListHubService } from './pages/manage-view/duplicate-creatives/list-hub.service';
import { metaReducers } from './pages/state/local-storage';
import { routes } from './routes/app.routes';
import { CreativesetShowcaseComponent } from './shared/creativeset/creativeset-showcase.component';
import { CreativesetComponent } from './shared/creativeset/creativeset.component';
import { StudioHubService } from './shared/services/studio-hub.service';
import { TestSignalRHubService } from './shared/services/test-signalR-hub.service';
import { SharedModule } from './shared/shared.module';
import { StoresModule } from './stores.module';
import { AuthInterceptor } from './core/auth/auth.interceptor';

function getLogLevelParam(): LogLevelType {
    const url = new URL(window.location.href);
    const logLevel = url.searchParams.get('log') || '';
    return logLevel as LogLevelType;
}

@NgModule({
    declarations: [
        // Bootstrapping
        AppComponent,
        CreativesetComponent,
        CreativesetShowcaseComponent,

        // Misc Pages
        Error404Component,
        OopsComponent,
        MaintenanceComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ApolloModule,
        CoreModule,
        SharedModule,
        StoreModule.forRoot(
            {},
            {
                metaReducers,
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true
                }
            }
        ),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            logOnly: environment.production
        }),
        StoreRouterConnectingModule.forRoot(),
        StoresModule,
        RouterModule.forRoot(routes)
    ],
    bootstrap: [AppComponent],
    providers: [
        provideAuth0(AUTH0_CONFIG),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: Container,
            useValue: new Container()
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: (
                httpLink: HttpLink,
                studioHubService: StudioHubService
            ): ApolloClientOptions<any> => {
                return {
                    cache: new InMemoryCache({
                        typePolicies: {
                            // Same ID doesn't mean same property
                            VersionPropertyType: { keyFields: false }
                        }
                    }),
                    link: from([
                        new ApolloLink((operation, forward) => {
                            operation.setContext({
                                headers: new HttpHeaders().set(
                                    'BF-Studio-SignalR-ConnectionId',
                                    studioHubService.connectionId || ''
                                )
                            });

                            return forward(operation);
                        }),
                        httpLink.create({ uri: `${environment.origins.sapi}/graphql` })
                    ]),
                    connectToDevTools: true,
                    defaultOptions: {
                        mutate: {
                            fetchPolicy: 'no-cache'
                        },
                        query: {
                            fetchPolicy: 'no-cache'
                        }
                    }
                };
            },
            deps: [HttpLink, StudioHubService]
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: (httpLink: HttpLink): Record<string, ApolloClientOptions<any>> => {
                return {
                    showcase: {
                        name: 'showcase',
                        link: httpLink.create({ uri: `${environment.origins.sapi}/graphql/showcase` }),
                        cache: new InMemoryCache({
                            typePolicies: {
                                // Same ID doesn't mean same property
                                VersionPropertyType: { keyFields: false }
                            }
                        })
                    }
                };
            },
            deps: [HttpLink]
        },
        {
            provide: ListHubService,
            useClass: environment.stage === 'test' ? TestSignalRHubService : ListHubService
        },
        Title,
        NavigationGuard,
        provideSentinelService(
            withLoggerConfig({
                level: getLogLevelParam(),
                production: environment.production,
                sandbox: environment.sandbox,
                stage: environment.stage
            }),
            withNewRelic({
                ...environment.newrelic,
                origins: Object.values(environment.origins),
                applicationVersion: environment.build.commit,
                releaseId: environment.build.commit,
                releaseName: environment.build.commit,
                ignoredErrors: error => {
                    if (error instanceof RelationValidationEvent) {
                        return true;
                    }

                    return false;
                },
                sessionReplay: {
                    enabled: true,
                    errorSamplingRate: 0.1,
                    maskSelector: 'null'
                }
            })
        )
    ]
})
export class AppModule {}
