import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIConfirmDialogResult, UIConfirmDialogService, UIDropdownComponent } from '@bannerflow/ui';
import { Color } from '@creative/color';
import { createFeed, isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import { isVersionedText, isVersionedWidgetText } from '@creative/elements/rich-text/text-nodes';
import {
    applyCustomPropertyPrefix,
    isCustomProperty,
    mapElementToCustomWidgetProperties
} from '@creative/elements/widget/utils';
import { serializeFeedValue, serializePropertyValue } from '@creative/serialization';
import { serializeWidgetTextValue } from '@creative/serialization/versions/widgetText-serializer';
import { IBrandLibraryElement, ImageLibraryAsset } from '@domain/brand/brand-library';
import { IColor } from '@domain/color';
import { IElement, IElementProperty } from '@domain/creativeset';
import { IVersion, IVersionProperty, IWidgetText } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { IBfFeed, IFeed } from '@domain/feed';
import { IFontStyle, ISelectedFont } from '@domain/font';
import { IFontFamily } from '@domain/font-families';
import {
    IWidgetCustomProperty,
    IWidgetElementDataNode,
    IWidgetElementProperty,
    IWidgetImage,
    IWidgetSelectOption,
    OneOfCustomPropertyValue
} from '@domain/widget';
import { handleError } from '@studio/utils/errors';
import { sanitizeString } from '@studio/utils/utils';
import { Observable, firstValueFrom } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { FeedPickerComponent } from '../../../../shared/components/feeds/feed-picker/feed-picker.component';
import { StudioUISectionComponent } from '../../../../shared/components/section/studio-ui-section/studio-ui-section.component';
import { FontFamiliesService } from '../../../../shared/font-families/state/font-families.service';
import { BrandLibraryDataService } from '../../../../shared/media-library/brand-library.data.service';
import { ColorService } from '../../../../shared/services/color.service';
import { WidgetService } from '../../../../shared/services/widget.service';
import { VersionsService } from '../../../../shared/versions/state/versions.service';
import { getVersionProperty, isVersionedProperty } from '../../../../shared/versions/versions.utils';
import { IAssetSelectionEvent } from '../../asset-picker/asset-picker.component';
import { isEffectLibraryElement } from '../../media-library/media-library.helpers';
import { ElementChangeType } from '../../services/editor-event/element-change';
import { EditorStateService } from '../../services/editor-state.service';
import { HistoryService } from '../../services/history.service';
import { MutatorService } from '../../services/mutator.service';
import { AssetPropertyContext, PartialLibraryAsset } from '../asset-property/asset-property';
import { WidgetPropertiesService } from './widget-properties.service';

@Component({
    selector: 'widget-properties',
    templateUrl: 'widget-properties.component.html',
    styleUrls: ['../common.scss', 'widget-properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetPropertiesComponent implements OnInit {
    @Input() preview = false;
    @Input() elements$: Observable<IWidgetElementDataNode[]>;

    selectedVersionProperties$: Observable<IVersionProperty[]>;

    @ViewChild('dropdown') dropdown: UIDropdownComponent;
    @ViewChild('section') section: StudioUISectionComponent;
    @ViewChild('feedPicker') feedPicker: FeedPickerComponent;

    mappedProperties: { [key: string]: IWidgetCustomProperty[] } = {};
    AssetPropertyContext = AssetPropertyContext;
    widgetProperties: IWidgetCustomProperty[] = [];
    selectedOptions: { [key: string]: IWidgetSelectOption } = {};
    fontFamilies: Readonly<IFontFamily[]>;
    isBannerflowLibraryWidget = false;
    isEffectWidget = false;

    private defaultVersion: IVersion;
    private selectedVersion: IVersion;
    private selectedVersionProperties: IVersion['properties'];
    private element: IWidgetElementDataNode;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private uiConfirmDialogService: UIConfirmDialogService,
        private editorStateService: EditorStateService,
        private historyService: HistoryService,
        public colorService: ColorService,
        private widgetService: WidgetService,
        private mutatorService: MutatorService,
        private fontFamiliesService: FontFamiliesService,
        private versionsService: VersionsService,
        private brandLibraryDataService: BrandLibraryDataService,
        private widgetPropertiesService: WidgetPropertiesService,
        private destroyRef: DestroyRef
    ) {}

    ngOnInit(): void {
        this.selectedVersionProperties$ = this.versionsService.selectedVersionProperties$;

        this.versionsService.selectedVersionProperties$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(selectedVersionProperties => {
                this.selectedVersionProperties = selectedVersionProperties;
            });

        this.versionsService.defaultVersion$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(defaultVersion => {
                this.defaultVersion = defaultVersion;
            });

        this.versionsService.selectedVersion$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(selectedVersion => {
                this.selectedVersion = selectedVersion;
                this.selectedVersionProperties ??= selectedVersion.properties;
            });

        this.fontFamiliesService.fontFamilies$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(fontFamilies => (this.fontFamilies = fontFamilies));

        this.widgetPropertiesService.propertiesChange$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(customProperties => {
                this.updateProperties(customProperties);
            });

        this.elements$
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                distinctUntilChanged(
                    (prev, curr) =>
                        prev.length === curr.length &&
                        curr.every(currElement =>
                            prev.find(prevElement => prevElement.id === currElement.id)
                        )
                )
            )
            .subscribe(elements => {
                this.element = elements[0];
                this.updateProperties();
            });
    }

    private updateProperties(properties?: IWidgetCustomProperty[]): void {
        const props = properties! || this.element?.customProperties;
        this.widgetProperties = [];
        if (!this.element || !props) {
            return;
        }

        if (!this.brandLibraryDataService.brandLibrary) {
            this.isBannerflowLibraryWidget = true;
        } else {
            const libraryWidget = this.brandLibraryDataService.getElementByDataNode(this.element);

            if (libraryWidget) {
                this.isEffectWidget = isEffectLibraryElement(libraryWidget);
            }

            this.isBannerflowLibraryWidget =
                libraryWidget?.type === ElementKind.BannerflowLibraryWidget;
        }

        this.populateProperties(props);
        if (!this.changeDetector['destroyed']) {
            this.changeDetector.detectChanges();
        }
    }

    /**
     * Apply the changed values to the element
     * Also called from bindings in the DOM
     */
    private propertyValueChanged(eventType: ElementChangeType = ElementChangeType.Burst): void {
        const properties = this.mappedProperties[this.element.id];
        this.mutatorService.setElementPropertyValue(
            this.element,
            'customProperties',
            properties,
            eventType
        );

        if (!this.changeDetector['destroyed']) {
            this.changeDetector.detectChanges();
        }
    }

    getVersionedValue(
        property: IWidgetElementProperty | IWidgetCustomProperty,
        asValue?: boolean
    ): string | OneOfCustomPropertyValue | undefined {
        if (property.versionPropertyId) {
            let versionProperty = getVersionProperty(
                this.selectedVersionProperties,
                this.defaultVersion,
                property.versionPropertyId
            );
            // On the weird case we have a property with versionPropertyId but no versionProperty found, add the versionProperty
            // TODO(Data model): This if should be removed after all data model migrations
            if (!versionProperty) {
                const text = (property?.value as unknown as IWidgetText)?.text || '';
                const epv: IVersionProperty<IWidgetText> = {
                    id: property.versionPropertyId,
                    name: 'widgetText',
                    value: { text }
                };
                this.versionsService.addVersionPropertiesToSelected(epv);
                versionProperty = epv;
            }

            if (property.unit === 'text') {
                if (asValue) {
                    return (versionProperty.value as IWidgetText).text;
                }
                const serializedValue = serializeWidgetTextValue(versionProperty.value as IWidgetText);
                return serializedValue;
            } else if (property.unit === 'feed') {
                if (asValue) {
                    return versionProperty.value as IFeed;
                }
                return serializeFeedValue(versionProperty.value as IFeed);
            }
        }

        if (
            property.unit === 'text' &&
            (isVersionedText(property) || isVersionedWidgetText(property))
        ) {
            return property.value.text;
        }

        if (asValue) {
            return property.value!.toString();
        }

        return serializePropertyValue(property.unit, { text: property.value })!;
    }

    getImageAssetById(
        imageId: string,
        property: IWidgetCustomProperty
    ): PartialLibraryAsset<ImageLibraryAsset> | undefined {
        if (!this.brandLibraryDataService.brandLibrary) {
            return undefined;
        }

        const libraryAsset = this.brandLibraryDataService.brandLibrary.images.find(
            ({ id }) => id === imageId
        );

        if (libraryAsset) {
            return libraryAsset;
        }

        const { src } = (property.value as IWidgetImage) || { src: '', id: '' };
        const name =
            src.split('/').find(x => x.match(/bmp|gif|jpeg|jpg|png|tiff|webp/)) ?? 'No image selected';
        return {
            name,
            url: src,
            thumbnail: { url: src, height: 0, width: 0 },
            original: { url: src, height: 0, width: 0 }
        };
    }

    resetProperties(): void {
        const libraryWidget = this.brandLibraryDataService.brandLibrary?.elements.find(
            element => element.id === this.element.parentId
        );

        if (!libraryWidget) {
            throw new Error('Could not find any library elements with the same id.');
        }

        const designElements = this.editorStateService.elements;
        const element = designElements.find(({ id }) => id === this.element.id);

        if (!element) {
            throw new Error('Could not find design element.');
        }

        this.resetToLibraryProperties(libraryWidget, element);
    }

    private resetToLibraryProperties(libraryWidget: IBrandLibraryElement, element: IElement): void {
        const newProperties = this.getNewProperties(libraryWidget, element);
        const properties = this.cloneMappedProperties(this.mappedProperties[this.element.id]);
        const currentVersion = this.editorStateService.currentVersion;
        const currentVersionProperties = this.editorStateService.versionProperties;

        for (const property of properties) {
            const newProperty = newProperties.find(
                ({ name, unit }) => name === property.name && unit === property.unit
            );

            if (newProperty) {
                property.value = newProperty.value;

                const versionProperty = currentVersionProperties.find(
                    ({ id }) => id === property.versionPropertyId
                );

                if (versionProperty && isVersionedProperty(property)) {
                    this.versionsService.upsertVersionProperty(currentVersion.id, {
                        ...versionProperty,
                        value: property.value
                    });
                }
            }
        }

        this.widgetProperties = properties;
        this.mappedProperties[this.element.id] = properties;
        this.propertyValueChanged();
    }

    private getNewProperties(
        libraryWidget: IBrandLibraryElement,
        element: IElement
    ): IWidgetCustomProperty[] {
        return mapElementToCustomWidgetProperties(libraryWidget).map(property => {
            const elementProperty = element.properties.find(
                ({ name, unit }) => name === property.name && unit === property.unit
            );

            return {
                ...property,
                versionPropertyId: elementProperty?.versionPropertyId
            };
        });
    }

    async updateLibraryElement(): Promise<void> {
        const libraryWidget = this.brandLibraryDataService.brandLibrary?.elements.find(
            element => element.id === this.element.parentId
        );

        if (!libraryWidget) {
            throw new Error('Could not find any library elements with the same id.');
        }

        const result: UIConfirmDialogResult = await this.uiConfirmDialogService.confirm({
            headerText: 'Update in library',
            text: `Are you sure you want to update the widget "${
                libraryWidget.name
            }" in the brand library? ${this.preview ? '' : 'This action cannot be undone.'}`,
            confirmText: 'Update',
            cancelText: 'Cancel'
        });

        if (result !== 'confirm') {
            return;
        }

        if (!libraryWidget) {
            throw new Error('Could not find any library elements with the same id.');
        }

        // Temporarily save the code properties
        const codeProperties = libraryWidget.properties.filter(property => {
            if (!isCustomProperty(property)) {
                return property;
            }
        });

        // Clear the properties of the original element (in brand library)
        libraryWidget.properties = [];

        // Re-add code properties
        codeProperties.forEach(prop => libraryWidget.properties.push(prop));

        // Add custom properties with the new values which are currently defined in the properties panel
        this.widgetProperties.forEach(prop => {
            let value: string | undefined;

            if (prop.unit === 'text') {
                value = sanitizeString(this.getVersionedValue(prop, true) as string);
            } else {
                value = serializePropertyValue(prop.unit as string, prop.value);
            }

            libraryWidget.properties.push({
                name: applyCustomPropertyPrefix(prop.name),
                unit: prop.unit,
                value,
                label: sanitizeString(prop.label || '')
            } as IElementProperty);
        });

        try {
            if (this.preview) {
                this.widgetPropertiesService.updateLibraryElement(this.widgetProperties);
            } else {
                await firstValueFrom(this.brandLibraryDataService.updateElement(libraryWidget));
            }
        } catch (error) {
            handleError('Could not update element in brand library', {
                contexts: { originalError: error }
            });
        }
    }

    onTextPropertyChange(value: string, property: IWidgetCustomProperty): void {
        const widgetProperty = this.widgetProperties.find(prop => prop.name === property.name);

        if (
            !(isVersionedText(widgetProperty) || isVersionedWidgetText(widgetProperty)) ||
            !this.element
        ) {
            return;
        }

        const elementProperty = this.mappedProperties[this.element.id].find(
            prop => prop.name === property.name
        );

        if (
            !elementProperty ||
            !(isVersionedText(elementProperty) || isVersionedWidgetText(elementProperty))
        ) {
            throw new Error('Property not found in element!');
        }

        if (this.getVersionedValue(widgetProperty, true) === value) {
            return;
        }

        if (elementProperty.versionPropertyId) {
            const newValue: IWidgetText = {
                text: value
            };
            elementProperty.value = newValue;
        } else {
            elementProperty.value ??= {
                text: value
            };
            if (elementProperty.value) {
                (elementProperty.value as IWidgetText)!.text = value;
            }
        }

        if (elementProperty.versionPropertyId) {
            const updatedVersionProperty: IVersionProperty = {
                id: property.versionPropertyId!,
                name: 'widgetText',
                value: elementProperty.value as IWidgetText
            };

            this.versionsService.upsertVersionProperty(this.selectedVersion.id, updatedVersionProperty);
        }

        this.propertyValueChanged();
    }

    private onSimplePropertyChange(
        value: OneOfCustomPropertyValue | undefined,
        property: IWidgetCustomProperty
    ): void {
        this.widgetProperties.find(prop => prop.name === property.name)!.value = value;

        const elementProperty = this.mappedProperties[this.element.id].find(
            prop => prop.name === property.name
        );
        if (!elementProperty) {
            throw new Error('Property not found in element!');
        }

        if (JSON.stringify(elementProperty.value) !== JSON.stringify(value)) {
            elementProperty.value = value;
        }

        this.propertyValueChanged();
    }

    onColorPropertyChange(value: Color, property: IWidgetCustomProperty): void {
        this.onSimplePropertyChange(value, property);
    }

    onNumberPropertyChange(value: number, property: IWidgetCustomProperty): void {
        this.onSimplePropertyChange(value, property);
    }

    onSelectionChange(value: IWidgetSelectOption, property: IWidgetCustomProperty): void {
        for (const val of property.value as IWidgetSelectOption[]) {
            val.selected = val.value === value.value;
            if (val.value === value.value) {
                this.selectedOptions[property.name] = val;
            }
        }

        this.onSimplePropertyChange(property.value, property);
    }

    onSwitchChange(value: boolean, property: IWidgetCustomProperty): void {
        this.onSimplePropertyChange(value, property);
    }

    selectedFontChanged(
        { fontFamily, fontStyle }: ISelectedFont,
        property: IWidgetCustomProperty
    ): void {
        const font: IFontStyle = {
            id: fontStyle.id,
            src: fontStyle.fontUrl,
            style: fontStyle.italic ? 'italic' : 'normal',
            weight: fontStyle.weight || 400,
            fontFamilyId: fontFamily.id
        };
        this.onSimplePropertyChange(font, property);
    }

    previewFontChanged(
        { fontFamily, fontStyle }: Partial<ISelectedFont>,
        property: IWidgetCustomProperty
    ): void {
        const font: IFontStyle = {
            id: fontStyle?.id ?? '',
            src: fontStyle?.fontUrl ?? '',
            style: fontStyle?.italic ? 'italic' : 'normal',
            weight: fontStyle?.weight ?? 300,
            fontFamilyId: fontFamily?.id ?? ''
        };

        const fontProperty = this.mappedProperties[this.element.id].find(
            prop => prop.name === property.name
        );

        if (fontProperty) {
            fontProperty.value = font;
        }

        this.mutatorService.setElementPropertyValue(
            this.element,
            'customProperties',
            this.mappedProperties[this.element.id]
        );
    }

    onImageChanged(event: IAssetSelectionEvent | undefined, property: IWidgetCustomProperty): void {
        if (event && event.asset) {
            const imageAsset = event.asset;

            property.value = {
                id: imageAsset.id,
                src: imageAsset.url
            };
        } else {
            // image removed
            property.value = {
                id: '',
                src: ''
            } satisfies IWidgetImage;
        }

        this.onSimplePropertyChange(property.value, property);
    }

    onFeedSelectionChanged(feed: IBfFeed, property: IWidgetCustomProperty): void {
        const widgetProperty = this.widgetProperties.find(prop => prop.name === property.name);

        if (!widgetProperty || !isVersionedFeed(widgetProperty)) {
            return;
        }

        widgetProperty.value.id = feed.id;

        const elementProperty = this.mappedProperties[this.element.id].find(
            prop => prop.name === property.name
        );

        if (!elementProperty || !isVersionedFeed(elementProperty)) {
            throw new Error('Property not found in element!');
        }

        const versionProperty = this.selectedVersionProperties.find(
            vp => vp.id === elementProperty.versionPropertyId
        );

        if (isVersionedFeed(property)) {
            if (versionProperty) {
                this.versionsService.updateVersionPropertyFeed(
                    this.selectedVersion.id,
                    versionProperty.id,
                    feed.id
                );
            } else {
                this.versionsService.addVersionProperty(this.selectedVersion.id, {
                    name: 'feed',
                    id: elementProperty.versionPropertyId!,
                    value: createFeed(feed.id)
                });
            }
        }

        elementProperty.value.id = feed.id;

        this.propertyValueChanged();
        this.updateProperties();
    }

    openWidgetInfo(): void {
        if (this.isEffectWidget) {
            this.widgetService.openEffectInfoPage();
            return;
        }

        this.widgetService.openWidgetInfoPage();
    }

    emitUndo(): void {
        this.historyService.undo$.next();
    }

    emitRedo(): void {
        this.historyService.redo$.next();
    }

    private populateProperties(properties: IWidgetCustomProperty[]): void {
        this.mappedProperties[this.element.id] = this.cloneMappedProperties(properties);

        this.selectedOptions = {};
        this.widgetProperties = this.cloneMappedProperties(this.mappedProperties[this.element.id]);
        for (const property of this.widgetProperties) {
            if (property.unit === 'select') {
                this.selectedOptions[property.name] =
                    (property.value as IWidgetSelectOption[]).find(option => option.selected) ||
                    property.value![0];
            }
        }

        this.propertyValueChanged();
    }

    private cloneMappedProperties(properties: IWidgetCustomProperty[]): IWidgetCustomProperty[] {
        return structuredClone(properties).map(property => {
            if (property.unit === 'color') {
                return {
                    ...property,
                    value: new Color(property.value as IColor)
                };
            }

            return property;
        });
    }
}
