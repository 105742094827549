import { deserializePropertyValue } from '@creative/serialization';
import {
    BrandElementDto,
    BrandLibraryDto,
    IBrandLibrary,
    IBrandLibraryElement
} from '@domain/brand/brand-library';
import { createBrandlibraryElement } from '@studio/utils/element.utils';
import { deserializeImageAssets } from './asset';

export function deserializeBrandlibrary(brandLibraryDto: BrandLibraryDto): IBrandLibrary {
    const { id, folders, brandId, videos, widgets, elements, images } = brandLibraryDto;

    return {
        id,
        folders,
        brandId,
        videos,
        widgets: widgets.map(widget => ({
            ...widget,
            animatedThumbnail: widget.animatedThumbnail ?? undefined,
            thumbnail: widget.thumbnail ?? undefined
        })),
        elements: elements.map(deserializeBrandElement),
        images: deserializeImageAssets(images)
    };
}

function deserializeBrandElement(element: BrandElementDto): IBrandLibraryElement {
    const { id, name, type, parentFolderId, created, modified } = element;

    const properties = element.properties.map(property => ({
        name: property.name,
        label: property.label ?? undefined,
        versionPropertyId: property.versionPropertyId ?? undefined,
        value: deserializePropertyValue(property.name, property.value) ?? undefined,
        unit: property.unit
    }));

    return createBrandlibraryElement({
        id,
        name,
        type,
        parentFolderId,
        created,
        modified,
        properties
    });
}
