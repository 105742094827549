import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Renderer2,
    ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UISVGIconComponent, UITooltipDirective, UITooltipService } from '@bannerflow/ui';
import { isImageOrVideoElement, isTextNode, isVideoNode, isWidgetElement } from '@creative/nodes';
import { deserializePropertyValue } from '@creative/serialization';
import { IBrandLibraryElement } from '@domain/brand/brand-library';
import { IElement } from '@domain/creativeset';
import { ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { IFeed } from '@domain/feed';
import { LibraryKind, LibraryViewMode } from '@domain/media-library';
import { IVideoViewElement, OneOfElementDataNodes, OneOfViewNodes } from '@domain/nodes';
import { isImageLibraryAsset } from '@studio/domain/brand-library/assets';
import { getBoundingRectangleOfRotatedBox } from '@studio/utils/geom';
import { getAspectRatioFit } from '@studio/utils/utils';
import { combineLatest } from 'rxjs';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { MediaLibraryService } from '../../../shared/media-library/state/media-library.service';
import { EditorStateService } from '../services';
import { MutatorService } from '../services/mutator.service';
import { DraggableElementComponent } from './draggable-element/draggable-element.component';
import { ElementRenderingService } from './element-renderering-service';
import { isAnimatedThumbnailSupportedAsset } from './media-library.helpers';

@Injectable()
export class LibraryElementRenderingService {
    dataNode: OneOfElementDataNodes;
    private viewMode: LibraryViewMode = LibraryViewMode.Grid;
    private libraryKind: LibraryKind;
    readonly THUMBNAIL_SIZE = 82;

    constructor(
        private elementRenderingService: ElementRenderingService,
        private mediaLibraryService: MediaLibraryService,
        private renderer2: Renderer2,
        private brandLibraryDataService: BrandLibraryDataService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private editorStateService: EditorStateService,
        private mutatorService: MutatorService,
        private injector: Injector,
        private appRef: ApplicationRef,
        private viewContainerRef: ViewContainerRef,
        private tooltipService: UITooltipService
    ) {
        combineLatest([this.mediaLibraryService.viewMode$, this.mediaLibraryService.kind$])
            .pipe(takeUntilDestroyed())
            .subscribe(([viewMode, kind]) => {
                this.viewMode = viewMode;
                this.libraryKind = kind;
            });
    }

    getComponentRef(element: IBrandLibraryElement): ComponentRef<DraggableElementComponent> {
        const isShapeOrText =
            element.type === ElementKind.Rectangle ||
            element.type === ElementKind.Ellipse ||
            element.type === ElementKind.Text ||
            element.type === ElementKind.Button;

        if (isImageOrVideoElement(element)) {
            return this.getImageOrVideoRef(element);
        } else if (isShapeOrText) {
            return this.getShapeOrTextRef(element);
        } else if (isWidgetElement(element)) {
            return this.getWidgetRef(element);
        } else {
            throw new Error('Could not match element while creating DOM reference');
        }
    }

    private createDraggingRef(): ComponentRef<DraggableElementComponent> {
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(DraggableElementComponent);
        const draggingRef = componentFactory.create(
            this.editorStateService.__appComponentViewRef.injector
        );
        draggingRef.instance.width = this.THUMBNAIL_SIZE;
        draggingRef.instance.height = this.THUMBNAIL_SIZE;

        return draggingRef;
    }

    private getShapeOrTextRef(element: IBrandLibraryElement): ComponentRef<DraggableElementComponent> {
        const draggingRef = this.createDraggingRef();
        const elementInstance = draggingRef.instance;

        const viewElement = this.renderStyledElement(element, elementInstance.viewContainer);
        if (!viewElement) {
            return draggingRef;
        }

        elementInstance.originalSize = [viewElement.width, viewElement.height];
        this.renderer2.setStyle(viewElement.__rootElement, 'height', '100%');
        this.renderer2.setStyle(viewElement.__rootElement, 'width', '100%');

        if (isTextNode(viewElement)) {
            elementInstance.originalSize = [viewElement.width, viewElement.height];
        }

        elementInstance.thumbnailElement = viewElement.__rootElement;

        return draggingRef;
    }

    getImageOrVideoRef(element: IBrandLibraryElement): ComponentRef<DraggableElementComponent> {
        const draggingRef = this.createDraggingRef();
        const elementInstance = draggingRef.instance;

        if (this.libraryKind === LibraryKind.Feeds) {
            // Update after merge
            const { id, path } = deserializePropertyValue('feed', element.properties[0].value) as IFeed;
            const src = this.mutatorService.renderer.feedStore?.getFeed(id)?.data[0][
                decodeURIComponent(path)
            ].value as string;

            elementInstance.src = src;
        } else {
            const asset = this.brandLibraryDataService.getAssetByElement(element);
            if (!asset) {
                return draggingRef;
            }

            elementInstance.originalSize = [asset.width, asset.width];

            if (this.elementRenderingService.isStyledElement(element)) {
                const viewElement = this.renderStyledElement(element, elementInstance.viewContainer);
                if (!viewElement) {
                    return draggingRef;
                }

                this.renderer2.setStyle(viewElement.__rootElement, 'height', '100%');
                this.renderer2.setStyle(viewElement.__rootElement, 'width', '100%');

                if (isVideoNode(viewElement)) {
                    elementInstance.originalSize = [viewElement.width, viewElement.height];
                }

                elementInstance.thumbnailElement = viewElement?.__rootElement;
            } else if (asset) {
                elementInstance.src =
                    isAnimatedThumbnailSupportedAsset(asset) && asset.animatedThumbnail
                        ? asset.animatedThumbnail.url
                        : asset.thumbnail.url;
            }
        }

        return draggingRef;
    }

    private getWidgetRef(element: IBrandLibraryElement): ComponentRef<DraggableElementComponent> {
        const draggingRef = this.createDraggingRef();
        const elementInstance = draggingRef.instance;

        const asset = this.brandLibraryDataService.getWidgetAssetByElement(element);
        elementInstance.src = asset?.thumbnail;
        elementInstance.isWidget = true;
        return draggingRef;
    }

    renderStyledElement(
        element: IElement,
        container: ElementRef<HTMLElement>
    ): OneOfViewNodes | undefined {
        const dataNode = this.elementRenderingService.createElement(element, true);
        this.dataNode = dataNode;
        const viewElement = this.elementRenderingService.renderer.getViewElementById(dataNode.id);

        const rootElement = viewElement?.__rootElement;
        if (!rootElement) {
            return;
        }

        viewElement.__svgBackground?.setResponsive();

        // Apply scope id to make renderer insert style rules
        container.nativeElement.id = `scope-${dataNode.id}`;

        const { width, height } = this.getScaledSize(dataNode);
        const scale = this.calculateScale(dataNode);
        const transformString = this.elementRenderingService.renderer.getTransformString_m(
            viewElement,
            true
        );

        this.renderer2.setStyle(rootElement, 'transform', `${transformString}`);
        this.renderer2.setStyle(rootElement, 'width', `${width}px`);
        this.renderer2.setStyle(rootElement, 'height', `${height}px`);
        this.renderer2.setStyle(rootElement, 'opacity', dataNode.opacity);

        if (isTextNode(viewElement)) {
            this.styleTextElementContent(rootElement, scale);
        } else if (isVideoNode(viewElement)) {
            this.styleVideoElement(element, viewElement);
        }

        this.renderer2.appendChild(container.nativeElement, rootElement);

        this.renderAILabelIfNeeded(element, container);

        return viewElement;
    }

    private renderAILabelIfNeeded(element: IElement, container: ElementRef<HTMLElement>): void {
        const asset = this.brandLibraryDataService.getAssetByElement(element);
        if (isImageLibraryAsset(asset) && asset.isGenAi) {
            const label = this.renderer2.createElement('div');
            this.renderer2.addClass(label, 'library-element__badge');
            this.renderer2.addClass(label, 'always-visible');
            this.renderer2.appendChild(container.nativeElement, label);

            this.addAITooltip(label);
            this.addAIIcon(label);
        }
    }

    private addAITooltip(element: HTMLElement): void {
        const elementRef = new ElementRef(element);
        const tooltipDirective = new UITooltipDirective(
            elementRef,
            this.viewContainerRef,
            this.tooltipService
        );
        tooltipDirective.uiTooltip = 'AI generated image';
        tooltipDirective.ngAfterViewInit();
    }

    private addAIIcon(parentElement: HTMLElement): void {
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(UISVGIconComponent);
        const componentRef = componentFactory.create(this.injector);

        componentRef.instance['icon'] = 'ai_stars';

        this.appRef.attachView(componentRef.hostView);

        const domElement = (componentRef.hostView as EmbeddedViewRef<UISVGIconComponent>).rootNodes[0];
        this.renderer2.appendChild(parentElement, domElement);
    }

    setDraggingRefFullScale(
        draggingRef: DraggableElementComponent,
        canvasSize: ISize,
        zoom: number
    ): void {
        const originalWidth = draggingRef.originalSize?.[0] || this.THUMBNAIL_SIZE;
        const originalHeight = draggingRef.originalSize?.[1] || this.THUMBNAIL_SIZE;
        const { width, height } = getAspectRatioFit(
            originalWidth,
            originalHeight,
            Math.min(canvasSize.width, originalWidth),
            Math.min(canvasSize.height, originalHeight)
        );

        draggingRef.height = Math.round(height * zoom);
        draggingRef.width = Math.round(width * zoom);
        draggingRef.zIndex = 1;

        const rootElement = draggingRef.thumbnailElement;
        if (rootElement) {
            // Texts need a scale reset here, as they are scaled down in the library
            const transformString = rootElement.style.transform.replace(
                /scale\((.*?)\)/,
                `scale(${zoom})`
            );

            this.styleTextElementContent(rootElement, zoom);
            this.renderer2.setStyle(rootElement, 'transform', `${transformString}`);
        }
    }

    /**
     * As videos are not rendered as SVGs, we need to style it around the thumbnail instead
     */
    private styleVideoElement(element: IElement, viewElement: Readonly<IVideoViewElement>): void {
        const asset = this.brandLibraryDataService.getAssetByElement(element);
        if (!asset) {
            throw new Error('Asset not found when rendering video element in library element');
        }

        const styledElement = document.createElement('div');
        styledElement.style.backgroundImage = `url(${asset.thumbnail.url})`;
        styledElement.style.backgroundSize = 'contain';
        styledElement.style.backgroundPosition = 'center';
        styledElement.style.backgroundRepeat = 'no-repeat';
        styledElement.style.zIndex = '1';
        styledElement.style.height = '100%';
        styledElement.style.width = '100%';

        const foreignObject = viewElement.__svgBackground?.foreignObject;
        foreignObject?.appendChild(styledElement);
    }

    private getScaledSize(element: OneOfElementDataNodes): ISize {
        const scale = this.calculateScale(element);
        return {
            width: element.width * scale,
            height: element.height * scale
        };
    }

    private calculateScale(element: OneOfElementDataNodes): number {
        const rotatedBoundingBox = getBoundingRectangleOfRotatedBox(element);
        const height = rotatedBoundingBox.height + (element.border?.thickness ?? 0);
        const width = rotatedBoundingBox.width + (element.border?.thickness ?? 0);

        const isGridView = this.viewMode === LibraryViewMode.Grid;
        const containerWidth = isGridView ? 88 : 175;
        const containerHeight = isGridView ? 65 : 135;

        const widthScale = containerWidth / width;
        const heightScale = containerHeight / height;

        const scale = Math.min(widthScale, heightScale, 1);

        return scale;
    }

    private styleTextElementContent(rootElement: HTMLDivElement, scale: number): void {
        const textContentElements = rootElement.querySelectorAll('.center > .text-content');
        if (textContentElements) {
            textContentElements.forEach(({ parentNode }) => {
                this.renderer2.setStyle(parentNode, 'position', 'relative');
                this.renderer2.setStyle(parentNode, 'z-index', '100');
                this.renderer2.setStyle(parentNode, 'transform', `scale(${scale})`);
            });
        }
    }
}
