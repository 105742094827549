<div
    class="wrapper"
    [class.open]="isPanelOpen()"
    [class.close]="!isPanelOpen()">
    <div
        class="header"
        (click)="closePanel()">
        <ui-svg-icon icon="arrow-left" />
        <span>Import from external libary</span>
    </div>
    <div class="info-section">
        <span>
            Type a font name to search it in the Google fonts library. Imported font will be applied as
            replacement for the <strong>“{{ fontToFix() ?? '' }}”</strong> font.
        </span>
    </div>
    <div class="inputs">
        <ui-checkbox
            [selected]="!!applyToAll()"
            (selectedChange)="applyToAllChanged($event)"
            label="Apply to all layers with missing fonts" />

        <ui-input
            type="text"
            icon="search"
            iconPosition="left"
            placeholder="Search"
            (valueChange)="searchTermChanged($event)"
            [value]="fontToFix() ?? ''" />
    </div>

    @if (loadingFonts()) {
        <div class="loader-wrapper">
            <ui-loader [inline]="true" />
        </div>
    } @else {
        <div class="search-results">
            @let externalFontsResponse = externalFonts();
            @if (externalFontsResponse) {
                @if (!externalFontsResponse.length) {
                    <div class="no-results-wrapper">No results to show. Try again</div>
                } @else {
                    <ui-accordion
                        [allowMultipleExpanded]="true"
                        ui-theme="tiny">
                        @for (externalFont of externalFontsResponse; track externalFont.family) {
                            <external-font-family
                                [externalFontFamily]="externalFont"
                                (selectedFamily)="externalFontFamilySelected(externalFont, $event)"
                                (selectedStyle)="externalFontStyleSelected(externalFont, $event)" />
                        }
                    </ui-accordion>
                }
            }
        </div>
    }
</div>
