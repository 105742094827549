import {
    ImageElementDto,
    RectangleElementDto,
    VideoElementDto,
    WidgetElementDto
} from './api/generated/sapi';
import { AssetReference } from './creativeset/element-asset';
import { VersionedPropertyUnit } from './creativeset/version';
import {
    AllDataNodes,
    IElementDataNode,
    IImageElementDataNode,
    IVideoElementDataNode,
    OmitSuperflous
} from './nodes';
import { OneOfElementsDto, OneOfStatesDtoKeys, TextLikeElementDto } from './serialization';
import { OneOfStatePropertyKeys } from './state';
import { ICharacterProperties, ITextElementProperties, TextStyle } from './text';
import { IWidgetElementDataNode, WidgetUnits } from './widget';

export function getObjectKeys<O extends object>(object: O): (keyof O)[] {
    return Object.keys(object) as (keyof O)[];
}

export type WidgetPropertyKeys = keyof typeof widgetPropertyToUnitMap;
export type WidgetCustomPropertyKeys = keyof typeof widgetCustomPropertyToUnitMap;
export type OneOfElementPropertyKeys = keyof AllDataNodes;

export const INLINE_STYLED_TEXT = 'inline-styled-text' as const;
type ImagePropertyMap = Pick<IImageElementDataNode, 'imageAsset' | 'imageSettings'> & {
    imageReference: 'string';
};
type VideoPropertyMap = Pick<IVideoElementDataNode, 'videoAsset' | 'videoSettings'>;
type AllowedPrimitiveValues = 'number' | 'string' | 'boolean';
type AllowedCustomValues =
    | 'enum'
    | 'Color'
    | 'Radius'
    | 'Border'
    | 'FilterMap'
    | 'Feed'
    | 'Padding'
    | 'Masking'
    | 'FontStyle'
    | 'ImageAsset'
    | 'ImageSettings'
    | 'VideoAsset'
    | 'VideoSettings'
    | 'text'
    | 'variable'
    | typeof INLINE_STYLED_TEXT
    | 'WidgetText'
    | 'Shadow[]'
    | 'Animation[]'
    | 'State[]'
    | 'Action[]'
    | 'TextShadow[]'
    | 'CustomProperty[]'
    | 'WidgetAsset';

type PropertyUnitRecord<Keys extends string> = Record<
    Keys,
    AllowedPrimitiveValues | AllowedCustomValues
>;

export const sharedPropertyToUnitMap: PropertyUnitRecord<keyof OmitSuperflous<IElementDataNode>> = {
    name: 'string',
    x: 'number',
    y: 'number',
    width: 'number',
    height: 'number',
    duration: 'number',
    fill: 'Color',
    originX: 'number',
    originY: 'number',
    rotationX: 'number',
    rotationY: 'number',
    rotationZ: 'number',
    radius: 'Radius',
    opacity: 'number',
    shadows: 'Shadow[]',
    filters: 'FilterMap',
    border: 'Border',
    hidden: 'boolean',
    locked: 'boolean',
    mirrorX: 'boolean',
    mirrorY: 'boolean',
    scaleX: 'number',
    scaleY: 'number',
    ratio: 'number',
    feed: 'Feed',
    states: 'State[]',
    animations: 'Animation[]',
    actions: 'Action[]',
    time: 'number',
    masking: 'Masking'
};

export const characterPropertyToUnitMap: PropertyUnitRecord<keyof ICharacterProperties> = {
    font: 'FontStyle',
    textColor: 'Color',
    fontSize: 'number',
    lineHeight: 'number',
    characterSpacing: 'number',
    uppercase: 'boolean',
    underline: 'boolean',
    strikethrough: 'boolean',
    textShadows: 'TextShadow[]',
    variable: 'Feed',
    __fontFamilyId: 'string'
};

const textPropertiesOnlyToUnitMap: PropertyUnitRecord<keyof ITextElementProperties> = {
    maxRows: 'number',
    padding: 'Padding',
    textOverflow: 'enum',
    horizontalAlignment: 'enum',
    verticalAlignment: 'enum',
    textColor: 'Color',
    font: 'FontStyle',
    fontSize: 'number',
    lineHeight: 'number',
    characterSpacing: 'number',
    uppercase: 'boolean',
    underline: 'boolean',
    strikethrough: 'boolean',
    textShadows: 'TextShadow[]',
    content: 'text',
    // A helper property to display, font family in dropdown, when font style is undefined.
    __fontFamilyId: 'string'
};

export const textPropertyToUnitMap = {
    ...characterPropertyToUnitMap,
    ...textPropertiesOnlyToUnitMap
};

export const textStyleProperties: (keyof TextStyle)[] = [
    'maxRows',
    'padding',
    'textOverflow',
    'horizontalAlignment',
    'verticalAlignment',
    'textColor',
    'font',
    'fontSize',
    'lineHeight',
    'characterSpacing',
    'uppercase',
    'underline',
    'strikethrough',
    'textShadows',
    'width',
    'height',
    // A helper property to display, font family in dropdown, when font style is undefined.
    '__fontFamilyId'
];

type WidgetProperties = keyof Pick<
    IWidgetElementDataNode,
    'js' | 'css' | 'html' | 'customProperties' | 'widgetReference' | 'bannerflowLibraryWidgetReference'
>;

export const widgetPropertyToUnitMap: PropertyUnitRecord<WidgetProperties> = {
    js: 'string',
    html: 'string',
    css: 'string',
    customProperties: 'CustomProperty[]',
    [AssetReference.Widget]: 'string',
    [AssetReference.BannerflowLibraryWidget]: 'string'
};

export const widgetCustomPropertyToUnitMap: PropertyUnitRecord<WidgetUnits | 'widgetText'> = {
    text: 'WidgetText',
    number: 'number',
    boolean: 'boolean',
    select: 'CustomProperty[]',
    image: 'ImageAsset',
    feed: 'Feed',
    font: 'FontStyle',
    color: 'Color',
    widgetText: 'WidgetText'
};

const imagePropertyToUnitMap: PropertyUnitRecord<keyof ImagePropertyMap> = {
    [AssetReference.Image]: 'string',
    imageAsset: 'ImageAsset',
    imageSettings: 'ImageSettings'
};

const videoPropertyToUnitMap: PropertyUnitRecord<keyof VideoPropertyMap> = {
    videoAsset: 'VideoAsset',
    videoSettings: 'VideoSettings'
};

type TextStateKeys = Exclude<keyof typeof textPropertyToUnitMap, 'textColor'>;
type StateProperties = Exclude<OneOfStatePropertyKeys, 'id' | TextStateKeys>;

export const statePropertyToUnitMap: PropertyUnitRecord<StateProperties> = {
    name: 'string',
    x: 'number',
    y: 'number',
    scaleX: 'number',
    scaleY: 'number',
    opacity: 'number',
    mirrorX: 'boolean',
    mirrorY: 'boolean',
    rotationX: 'number',
    rotationY: 'number',
    rotationZ: 'number',
    originX: 'number',
    originY: 'number',
    ratio: 'number',
    textColor: 'Color',
    border: 'Border',
    shadows: 'Shadow[]',
    radius: 'Radius',
    fill: 'Color',
    filters: 'FilterMap'
};

/** Mutatable properties */
export const propertyToUnitMap = {
    ...sharedPropertyToUnitMap,
    ...textPropertyToUnitMap,
    ...widgetPropertyToUnitMap,
    ...imagePropertyToUnitMap,
    ...videoPropertyToUnitMap,
    ...statePropertyToUnitMap
} as const;

export const propertyKeys = getObjectKeys(propertyToUnitMap);

type PropertiesTuple<T extends string, V extends T[]> = [...V];

export type TextProperties = PropertiesTuple<
    keyof ITextElementProperties,
    [
        'content',
        'horizontalAlignment',
        'verticalAlignment',
        'font',
        'fontSize',
        'maxRows',
        'padding',
        'textOverflow',
        'textColor',
        'uppercase',
        'underline',
        'strikethrough',
        'characterSpacing',
        'lineHeight',
        'textShadows',
        '__fontFamilyId'
    ]
>;

export const textProperties: TextProperties = [
    'content',
    'horizontalAlignment',
    'verticalAlignment',
    'font',
    'fontSize',
    'maxRows',
    'padding',
    'textOverflow',
    'textColor',
    'uppercase',
    'underline',
    'strikethrough',
    'characterSpacing',
    'lineHeight',
    'textShadows',
    '__fontFamilyId'
];

export type CharacterProperties = PropertiesTuple<
    keyof ICharacterProperties,
    [
        '__fontFamilyId',
        'characterSpacing',
        'font',
        'fontSize',
        'lineHeight',
        'strikethrough',
        'textColor',
        'textShadows',
        'underline',
        'uppercase'
    ]
>;

export const characterProperties: CharacterProperties = [
    '__fontFamilyId',
    'characterSpacing',
    'font',
    'fontSize',
    'lineHeight',
    'strikethrough',
    'textColor',
    'textShadows',
    'underline',
    'uppercase'
];

export const unitToVersionedUnitMap: Record<'text' | 'feed', VersionedPropertyUnit> = {
    text: 'widgetText',
    feed: 'feed'
};

export type SerializableSharedProperties = PropertiesTuple<
    keyof OneOfElementsDto,
    [
        'actions',
        'animations',
        'border',
        'duration',
        'fill',
        'filters',
        'height',
        'hidden',
        'locked',
        'mirrorX',
        'mirrorY',
        'opacity',
        'originX',
        'originY',
        'radius',
        'ratio',
        'rotationX',
        'rotationY',
        'rotationZ',
        'scaleX',
        'scaleY',
        'shadows',
        'states',
        'time',
        'width',
        'x',
        'y'
    ]
>;

export const serializableSharedProperties: SerializableSharedProperties = [
    'actions',
    'animations',
    'border',
    'duration',
    'fill',
    'filters',
    'height',
    'hidden',
    'locked',
    'mirrorX',
    'mirrorY',
    'opacity',
    'originX',
    'originY',
    'radius',
    'ratio',
    'rotationX',
    'rotationY',
    'rotationZ',
    'scaleX',
    'scaleY',
    'shadows',
    'states',
    'time',
    'width',
    'x',
    'y'
] as const;

type OnlyTextLikeProperties = Exclude<keyof TextLikeElementDto, keyof RectangleElementDto>;

export type SerializableTextProperties = PropertiesTuple<
    OnlyTextLikeProperties,
    [
        'characterSpacing',
        'characterStyles',
        'font',
        'fontSize',
        'horizontalAlignment',
        'lineHeight',
        'maxRows',
        'padding',
        'strikethrough',
        'textColor',
        'textOverflow',
        'textShadows',
        'underline',
        'uppercase',
        'verticalAlignment'
    ]
>;

export const serializableTextProperties: SerializableTextProperties = [
    'characterSpacing',
    'characterStyles',
    'font',
    'fontSize',
    'horizontalAlignment',
    'lineHeight',
    'maxRows',
    'padding',
    'strikethrough',
    'textColor',
    'textOverflow',
    'textShadows',
    'underline',
    'uppercase',
    'verticalAlignment'
] as const;

export type SerializableStateProperties = PropertiesTuple<
    OneOfStatesDtoKeys,
    [
        'border',
        'fill',
        'filters',
        'id',
        'mirrorX',
        'mirrorY',
        'name',
        'opacity',
        'originX',
        'originY',
        'radius',
        'ratio',
        'rotationX',
        'rotationY',
        'rotationZ',
        'scaleX',
        'scaleY',
        'shadows',
        'textColor',
        'x',
        'y'
    ]
>;

export const serializableStateProperties: SerializableStateProperties = [
    'border',
    'fill',
    'filters',
    'id',
    'mirrorX',
    'mirrorY',
    'name',
    'opacity',
    'originX',
    'originY',
    'radius',
    'ratio',
    'rotationX',
    'rotationY',
    'rotationZ',
    'scaleX',
    'scaleY',
    'shadows',
    'textColor',
    'x',
    'y'
] as const;

export type SerializableImageProperties = PropertiesTuple<
    keyof ImageElementDto,
    ['imageAssetId', 'imageSettings']
>;

export const serializableImageProperties: SerializableImageProperties = [
    'imageAssetId',
    'imageSettings'
] as const;

export type SerializableVideoProperties = PropertiesTuple<
    keyof VideoElementDto,
    ['videoAssetId', 'videoSettings']
>;
export const serializableVideoProperties: SerializableVideoProperties = [
    'videoAssetId',
    'videoSettings'
] as const;

export type SerializableWidgetProperties = PropertiesTuple<
    keyof WidgetElementDto,
    ['customProperties']
>;
export const serializableWidgetProperties: SerializableWidgetProperties = ['customProperties'] as const;

export type SerializableWidgetCustomProperties = PropertiesTuple<
    WidgetUnits,
    ['text', 'number', 'boolean', 'select', 'image', 'feed', 'font', 'color']
>;
export const serializableWidgetCustomProperties: SerializableWidgetCustomProperties = [
    'text',
    'number',
    'boolean',
    'select',
    'image',
    'feed',
    'font',
    'color'
] as const;

export const serializableProperties = [
    ...serializableSharedProperties,
    ...serializableStateProperties,
    ...serializableImageProperties,
    ...serializableVideoProperties,
    ...serializableWidgetProperties,
    ...serializableTextProperties
] as const;
