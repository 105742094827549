import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CreativeConverterEffects } from './creative-converter.effects';
import * as creativeConverter from './creative-converter.reducer';
import { CreativeConverterStateService } from './creative-converter.service';

@NgModule({
    imports: [
        StoreModule.forFeature(
            creativeConverter.CREATIVE_CONVERTER_FEATURE_KEY,
            creativeConverter.reducer
        ),
        EffectsModule.forFeature([CreativeConverterEffects])
    ],
    providers: [CreativeConverterStateService]
})
export class CreativeConverterStoreModule {}
