import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import * as FontFamiliesActions from '../../../../shared/font-families/state/font-families.actions';
import * as CreativeConverterActions from './creative-converter.actions';

@Injectable()
export class CreativeConverterEffects {
    private _actions = inject(Actions);

    fixFont$ = createEffect(() => {
        return this._actions.pipe(
            ofType(CreativeConverterActions.fixFontWith),
            map(() => FontFamiliesActions.resetExternalFonts())
        );
    });
}
