import { zodOptional } from '@zod/zod';
import { z } from 'zod';
import { IElement, ZElement, ZElementProperty } from '../../creativeset/element';
import { ImageLibraryAsset, ZImageLibraryAsset } from './image-asset';
import { VideoLibraryAsset, ZVideoLibraryAsset } from './video-asset';
import { IWidgetLibraryAsset, ZWidgetLibraryAsset } from './widget-asset';

export const ZBrandElement = ZElement.extend({
    parentFolderId: zodOptional(z.string()),
    created: z.string(),
    modified: zodOptional(z.string()),
    properties: z.array(ZElementProperty.omit({ id: true, clientId: true }))
}).omit({ parentId: true });

export type BrandElementDto = z.infer<typeof ZBrandElement>;

export const ZBrandFolder = z.object({
    id: z.string(),
    name: z.string(),
    parentFolderId: zodOptional(z.string())
});
const ZNewBrandFolder = ZBrandFolder.omit({ id: true });

export type Folder = z.infer<typeof ZBrandFolder>;
export type NewFolder = z.infer<typeof ZNewBrandFolder>;

/**
 * @deprecated Use BrandLibraryDto interface from SAPI instead
 */
export const ZBrandLibraryDto = z.object({
    id: z.string(),
    brandId: z.string(),
    elements: z.array(ZBrandElement),
    folders: z.array(ZBrandFolder),
    images: z.array(ZImageLibraryAsset),
    videos: z.array(ZVideoLibraryAsset),
    widgets: z.array(ZWidgetLibraryAsset)
});

export type BrandLibraryDto = z.infer<typeof ZBrandLibraryDto>;

export interface IBrandLibrary {
    id: string;
    brandId: string;
    elements: IBrandLibraryElement[];
    folders: Folder[];
    images: ImageLibraryAsset[];
    videos: VideoLibraryAsset[];
    widgets: IWidgetLibraryAsset[];
}

export interface IBrandLibraryElement extends Omit<IElement, 'parentId' | 'properties'> {
    created: string;
    modified?: string;
    parentFolderId?: string;
    properties: Omit<IElement['properties'], 'id' | 'clientId'>;
}

export interface INewBrandLibraryElement extends Omit<IBrandLibraryElement, 'id'> {
    id?: string;
}

export type BrandLibraryQueryResult = {
    brandLibrary: BrandLibraryDto;
};

export type CreateElementInBrandLibraryResult = { createElementInBrandLibrary: BrandLibraryDto };
export type UpdateElementInBrandLibraryResult = { updateElementInBrandLibrary: BrandLibraryDto };
export type DeleteElementsInBrandLibraryResult = { deleteElementsInBrandLibrary: BrandLibraryDto };
export type CreateBrandLibraryFolderResult = {
    createBrandLibraryFolder: {
        folders: Folder[];
    };
};
