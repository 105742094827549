import { inject, Injectable } from '@angular/core';
import * as CreativeConverterActions from './creative-converter.actions';
import * as CreativeConverterSelectors from './creative-converter.selectors';
import { Store } from '@ngrx/store';
import { LayerItem } from '@studio/domain/components/psd-import/psd';
import { IFontFamily, IFontFamilyStyle } from '@domain/font-families';

@Injectable({
    providedIn: 'root'
})
export class CreativeConverterStateService {
    private store = inject(Store);

    applyToAll$ = this.store.select(CreativeConverterSelectors.getApplyToAll);
    fontToFix$ = this.store.select(CreativeConverterSelectors.getFontToFix);
    psdLayers$ = this.store.select(CreativeConverterSelectors.getPsdLayers);

    resetState(): void {
        this.store.dispatch(CreativeConverterActions.resetState());
    }

    setLayers(layers: LayerItem[]): void {
        this.store.dispatch(CreativeConverterActions.setLayers({ layers }));
    }

    setFontToFix(fontToFix: string, applyToAll: boolean): void {
        this.store.dispatch(CreativeConverterActions.setFontToFix({ fontToFix, applyToAll }));
    }

    setApplyToAll(applyToAll: boolean): void {
        this.store.dispatch(CreativeConverterActions.setApplyToAll({ applyToAll }));
    }

    resetFontToFix(): void {
        this.store.dispatch(CreativeConverterActions.resetFontToFix());
    }

    useAlternativeFont(fontToFix: string, applyToAll: boolean): void {
        this.store.dispatch(
            CreativeConverterActions.useAlternativeFont({
                applyToAll,
                fontToFix
            })
        );
    }

    toggleCollapse(layer: LayerItem): void {
        this.store.dispatch(CreativeConverterActions.toggleCollapse({ layer }));
    }

    toggleVisibility(layer: LayerItem): void {
        this.store.dispatch(CreativeConverterActions.toggleVisibility({ layer }));
    }

    toggleSelection(layer: LayerItem): void {
        this.store.dispatch(CreativeConverterActions.toggleSelection({ layer }));
    }

    toggleAllSelection(newSelectedValue: boolean): void {
        this.store.dispatch(CreativeConverterActions.toggleAllSelection({ newSelectedValue }));
    }

    fixFontWith(fontFamily: IFontFamily, fontStyle: IFontFamilyStyle): void {
        this.store.dispatch(CreativeConverterActions.fixFontWith({ fontStyle, fontFamily }));
    }
}
