import { Ad } from '@ad';
import { getStudioAdDataCreative, getTargetUrl, IInputCreative } from '@ad/data/get-ad-data-creative';
import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Logger } from '@bannerflow/sentinel-logger';
import { UIDialogService } from '@bannerflow/ui';
import { IAnimator } from '@creative/animator.header';
import { ICreativeWrapper } from '@creative/creative-wrapper.header';
import { createCreativeContainer, ICreativeFactory, T } from '@creative/creative.container';
import { createFeed, isVersionedFeed } from '@creative/elements/feed/feeds.utils';
import {
    isContentSpan,
    isVersionedText,
    isVersionedWidgetText
} from '@creative/elements/rich-text/text-nodes';
import { updateOrCreateWidgetElementCodeProperties } from '@creative/elements/widget/utils';
import { Layouter } from '@creative/layout/layout';
import {
    createTextFromVersionedText,
    initializeElements,
    isImageNode,
    isTextDataElement,
    isTextNode,
    isVideoNode,
    isWidgetNode
} from '@creative/nodes/helpers';
import { IRenderer, RendererEvents } from '@creative/renderer.header';
import { getViewElementProperty } from '@creative/rendering';
import { IBrandLocalization } from '@domain/brand';
import { CreativeMode, ICreativeEnvironment } from '@domain/creative/environment';
import { IFeedStore } from '@domain/creative/feed/feed-store.header';
import { CreativeSize, IDesign } from '@domain/creativeset';
import { ICreative } from '@domain/creativeset/creative';
import { IVersion, IVersionedText, IVersionProperty } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { IFontFamily } from '@domain/font-families';
import {
    IButtonElementDataNode,
    ICreativeDataNode,
    ITextElementDataNode,
    ITextViewElement
} from '@domain/nodes';
import { IText, SpanType } from '@domain/text';
import { IWidgetElementDataNode, IWidgetViewElement } from '@domain/widget';
import { WidgetEvent } from '@domain/widget-events';
import { concatLatestFrom } from '@ngrx/operators';
import {
    DirtyCharacterStyling,
    DirtyCharacterStylingElements
} from '@studio/domain/components/translation-page';
import { cloneDeep } from '@studio/utils/clone';
import { Container } from '@studio/utils/di';
import { merge, Subject } from 'rxjs';
import { auditTime, filter, map, switchMap, take } from 'rxjs/operators';
import { ChangeState } from '../../../pages/manage-view/services/creative-mutation';
import { EditCreativeService } from '../../../pages/manage-view/services/edit-creative.service';
import { TargetUrlDialogComponent } from '../../../pages/manage-view/target-url-dialog/target-url-dialog.component';
import { TranslationPanelService } from '../../../pages/manage-view/translation-panel/translation-panel.service';
import { TranslationPageService } from '../../../pages/translation-page/state/translation-page.service';
import { mergeCharacterStyles } from '../../../pages/translation-page/utils/tp.utils';
import { AnimationControlService } from '../../animation-control/animation-control.service';
import { BrandService } from '../../brand/state/brand.service';
import { CreativesService } from '../../creatives/state/creatives.service';
import { CreativesetDataService } from '../../creativeset/creativeset.data.service';
import { FontFamiliesService } from '../../font-families/state/font-families.service';
import { EnvironmentService } from '../../services/environment.service';
import { VersionsService } from '../../versions/state/versions.service';
import {
    createAdDataCreativeVersion,
    mergeVersionProperties,
    mergeVersionsProperties
} from '../../versions/versions.utils';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'studio-creative',
    templateUrl: './studio-creative.component.html',
    styleUrls: ['./studio-creative.component.scss'],
    host: {
        '[class.no-design]': '!creative || (creative && !creative.design)'
    }
})
export class StudioCreativeComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    private animationControlService = inject(AnimationControlService);
    private appContainer = inject(Container<T>);
    private brandService = inject(BrandService);
    private creativesService = inject(CreativesService);
    private creativesetDataService = inject(CreativesetDataService);
    private destroyRef = inject(DestroyRef);
    private editCreativeService = inject(EditCreativeService);
    private environmentService = inject(EnvironmentService);
    private fontFamiliesService = inject(FontFamiliesService);
    private translationPageService = inject(TranslationPageService);
    private translationPanelService = inject(TranslationPanelService);
    private uiDialogService = inject(UIDialogService);
    private versionsService = inject(VersionsService);

    @Input() creative: ICreative;
    @Input() size: CreativeSize;
    @Input() version: IVersion;
    @Input() shouldPlay: boolean;
    @Input() renderOnInit = false;
    @Input() preview = false;
    @Input() showTargetUrl = true;

    loaded = output<void>();

    @ViewChild('creativeElement', { static: true }) creativeElement: ElementRef;

    private renderer: IRenderer;
    private animator: IAnimator;
    private creativeDocument: ICreativeDataNode;
    private creativeDesign: IDesign;
    private selectedVersions: IVersion[] = [];

    private logger = new Logger('StudioCreativeComponent');
    private fontFamilies: IFontFamily[] = [];
    private defaultVersion: IVersion;
    private shouldUpdateVersion = false;
    private localizations: IBrandLocalization[];

    private initializeCreativeRendering$ = new Subject<boolean>();

    private _initialized$ = new Subject<void>();
    initialized$ = this._initialized$.asObservable().pipe(map(() => true));

    private dirtyCharacterStyles: DirtyCharacterStyling = {};
    private initialized = false;

    constructor() {
        this.brandService.localizations$.pipe(take(1)).subscribe(localizations => {
            this.localizations = localizations;
        });

        this.animationControlService.isPlaying$.pipe(takeUntilDestroyed()).subscribe(isPlaying => {
            if (this.animator && this.creative && this.creative.design) {
                if (isPlaying) {
                    if (this.environmentService.isPage('MV')) {
                        const startTime = this.preview
                            ? 0
                            : (this.creative.design.document.startTime ?? 0);
                        this.animator.seek(startTime);
                    }
                    this.animator.play();
                } else {
                    this.animator.pause();
                }
            }
        });

        this.animationControlService.stepToStart$.pipe(takeUntilDestroyed()).subscribe(() => {
            if (this.animator && this.creative && this.creative.design) {
                this.animator.setLoop(1);
                this.animator.seek(0);
            }
        });

        this.animationControlService.showPreloadImage$
            .pipe(filter(Boolean), takeUntilDestroyed())
            .subscribe(() => {
                if (this.animator && this.creative?.design) {
                    this.animator.setLoop(1);
                    this.animator.seek(this.creativeDocument.getFirstPreloadImageFrame());
                    for (const element of this.creativeDocument.elements) {
                        if (element.kind === ElementKind.Widget && element.__widget) {
                            element.__widget.emit(WidgetEvent.ShowPreloadImage);
                        }
                    }
                }
            });

        this.animationControlService.stepForward$.pipe(takeUntilDestroyed()).subscribe(() => {
            if (this.animator && this.creative?.design) {
                const newTime = this.animator.time + 1;
                this.stepAnimator(newTime, newTime > this.animator.duration, 0);
            }
        });

        this.animationControlService.stepBack$.pipe(takeUntilDestroyed()).subscribe(() => {
            if (this.animator && this.creative?.design) {
                const newTime = this.animator.time - 1;
                this.stepAnimator(newTime, newTime < 0, this.animator.duration);
            }
        });

        this._initialized$.pipe(takeUntilDestroyed()).subscribe(() => {
            if (this.preview) {
                // unmute audio in 100% preview
                this.renderer?.emit('mute', false);
            }
        });

        this._initialized$
            .pipe(
                switchMap(() =>
                    this.animationControlService.shownElement$.pipe(
                        filter(elementId =>
                            this.creativeDesign.elements.some(({ id }) => id === elementId)
                        )
                    )
                ),
                takeUntilDestroyed()
            )
            .subscribe(elementId => {
                if (this.animator) {
                    this.animator.seekToElement(elementId);
                }
            });

        this.fontFamiliesService.fontFamilies$
            .pipe(takeUntilDestroyed())
            .subscribe(fontFamilies => (this.fontFamilies = fontFamilies));

        merge(
            this.editCreativeService.activation.change$,
            this.editCreativeService.deactivation.change$
        )
            .pipe(
                filter(({ state }) => state === ChangeState.FINISHED),
                filter(
                    ({ data: creatives }) =>
                        !this.creative.design || (this.creative && creatives.includes(this.creative))
                ),
                takeUntilDestroyed()
            )
            .subscribe(() => {
                this.initializeCreativeRendering$.next(true);
            });

        this.editCreativeService.deletion.change$
            .pipe(
                filter(({ state }) => state === ChangeState.FINISHED),
                filter(
                    ({ data: sizes }) =>
                        !this.creative.design ||
                        (this.size && sizes.every(size => size.id === this.size.id))
                ),
                takeUntilDestroyed()
            )
            .subscribe(() => {
                if (!this.creative.design) {
                    this.initializeCreativeRendering$.next(true);
                }
            });

        this.versionsService.renderDirtyVersionProperty$
            .pipe(
                filter(({ elementId }) =>
                    this.creativeDocument.elements.some(el => el.id === elementId)
                ),
                takeUntilDestroyed()
            )
            .subscribe(text => {
                this.updateTextAndWidget(text);
            });

        this.initializeCreativeRendering$
            .pipe(
                concatLatestFrom(() => [
                    this.versionsService.versions$,
                    this.versionsService.defaultVersion$,
                    this.animationControlService.isPlaying$,
                    this.versionsService.getDirtyVersionPropertiesOf(
                        this.creative?.version.id || this.version.id
                    )
                ]),
                takeUntilDestroyed()
            )
            .subscribe(([force, versions, defaultVersion, shouldPlay, dirtyPropertiesTPage]) => {
                this.initialized = true;
                this.defaultVersion = defaultVersion;
                if (this.shouldUpdateVersion) {
                    this.version =
                        versions.find(({ id }) => id === this.creative.version.id) ?? this.version;
                }

                if (this.selectedVersions.length === 1) {
                    const dirtyPropertiesTPanel = this.translationPanelService.getDirtyProperties(
                        this.version
                    );

                    this.version = {
                        ...this.version,
                        properties: [
                            ...this.version.properties.filter(
                                property =>
                                    !dirtyPropertiesTPanel.some(({ id }) => id === property.id) &&
                                    !dirtyPropertiesTPage.some(({ id }) => id === property.id)
                            ),
                            ...dirtyPropertiesTPanel,
                            ...dirtyPropertiesTPage
                        ]
                    };
                }

                this.initializeCreativeRendering(shouldPlay, force);
            });

        this._initialized$
            .pipe(
                switchMap(() => this.creativesService.focusedElement$),
                takeUntilDestroyed()
            )
            .subscribe(({ focusedElementId, focusedVersionId }) => {
                const currentVersionId = this.version?.id && this.creative?.version?.id;
                if (focusedVersionId && focusedVersionId !== currentVersionId) {
                    return;
                }
                this.highlightElement(focusedElementId);
            });
    }

    ngOnInit(): void {
        if (this.creative) {
            this._initCreative(this.creative);
        } else if (!this.size || !this.version) {
            throw new Error('If no creative is set, you must define a size and a version.');
        }
    }

    ngAfterViewInit(): void {
        /* the dialog animation will otherwise show small distortion of the creative at load. Customers wont understand that it is the dialogs animation */
        if (this.renderOnInit && !this.initialized) {
            this.initializeCreativeRendering$.next(false);
        }

        if (this.environmentService.isPage('TP')) {
            this.subscribeToDirtyProperties();
        }

        this.loaded.emit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.creative?.isFirstChange()) {
            return;
        }

        const creative: ICreative = changes.creative?.currentValue;
        if (creative) {
            this.dirtyCharacterStyles = {}; // Clear dirtyCharacterStyles after save
            this._initCreative(creative);
        }
    }

    ngOnDestroy(): void {
        if (this.creative && !this.preview) {
            this.editCreativeService.setCreativeVisiblityStatus(this.creative, {
                visible: false,
                renderer: undefined
            });
        }

        this.destroyCreative();
    }

    rerender(creativeDataNode: ICreativeDataNode): void {
        this.renderer.rerender_m(creativeDataNode);
    }

    private _initCreative(creative: ICreative): void {
        this.versionsService.selectedVersions$.pipe(take(1)).subscribe(versions => {
            this.selectedVersions = versions;
            if (!this.version) {
                this.shouldUpdateVersion = true;
            }
            this.version ??= versions.find(({ id }) => id === creative.version.id)!;
        });
        this.translationPageService
            .selectDirtyCharacterStylingForCreative(this.creative.id)
            .pipe(takeUntilDestroyed(this.destroyRef), filter(Boolean))
            .subscribe(dirtyCharacterStyles => {
                if (!this.creative.design) {
                    return;
                }
                this.clearDirtyCharacterStylesFromDesign(
                    this.creative.design,
                    this.dirtyCharacterStyles
                );

                this.dirtyCharacterStyles = dirtyCharacterStyles;
                if (dirtyCharacterStyles && !!Object.keys(dirtyCharacterStyles).length) {
                    this.updateDesignWithDirtyCharacterStyles(
                        this.creative.design,
                        dirtyCharacterStyles
                    );
                }
                this.rerenderTexts();
            });
        this.logger = new Logger(
            `Creative - ${this.version?.name} - ${creative.size.width}x${creative.size.height}`,
            true
        );

        this.size = creative.size;
        this.initializeCreativeRendering$.next(true);
    }

    private async initializeCreativeRendering(
        animationControlShouldPlay: boolean,
        force = false
    ): Promise<void> {
        if (!this.version) {
            return;
        }

        this.logger.debug('initializeCreativeRendering');

        if (this.renderer && !force) {
            return;
        }

        const design = this.getDesignFromCreative();

        if (!design) {
            this.logger.verbose('Inactive creative');
            this.creativeElement.nativeElement.innerHTML = '';
            return;
        }

        await this.initializeCreativeRenderingWidgets(design);

        this.creativeDesign = design;
        this.creativeDocument = design.document;

        const { renderTime, creative } = this.createCreative(design);
        this.renderer = creative.renderer_m;

        if (this.creative && !this.preview) {
            this.editCreativeService.setCreativeVisiblityStatus(this.creative, {
                renderer: this.renderer
            });
        }

        await this.initializeCreativeRenderingFeeds(creative);

        this.animator = creative.animator_m;
        this.renderer.initialize(this.creativeElement.nativeElement, renderTime);
        const widgetRenderer = this.renderer.WidgetRenderer;
        if (widgetRenderer) {
            try {
                // Ugly workaround to ensure that all widgets has completely loaded to ensure that they are in sync with the creative
                await Promise.all(widgetRenderer.widgetInitializingPromises);
                await Promise.all(widgetRenderer.widgetLoadingPromises);
            } catch (error) {
                this.logger.error(`An error has occurred while initializing widgets: ${error}`);
            }
        }

        // workaround for widgets so they can set animation on right frame on manage view
        this.animator.seek(this.creativeDocument.getFirstPreloadImageFrame());

        if (this.creative) {
            this.editCreativeService.onCreativeComponentLoaded(this.creative.id);
        }
        this.shouldPlayCreative(animationControlShouldPlay);
        this.renderer.on('click', this.onCreativeClick);
        this._initialized$.next();
    }

    private async initializeCreativeRenderingFeeds(creative: ICreativeWrapper): Promise<void> {
        const feeds: string[] = [];
        this.creativeDocument.elements.forEach(child => {
            if (isTextDataElement(child)) {
                for (const span of child.content.spans) {
                    if (span.type === SpanType.Variable) {
                        feeds.push(span.style.variable!.id);
                    }
                }
            } else if (child.feed && (isImageNode(child) || isVideoNode(child))) {
                feeds.push(child.feed.id);
            }
        });

        if (feeds.length) {
            await creative.feedStore_m.preloadFeeds(feeds);
        }
    }

    private async initializeCreativeRenderingWidgets(design: IDesign): Promise<void> {
        for (const element of design.elements) {
            await updateOrCreateWidgetElementCodeProperties(element);
        }
    }

    private shouldPlayCreative(animationControlShouldPlay: boolean): void {
        const shouldPlay = this.creative?.design && (this.shouldPlay || animationControlShouldPlay);

        if (this.animator && shouldPlay) {
            if (this.preview) {
                this.animator.seek(0);
            }
            this.animator.play();
        }
    }

    private createCreative(design: IDesign): { renderTime: number; creative: ICreativeWrapper } {
        if (!this.version?.properties) {
            throw new Error('Version not set');
        }
        const properties = this.version.properties;

        initializeElements(this.creativeDocument, design.elements, {
            versionProperties: properties,
            defaultVersionProperties: this.defaultVersion.properties,
            fontFamilies: this.fontFamilies
        });

        const version = createAdDataCreativeVersion(this.version, this.localizations);

        const creativeInput: IInputCreative = {
            id: '0',
            design: design,
            size: this.size,
            targetUrl: ''
        };
        const adCreative = getStudioAdDataCreative(
            this.creativesetDataService.creativeset,
            creativeInput,
            this.creativesetDataService.creativeset.brandId,
            version
        );

        const adScript = document.createElement('script');
        const ad = new Ad(
            {
                id: 'studioAd',
                adTagId: '',
                origin: '',
                account: { slug: 'accountSlug' },
                brand: { id: this.creativesetDataService.brand.id },
                creatives: [adCreative],
                responsive: !this.preview
            },
            adScript
        );

        const renderTime = this.preview
            ? (this.creativeDocument.startTime ?? 0)
            : Math.min(
                  this.creativeDocument.duration,
                  this.creativeDocument.getFirstPreloadImageFrame()
              );

        const env = {
            ...this.environmentService.env,
            MODE: CreativeMode.ManageView
        } as ICreativeEnvironment;
        const container = createCreativeContainer(env);
        container.parent = this.appContainer;
        container.register_m(T.ENVIRONMENT, env);
        const createCreative = container.resolve<ICreativeFactory>(T.CREATIVE_FACTORY);
        const creative = createCreative(
            this.creativeDocument,
            {
                brandId: this.creativesetDataService.brand.id,
                responsive: {
                    enabled: true,
                    mode: 'contain'
                }
            },
            ad
        );
        return { renderTime, creative };
    }

    private getDesignFromCreative(): IDesign | undefined {
        if (!this.creative?.design) {
            const originalDesign = Layouter.getBestDesignBasedOnSize(
                this.size,
                this.creativesetDataService.creativeset
            );
            if (!originalDesign) {
                return;
            }
            const layouter = new Layouter(originalDesign.document, this.size);
            return {
                ...originalDesign,
                id: '',
                document: layouter.newCreative
            };
        }

        return cloneDeep(this.creative.design);
    }

    // we only stop the propagation if we have a target url to show,
    // otherwise click handlers on parent elements wont work in mobile view
    private onCreativeClick = ({ event, deepLinkUrl }: RendererEvents['click']): void => {
        if (!this.showTargetUrl) {
            return;
        }

        const targetUrl = getTargetUrl(this.version, this.creative);
        const resolvedTargetUrl = deepLinkUrl ?? targetUrl;
        if (resolvedTargetUrl) {
            if (event instanceof Event) {
                event.stopPropagation();
            }
            const isDialogOpen = this.uiDialogService.hasDialog('target-url-dialog');
            if (isDialogOpen) {
                return;
            }

            this.uiDialogService.openComponent(TargetUrlDialogComponent, {
                id: 'target-url-dialog',
                headerText: 'Target URL was triggered',
                data: {
                    targetUrl: resolvedTargetUrl
                }
            });
        }
    };

    updateTextAndWidget({
        versionProperty,
        elementId
    }: {
        versionProperty: IVersionProperty;
        elementId: string | undefined;
    }): void {
        this.logger.debug('updateTextAndWidget');
        if (!this.renderer) {
            return;
        }

        const element = this.renderer.creativeDocument.elements.find(({ id }) => id === elementId);

        if (isTextNode(element) && isVersionedText(versionProperty)) {
            this.updateText(versionProperty, element);
        }
        if (isWidgetNode(element)) {
            this.updateWidget(versionProperty, element);
        }
    }

    private updateText(
        versionProperty: IVersionProperty<IVersionedText>,
        element: ITextElementDataNode | IButtonElementDataNode
    ): void {
        const viewElement = this.renderer.getViewElementById<ITextViewElement>(element.id);
        const versionValue = versionProperty.value;
        if (!viewElement?.__richTextRenderer || !element.characterStyles) {
            return;
        }

        const newText = createTextFromVersionedText(element, versionValue, element.characterStyles);
        if (viewElement.__richTextRenderer.feedStore_m) {
            this.updateFeedValue(newText, viewElement.__richTextRenderer.feedStore_m);
        }
        viewElement.__richTextRenderer.setText(
            { style: viewElement, spans: newText.spans },
            false,
            true
        );
    }

    private updateFeedValue(newText: IText, feedStore: IFeedStore): void {
        for (const span of newText.spans) {
            if (!isContentSpan(span) || !span.style.variable) {
                continue;
            }
            for (const [key, value] of Array.from(feedStore.elements)) {
                if (key === span.style.variable.spanId) {
                    value.feed.step = span.style.variable.step;
                }
            }
        }
    }

    private updateWidget(versionProperty: IVersionProperty, element: IWidgetElementDataNode): void {
        const viewElement = this.renderer.getViewElementById<IWidgetViewElement>(element.id);
        const customProperty = element.customProperties.find(
            prop => prop.versionPropertyId === versionProperty.id
        );

        if (
            customProperty?.unit === 'text' &&
            (isVersionedText(versionProperty) || isVersionedWidgetText(versionProperty))
        ) {
            customProperty.value = {
                text: versionProperty.value.text
            };
        }

        if (customProperty && isVersionedFeed(versionProperty)) {
            const { id, path, type } = versionProperty.value;
            customProperty.value = createFeed(id, path, type);
        }

        element.__widget?.emit(WidgetEvent.ViewNodeChanged, viewElement);
    }

    private destroyCreative(): void {
        this.logger.verbose('destroyCreative');

        if (this.animator) {
            this.animator.destroy();
        }

        if (this.renderer) {
            this.renderer.destroy();
        }
    }

    private stepAnimator(time: number, reset: boolean, resetTime: number): void {
        const newTime = reset ? resetTime : time;
        this.animator.seek(newTime);
    }

    private subscribeToDirtyProperties(): void {
        this.versionsService
            .getDirtyVersionPropertiesOf(this.creative?.version.id || this.version.id)
            .pipe(
                auditTime(250),
                concatLatestFrom(() => [
                    this.versionsService.versions$,
                    this.versionsService.defaultVersion$
                ]),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([dirtyProperties, versions, defaultVersion]) => {
                // if there are no dirty properties, force the initial version's properties (e.g. canceled translations)
                // since this.version might already has dirty properties merged
                const creativeVersion = dirtyProperties.length
                    ? this.version
                    : versions.find(v => v.id === this.version.id);
                if (!creativeVersion) {
                    return;
                }
                const versionProperties = mergeVersionsProperties(defaultVersion, creativeVersion);
                const newVersionProperties = mergeVersionProperties(versionProperties, dirtyProperties);

                for (const property of newVersionProperties) {
                    this.updateElementProperty(property);
                }

                this.version = {
                    ...this.version,
                    properties: newVersionProperties
                };
            });
    }

    private updateElementProperty(versionProperty: IVersionProperty): void {
        if (!this.creativeDesign) {
            return;
        }
        const element = this.creativeDesign.elements.find(({ properties }) =>
            properties.some(({ versionPropertyId }) => versionPropertyId === versionProperty.id)
        );

        if (element) {
            this.updateTextAndWidget({
                elementId: element.id,
                versionProperty
            });
        }
    }

    private highlightElement(elementId: string | undefined): void {
        if (!this.creativeElement?.nativeElement) {
            return;
        }
        const e = this.creativeElement.nativeElement;
        e.style.setProperty('--after-visibility', 'hidden');
        const element = this.creativeDocument.elements.find(({ id }) => id === elementId);
        if (!this.renderer || !element) {
            return;
        }
        e.style.setProperty('--after-visibility', 'visible');
        const x = getViewElementProperty('x', element, undefined, this.renderer.getScale_m());
        const y = getViewElementProperty('y', element, undefined, this.renderer.getScale_m());
        const width = getViewElementProperty('width', element, undefined, this.renderer.getScale_m());
        const height = getViewElementProperty('height', element, undefined, this.renderer.getScale_m());
        e.style.setProperty('--after-top', `${y}px`);
        e.style.setProperty('--after-left', `${x}px`);
        e.style.setProperty('--after-width', `${width}px`);
        e.style.setProperty('--after-height', `${height}px`);
    }

    private updateDesignWithDirtyCharacterStyles(
        design: IDesign,
        dirtyCharacterStyles: DirtyCharacterStylingElements
    ): void {
        for (const documentElement of design.document.elements) {
            if (!isTextDataElement(documentElement)) {
                continue;
            }
            const dirtyCharacterStyleForElement = dirtyCharacterStyles[documentElement.id];
            if (!dirtyCharacterStyleForElement) {
                continue;
            }
            documentElement.characterStyles = mergeCharacterStyles(
                documentElement.characterStyles,
                dirtyCharacterStyleForElement,
                true
            );
        }
    }
    private rerenderTexts(): void {
        if (!this.creativeDesign) {
            return;
        }
        for (const docElement of this.creativeDesign.document.elements) {
            if (isTextDataElement(docElement)) {
                const viewElement = this.renderer.getViewElementById<ITextViewElement>(docElement.id);
                if (!viewElement) {
                    continue;
                }
                viewElement.__richTextRenderer?.rerender(true);
            }
        }
    }

    private clearDirtyCharacterStylesFromDesign(
        design: IDesign,
        oldDirtyChracterStyles: DirtyCharacterStyling
    ): void {
        for (const documentElement of design.document.elements) {
            if (!isTextDataElement(documentElement)) {
                continue;
            }
            const dirtyCharacterStyleForElement = oldDirtyChracterStyles[documentElement.id];
            if (!dirtyCharacterStyleForElement) {
                continue;
            }
            for (const styleId of Object.keys(dirtyCharacterStyleForElement)) {
                documentElement.characterStyles.delete(styleId);
            }
        }
    }
}
