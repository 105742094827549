import type { AuthConfig } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';

const STUDIO_SCOPE =
    'offline_access profile openid studio design fontmanager bannerflowlibrary campaignservice commentservice videoconverter listservice bannerlingoservice';

export const AUTH0_CONFIG: AuthConfig = {
    domain: environment.auth0.domain,
    clientId: environment.auth0.clientId,
    authorizationParams: {
        redirect_uri: `${window.location.origin}/${window.location.search}`,
        audience: 'https://bannerflow.com/resources/',
        scope: STUDIO_SCOPE
    },
    httpInterceptor: {
        allowedList: Object.values(environment.origins).map(origin => `${origin}/*`)
    },
    useRefreshTokens: true
};
