import { getXmlAttribute, getXmlElement, getXmlElements } from '@studio/utils/dom-utils';
import { StreamingManifest, StreamingRepresentation } from './manifest.model';

export function parseManifest(manifest: string): StreamingManifest {
    const parser = new DOMParser();
    const xml = parser.parseFromString(manifest, 'text/xml');

    const videoSet = xml.querySelector('AdaptationSet[contentType="video"]');
    if (!videoSet) {
        throw new Error('Could not find AdaptationSet[contentType="video"]');
    }
    const videoRepresentations = Array.from(videoSet.querySelectorAll('Representation'))
        .map(mapToStreamingRepresentation)
        .sort(sortRepresentationByBandwidth);
    const audioSet = xml.querySelector('AdaptationSet[contentType="audio"]');
    const audioRepresentations = audioSet
        ? Array.from(audioSet.querySelectorAll('Representation')).map(mapToStreamingRepresentation)
        : [];
    const totalChunks = videoRepresentations.length ? videoRepresentations[0].chunkUrls.length : 0;
    const totalDuration = getTotalDurationFromManifest(xml);

    return {
        totalDuration,
        totalChunks,
        videoRepresentations,
        audioRepresentations
    };
}

export function mapToStreamingRepresentation(representation: Element): StreamingRepresentation {
    const segmentList = getXmlElement(representation, 'SegmentList');
    const isVideo = representation.getAttribute('mimeType') === 'video/mp4';
    const bandwidth = +getXmlAttribute(representation, 'bandwidth');
    const id = getXmlAttribute(representation, 'id');
    const identifier = isVideo
        ? `${representation.getAttribute('height')}p`
        : representation.getAttribute('audioSamplingRate');
    const name = `${identifier}, ${Math.floor(bandwidth / 1000)} kbps`;

    const initialization = getXmlElement(segmentList, 'Initialization');
    const initUrl = getXmlAttribute(initialization, 'sourceURL');

    const segments = getXmlElements(segmentList, 'SegmentURL');
    const duration = +getXmlAttribute(segmentList, 'duration');
    const timescale = +getXmlAttribute(segmentList, 'timescale');
    const startIndex = +getXmlAttribute(segmentList, 'startNumber');
    const chunkUrls = Array.from(segments).map(segment => getXmlAttribute(segment, 'media'));

    return {
        id,
        name,
        duration: duration / timescale,
        chunkUrls,
        initUrl,
        bandwidth,
        startIndex
    };
}

function sortRepresentationByBandwidth(
    repA: StreamingRepresentation,
    repB: StreamingRepresentation
): number {
    return repA.bandwidth - repB.bandwidth;
}

export function getTotalDurationFromManifest(manifest: Document): number {
    const mpd = manifest.querySelector('MPD');
    if (!mpd) {
        throw new Error('No MPD element found in the manifest');
    }

    const mediaPresentationDuration = getXmlAttribute(mpd, 'mediaPresentationDuration');

    return getMediaPresentationDurationInSeconds(mediaPresentationDuration);
}

export function getMediaPresentationDurationInSeconds(duration: string): number {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+(\.\d+)?S)?/);
    if (!match) {
        throw new Error('Invalid duration format');
    }
    const hours = parseFloat(match[1]) || 0;
    const minutes = parseFloat(match[2]) || 0;
    const seconds = parseFloat(match[3]) || 0;

    return hours * 3600 + minutes * 60 + seconds;
}
