import { DesignApiElementOverrideOrElement, OneOfElementDtos } from '@domain/api/design-api.interop';
import {
    GroupNodeDto as DapiGroupLikeElementDto,
    ImageElementDto as DapiImageElementDto,
    TextLikeElementDto as DapiTextLikeElementDto,
    VideoElementDto as DapiVideoElementDto,
    WidgetElementDto as DapiWidgetLikeElementDto
} from '@domain/api/generated/design-api';
import {
    ButtonElementDto,
    ElementDtoV2,
    EllipseElementDto,
    GroupNodeDto,
    ImageElementDto,
    RectangleElementDto,
    TextElementDto,
    VideoElementDto,
    WidgetElementDto
} from '@domain/api/generated/sapi';
import { IBrandLibraryElement } from '@domain/brand/brand-library';
import { ICreativeset, IElementPropertyValues, IElementValues } from '@domain/creativeset';
import { IElement, IElementProperty } from '@domain/creativeset/element';
import { AssetReference } from '@domain/creativeset/element-asset';
import {
    IInlineStyledText,
    IInlinedStyledTextSpan,
    ITextSpan,
    IVersionedText
} from '@domain/creativeset/version';
import { IBoundingBox, IBounds, IPosition, IScale } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { IFontFamily } from '@domain/font-families';
import {
    MaskNode,
    MaskedNode,
    Masking,
    OneOfMaskableElementDataNodes,
    OneOfMaskableNodeDtos
} from '@domain/mask';
import { LibraryKind } from '@domain/media-library';
import {
    CreativeKind,
    IButtonElementDataNode,
    ICreativeDataNode,
    IElementDataNode,
    IElementViewNode,
    IGroupElementDataNode,
    IGroupViewElement,
    IImageElementDataNode,
    INodeWithChildren,
    INodeWithKind,
    ISVGBackgroundNode,
    ITextDataNode,
    ITextElementDataNode,
    NodeKind,
    OneOfDataNodes,
    OneOfElementDataNodes,
    OneOfGroupDataNodes,
    OneOfGroupViewNodes,
    OneOfSelectableElements,
    OneOfTextDataNodes,
    OneOfViewNodes
} from '@domain/nodes';
import { characterProperties } from '@domain/property';
import { OneOfElementsDto, OneOfNodesDto } from '@domain/serialization';
import { IState } from '@domain/state';
import {
    ICharacterProperties,
    ICharacterStylesMap,
    IEndSpan,
    INewlineSpan,
    IRequiredTextProperties,
    ISpaceSpan,
    IText,
    ITextProperties,
    IWordSpan,
    OneOfEditableSpans,
    SpanType,
    VARIABLE_PREFIX
} from '@domain/text';
import { cloneDeep } from '@studio/utils/clone';
import { uuidv4 } from '@studio/utils/id';
import { isElementVisibleAtTime } from '../animation.utils';
import { isVersionedText } from '../elements/rich-text/text-nodes';
import { getFontStyleById, tryGetFontStyleById } from '../font-families.utils';
import { deserializeVersionedText } from '../serialization/text-serializer';
import { hasHiddenAncestor } from './data-node.utils';
import { isElementSelection } from './selection';

export function getKindAsString(kind: LibraryKind): string {
    switch (kind) {
        case LibraryKind.Text:
            return 'texts';
        case LibraryKind.Button:
            return 'buttons';
        case LibraryKind.Rectangle:
            return 'shapes';
        case LibraryKind.Ellipse:
            return 'shapes';
        case LibraryKind.Image:
            return 'images';
        case LibraryKind.Video:
            return 'videos';
        case LibraryKind.Widget:
            return 'widgets';
        case LibraryKind.Effects:
            return 'effects';
        case LibraryKind.Any:
            return 'elements';
        case LibraryKind.Feeds:
            return 'feeds';
        case LibraryKind.Selection:
            return 'selection';
    }
}

export function getKindTitleAsString(kind: LibraryKind): string {
    switch (kind) {
        case LibraryKind.Text:
            return 'Text Tool';
        case LibraryKind.Button:
            return 'Button Tool';
        case LibraryKind.Rectangle:
            return 'Rectangle Tool';
        case LibraryKind.Ellipse:
            return 'Ellipse Tool';
        case LibraryKind.Image:
            return 'Images';
        case LibraryKind.Video:
            return 'Videos';
        case LibraryKind.Widget:
            return 'Widgets';
        case LibraryKind.Effects:
            return 'Effects';
        case LibraryKind.Any:
            return 'Brand Library';
        case LibraryKind.Feeds:
            return 'Dynamic Content';
        case LibraryKind.Selection:
            return '';
    }
}

export function getKindMessageAsString(kind: LibraryKind): string {
    switch (kind) {
        case LibraryKind.Text:
            return 'Click/drag on canvas to\ncreate a new text area';
        case LibraryKind.Button:
            return 'Click/drag on canvas to\ncreate a new button';
        case LibraryKind.Rectangle:
            return 'Click/drag on canvas to\ncreate a new rectangle';
        case LibraryKind.Ellipse:
            return 'Click/drag on canvas to\ncreate a new ellipse';
        case LibraryKind.Image:
            return 'Drag in or upload an image\n to store it here';
        case LibraryKind.Video:
            return 'Drag in or upload an video\n to store it here';
        case LibraryKind.Widget:
            return 'Import a stock widget or\ncreate a new one from scratch';
        case LibraryKind.Effects:
            return 'Use a stock effect';
        case LibraryKind.Any:
            return 'Store elements that you\nwant to reuse across\ncreative sets in the library';
        case LibraryKind.Feeds:
            return 'Dynamic feed variables\nwill show up here';
        default:
            return '';
    }
}

const SEARCH_PLACEHOLDER_MAP: Partial<Record<LibraryKind | 'library', string>> = {
    [LibraryKind.Text]: 'Search text elements',
    [LibraryKind.Button]: 'Search buttons',
    [LibraryKind.Rectangle]: 'Search rectangles',
    [LibraryKind.Ellipse]: 'Search ellipses',
    [LibraryKind.Image]: 'Search images',
    [LibraryKind.Widget]: 'Search widgets',
    [LibraryKind.Effects]: 'Search effects',
    [LibraryKind.Video]: 'Search videos',
    [LibraryKind.Feeds]: 'Search dynamic content',
    library: 'Search in library'
};

export function getLibraryKindFromElementKind(kind: ElementKind): LibraryKind {
    switch (kind) {
        case ElementKind.Text:
            return LibraryKind.Text;
        case ElementKind.Button:
            return LibraryKind.Button;
        case ElementKind.Rectangle:
            return LibraryKind.Rectangle;
        case ElementKind.Ellipse:
            return LibraryKind.Ellipse;
        case ElementKind.Image:
            return LibraryKind.Image;
        case ElementKind.Video:
            return LibraryKind.Video;
        case ElementKind.Widget:
        case ElementKind.BannerflowLibraryWidget:
        case ElementKind.BannerflowLibraryWidgetInstance:
            return LibraryKind.Widget;
        default:
            return LibraryKind.Any;
    }
}

export function getElementKindFromLibraryKind(kind: LibraryKind): ElementKind | undefined {
    if (kind === LibraryKind.Any || kind === LibraryKind.Feeds || kind === LibraryKind.Selection) {
        return;
    }

    switch (kind) {
        case LibraryKind.Text:
            return ElementKind.Text;
        case LibraryKind.Button:
            return ElementKind.Button;
        case LibraryKind.Rectangle:
            return ElementKind.Rectangle;
        case LibraryKind.Ellipse:
            return ElementKind.Ellipse;
        case LibraryKind.Image:
            return ElementKind.Image;
        case LibraryKind.Video:
            return ElementKind.Video;
        case LibraryKind.Widget:
        case LibraryKind.Effects:
            return ElementKind.Widget;
        default:
            throw new Error(`Unknown library kind: ${kind}`);
    }
}

export function getSearchPlaceholderAsString(kind?: LibraryKind): string {
    return SEARCH_PLACEHOLDER_MAP[kind ?? 'library'] ?? SEARCH_PLACEHOLDER_MAP.library!;
}

export function mergeWidgetKind(kind: ElementKind): ElementKind {
    if (isWidgetNode({ kind })) {
        return ElementKind.Widget;
    }
    return kind;
}

// Helper function to in order to make typing easier
function isNodeWithKind<IElementWithKind extends INodeWithKind<Kind>, Kind extends NodeKind = NodeKind>(
    element: IElementWithKind | undefined | object
): element is IElementWithKind {
    return Boolean(element && 'kind' in element);
}

function buildIsOfKind<Kinds extends NodeKind[]>(...kinds: Kinds) {
    return function <Element extends INodeWithKind<ElementKind> | object>(
        node?: Element
    ): node is Extract<Element, { kind: Kinds[number] }> {
        if (!isNodeWithKind(node)) {
            return false;
        }
        return kinds.findIndex(testKind => node.kind === testKind) !== -1;
    };
}

export const isTextNode = buildIsOfKind(ElementKind.Button, ElementKind.Text);
export const isEllipseNode = buildIsOfKind(ElementKind.Ellipse);
export const isRectangleNode = buildIsOfKind(ElementKind.Rectangle);
export const isCreativeNode = buildIsOfKind(CreativeKind.Creative);
export const isVideoNode = buildIsOfKind(ElementKind.Video);
export const isImageNode = buildIsOfKind(ElementKind.Image);
export const isImageOrVideoNode = buildIsOfKind(ElementKind.Image, ElementKind.Video);
export function isGroupDataNode(
    element?: OneOfDataNodes | OneOfViewNodes | INodeWithChildren
): element is IGroupElementDataNode {
    return !!element && buildIsOfKind(ElementKind.Group)(element);
}
export const isWidgetNode = buildIsOfKind(
    ElementKind.Widget,
    ElementKind.BannerflowLibraryWidget,
    ElementKind.BannerflowLibraryWidgetInstance
);

function isNodeDtoKind(node: OneOfNodesDto | OneOfElementDtos, kind: ElementKind): boolean {
    return Boolean(node[`__${kind}Kind`]);
}
export function isRectangleNodeDto(node: OneOfNodesDto): node is RectangleElementDto {
    return isNodeDtoKind(node, ElementKind.Rectangle);
}
export function isEllipseNodeDto(node: OneOfNodesDto): node is EllipseElementDto {
    return isNodeDtoKind(node, ElementKind.Ellipse);
}
export function isTextNodeDto(node: OneOfNodesDto): node is TextElementDto {
    return isNodeDtoKind(node, ElementKind.Text);
}
export function isButtonNodeDto(node: OneOfNodesDto): node is ButtonElementDto {
    return isNodeDtoKind(node, ElementKind.Button);
}
export function isVideoNodeDto(node: OneOfNodesDto): node is VideoElementDto {
    return isNodeDtoKind(node, ElementKind.Video);
}
export function isImageNodeDto(node: OneOfNodesDto): node is ImageElementDto {
    return isNodeDtoKind(node, ElementKind.Image);
}
export function isWidgetNodeDto(node: OneOfNodesDto): node is WidgetElementDto {
    return isNodeDtoKind(node, ElementKind.Widget);
}
export function isGroupNodeDto(node: OneOfNodesDto): node is GroupNodeDto {
    return isNodeDtoKind(node, ElementKind.Group);
}

function isDapiNodeDtoKind(node: DesignApiElementOverrideOrElement, kind: ElementKind): boolean {
    return Boolean(node[`__${kind}Kind`]);
}
function isNodeDtoTextLikeKind(node: DesignApiElementOverrideOrElement): boolean {
    return Boolean(node['__textLikeKind']);
}
export function isDapiImageNodeDto(
    node: DesignApiElementOverrideOrElement
): node is DapiImageElementDto {
    return isDapiNodeDtoKind(node, ElementKind.Image);
}
export function isDapiTextLikeNodeDto(
    node: DesignApiElementOverrideOrElement
): node is DapiTextLikeElementDto {
    return isNodeDtoTextLikeKind(node);
}
export function isDapiVideoNodeDto(
    node: DesignApiElementOverrideOrElement
): node is DapiVideoElementDto {
    return isDapiNodeDtoKind(node, ElementKind.Video);
}
export function isDapiWidgetNodeDto(
    node: DesignApiElementOverrideOrElement
): node is DapiWidgetLikeElementDto {
    return isDapiNodeDtoKind(node, ElementKind.Widget);
}
export function isDapiWidgetWithAssetNodeDto(
    node: DesignApiElementOverrideOrElement
): node is DapiWidgetLikeElementDto {
    return isDapiWidgetNodeDto(node) && !!node.widgetAsset;
}
export function isDapiGroupNodeDto(node: OneOfElementDtos): node is DapiGroupLikeElementDto {
    return isNodeDtoKind(node, ElementKind.Group);
}

export function isWidgetElementDto(element?: ElementDtoV2): boolean {
    return (
        element !== undefined &&
        (element.type === ElementKind.Widget ||
            element.type === ElementKind.BannerflowLibraryWidget ||
            element.type === ElementKind.BannerflowLibraryWidgetInstance)
    );
}

export function isTextLikeElement(element?: IElement): boolean {
    return element !== undefined && [ElementKind.Text, ElementKind.Button].includes(element.type);
}

export function isVideoElement(element?: Omit<IElement, 'id'>): boolean {
    return element !== undefined && element.type === ElementKind.Video;
}

export function isImageElement(element?: Omit<IElement, 'id'>): boolean {
    return element !== undefined && element.type === ElementKind.Image;
}

export function isImageOrVideoElement(element?: Omit<IElement, 'id'>): boolean {
    return element !== undefined && (isImageElement(element) || isVideoElement(element));
}

export function isTextDataElement<Kind extends ElementKind>(
    node?: Partial<IElementViewNode<Kind> | OneOfElementDataNodes | IElementDataNode<Kind>>
): node is ITextDataNode<Kind> {
    return (
        node !== undefined &&
        (node.kind === ElementKind.Button || node.kind === ElementKind.Text) &&
        !isViewElementNode(node)
    );
}

export function isWidgetElement(element?: IElement | IBrandLibraryElement): boolean {
    return (
        element !== undefined &&
        [
            ElementKind.Widget,
            ElementKind.BannerflowLibraryWidget,
            ElementKind.BannerflowLibraryWidgetInstance
        ].includes(element.type)
    );
}

export function isWidgetElementWithTranslatableContent(element: IElement): boolean {
    return (
        isWidgetElement(element) &&
        Boolean(
            element.properties.find(property => property.unit === 'text' || property.unit === 'feed')
        )
    );
}

export function isViewElementNode(
    node: Partial<OneOfViewNodes> | Partial<ISVGBackgroundNode> | OneOfElementDataNodes
): node is IElementViewNode {
    if (node === undefined || !('kind' in node)) {
        return false;
    }

    return node.kind !== CreativeKind.Creative && node.kind !== ElementKind.Group && node['__data'];
}

export function isSelectionVisibleAtTime(element: OneOfDataNodes, time: number): boolean {
    return !isHidden(element) && isVisibleAtTime(element, time);
}

export function isVisibleAtTime(element: OneOfDataNodes, time: number): boolean {
    return time >= element.time && time <= element.time + element.duration;
}

export function isState(element: OneOfElementDataNodes | IState | undefined): element is IState {
    if (!element) {
        return false;
    }
    return !('kind' in element) || typeof element.kind !== 'string';
}

export function isElementDataNode(
    element: OneOfDataNodes | IState | OneOfViewNodes
): element is OneOfElementDataNodes {
    return !isState(element) && !isViewElementNode(element);
}

export function canBeMask(node: OneOfDataNodes): node is OneOfMaskableElementDataNodes {
    return isRectangleNode(node) || isEllipseNode(node) || isImageNode(node);
}

export function isMaskingSupported(node: OneOfDataNodes): node is OneOfMaskableElementDataNodes {
    return canBeMask(node) || isVideoNode(node);
}

export function isMaskingSupportedDto(node: OneOfElementsDto): node is OneOfMaskableNodeDtos {
    return (
        isRectangleNodeDto(node) ||
        isEllipseNodeDto(node) ||
        isImageNodeDto(node) ||
        isVideoNodeDto(node)
    );
}

export function isUsedInMask(
    node: OneOfDataNodes
): node is OneOfMaskableElementDataNodes & { masking: Masking } {
    return isMaskingSupported(node) && node.masking !== undefined;
}

export function isMaskNode(node: OneOfDataNodes): node is OneOfMaskableElementDataNodes & MaskNode {
    return isUsedInMask(node) && node.masking.isMask === true;
}

export function isMaskedNode(node: OneOfDataNodes): node is OneOfMaskableElementDataNodes & MaskedNode {
    return isUsedInMask(node) && node.masking.elementId !== undefined;
}

export function getClosestMaskIndex(nodeIndex: number, nodes: OneOfDataNodes[]): number {
    for (let i = nodeIndex; i < nodes.length; i++) {
        const node = nodes[i];
        if (isMaskNode(node)) {
            return i;
        }
    }

    return -1;
}

export function getClosestGroupIndex(nodeIndex: number, nodes: OneOfDataNodes[]): number {
    for (let i = nodeIndex; i < nodes.length; i++) {
        const node = nodes[i];
        if (isGroupDataNode(node)) {
            return i;
        }
    }

    return -1;
}

export function sortToMaskable<T extends OneOfMaskableElementDataNodes>(nodes: T[]): T[] {
    const newArr: T[] = [];

    for (const node of nodes) {
        const hasMaskableNode = newArr.some(canBeMask);
        if (!hasMaskableNode && canBeMask(node)) {
            newArr.unshift(node);
        } else {
            newArr.push(node);
        }
    }

    return newArr;
}

export function createDefaultTextProperties(
    dataOverride?: ITextElementDataNode | IButtonElementDataNode
): ITextProperties {
    /** TODO: Fix these typecasts at some point for safer typing */
    const properties = {
        padding: dataOverride?.padding ?? { top: 0, left: 0, right: 0, bottom: 0 },
        verticalAlignment: dataOverride?.verticalAlignment ?? 'top',
        maxRows: dataOverride?.maxRows ?? Infinity,
        textOverflow: dataOverride?.textOverflow ?? 'expand',
        characterSpacing: dataOverride?.characterSpacing ?? 0,
        lineHeight: dataOverride?.lineHeight ?? 1.3,
        __fontFamilyId: ''
    } as IRequiredTextProperties;
    return properties as ITextProperties;
}

const characters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
];
let currentCidIndex = 1;

export function createElementCid(): string {
    const position = currentCidIndex++;
    return getNumberSystemEncoding(position);
}

export function getNumberSystemEncoding(position: number): string {
    const numberSystem = characters.length;
    if (position <= numberSystem) {
        return characters[position - 1];
    }
    const numerals: string[] = [];
    let currentNumber = position;
    while (currentNumber >= numberSystem) {
        const remainder = currentNumber % numberSystem;
        currentNumber = (currentNumber - remainder) / numberSystem;
        numerals.push(characters[remainder]);
    }
    numerals.push(characters[currentNumber - 1]);
    return numerals.reverse().join('');
}

export function resetNodeCid(): void {
    currentCidIndex = 1;
}

export function getCurrentCidIndex(): number {
    return currentCidIndex;
}

export function forEachElement<Element extends OneOfViewNodes>(
    group: OneOfGroupViewNodes,
    callback: (node: Element, group: IGroupViewElement | undefined, index: number) => void,
    atTime?: number,
    reverse?: boolean
): void {
    forEachNode(group, callback, atTime, reverse);
}

export function forEachDataElement<Node extends OneOfElementDataNodes>(
    group: OneOfGroupDataNodes | OneOfDataNodes[],
    callback: (element: Node, group: IGroupElementDataNode | undefined, index: number) => void,
    atTime?: number,
    reverse?: boolean
): void {
    forEachNode(group, callback, atTime, reverse);
}

function forEachNode<
    T extends OneOfViewNodes | OneOfElementDataNodes,
    Group extends IGroupElementDataNode | IGroupViewElement
>(
    group: OneOfGroupDataNodes | OneOfDataNodes[] | OneOfGroupViewNodes,
    callback: (node: T, group: Group | undefined, index: number) => void,
    atTime?: number,
    reverse?: boolean
): void {
    let nodes: OneOfDataNodes[] | OneOfViewNodes[] = [];
    if (isNodeWithKind(group)) {
        switch (group.kind) {
            case CreativeKind.Creative:
            case ElementKind.Group:
                nodes = group.nodes;
                break;
            default:
                throw new Error('Unknown group element.');
        }
    } else {
        nodes = group;
    }

    if (reverse) {
        nodes = nodes.slice().reverse();
    }

    let i = 0;
    for (const node of nodes) {
        if (isGroupDataNode(node)) {
            forEachNode(
                node,
                groupNode => {
                    callback(groupNode as T, node as Group, i);
                },
                atTime,
                reverse
            );
        } else if (
            typeof atTime !== 'number' ||
            isElementVisibleAtTime(node as OneOfElementDataNodes, atTime)
        ) {
            callback(node as T, undefined, i);
        }

        i++;
    }
}

/**
 * @returns Returns a flat nodelist of element nodes. Groups are excluded.
 */
export function toFlatElementNodeList(
    nodes: OneOfGroupDataNodes | OneOfDataNodes[]
): OneOfElementDataNodes[] {
    const flatNodeList: OneOfElementDataNodes[] = [];

    forEachDataElement(nodes, element => {
        if (isGroupDataNode(element)) {
            return;
        }
        flatNodeList.push(element);
    });

    return flatNodeList;
}

/**
 * @returns Returns a completely flattened array of all nodes, including groups.
 */
export function toFlatNodeList(nodes: OneOfGroupDataNodes | OneOfDataNodes[]): OneOfDataNodes[] {
    if (Array.isArray(nodes) && nodes.every(node => !isGroupDataNode(node))) {
        return nodes;
    }

    const flatNodeListArr: OneOfDataNodes[] = [];
    const nodeArr = 'nodes' in nodes ? nodes.nodes : nodes;

    for (const node of nodeArr.slice().reverse()) {
        if (isGroupDataNode(node)) {
            flatNodeListArr.unshift(node);
            flatNodeListArr.unshift(...toFlatNodeList(node));
        } else if (flatNodeListArr.indexOf(node) === -1) {
            flatNodeListArr.unshift(node);
        }
    }

    return flatNodeListArr;
}

export function isHidden(node: OneOfDataNodes): boolean {
    return node.hidden || hasHiddenAncestor(node);
}

/**
 * @param  {OneOfDataNodes|undefined} node
 * @param  {(parent:IGroupElementDataNode)=>boolean|void} callback
 * Return true to break loop
 */
export function forEachParentNode(
    node: OneOfDataNodes | undefined,
    callback: (parent: IGroupElementDataNode) => boolean | void
): void {
    if (!node) {
        return;
    }

    let parentNode = node.__parentNode;
    while (parentNode) {
        const cancel = callback(parentNode);
        if (cancel) {
            return;
        }
        parentNode = parentNode.__parentNode;
    }
}

export function positionIsInBounds(
    position: IPosition,
    bounds: (IBounds & Partial<IScale>) | (IBoundingBox & Partial<IScale>),
    tolerance: IPosition = { x: 0, y: 0 }
): boolean {
    let checkLeftBound: boolean;
    let checkTopBound: boolean;
    let checkRightBound: boolean;
    let checkBottomBound: boolean;
    if ('scaleX' in bounds && bounds.scaleX) {
        checkLeftBound = position.x >= bounds.x + bounds.width / 2 - (bounds.width * bounds.scaleX) / 2;
        checkRightBound =
            position.x <= bounds.x + bounds.width / 2 + (bounds.width * bounds.scaleX) / 2;
    } else {
        const minX = bounds.x - tolerance.x;
        const maxX = bounds.x + bounds.width + tolerance.x;
        checkLeftBound = position.x >= minX;
        checkRightBound = position.x <= maxX;
    }
    if ('scaleY' in bounds && bounds.scaleY) {
        checkTopBound =
            position.y >= bounds.y + bounds.height / 2 - (bounds.height * bounds.scaleY) / 2;
        checkBottomBound =
            position.y <= bounds.y + bounds.height / 2 + (bounds.height * bounds.scaleY) / 2;
    } else {
        const minY = bounds.y - tolerance.y;
        const maxY = bounds.y + bounds.height + tolerance.y;
        checkTopBound = position.y >= minY;
        checkBottomBound = position.y <= maxY;
    }
    if (checkLeftBound && checkRightBound) {
        if (checkTopBound && checkBottomBound) {
            return true;
        }
    }
    return false;
}

export function isNumberBetween(origin: number, num2: number, margin = 5): boolean {
    return (origin - num2 - margin) * (origin - num2 + margin) <= 0;
}

export function getElementTextProperties(data: OneOfTextDataNodes): Partial<ICharacterProperties> {
    const style: Partial<ICharacterProperties> = {};
    for (const characterProperty of characterProperties) {
        const value = data[characterProperty];
        if (value !== undefined) {
            style[characterProperty] = value;
        }
    }
    return style;
}

export function getElementIndex(element: OneOfDataNodes): number {
    return element.__rootNode!.elements.findIndex(e => e.id === element.id);
}

export function asSortedArray<T extends OneOfElementDataNodes>(elements: T[]): T[] {
    return Array.from(elements).sort((a, b) => getElementIndex(a) - getElementIndex(b));
}

export function getNodeIndex(element: OneOfDataNodes): number {
    return toFlatNodeList(element.__rootNode!.nodes).findIndex(e => e.id === element.id);
}

export function getElementIdentifier(elementOrSelection: OneOfSelectableElements): string {
    if (isElementSelection(elementOrSelection)) {
        if (elementOrSelection.length === 1) {
            const element = elementOrSelection.element!;
            return `${element.kind}:${element.id}`;
        } else {
            return `Selection: ${elementOrSelection.length}`;
        }
    } else {
        const element = elementOrSelection;
        return `${element.kind}:${element.id}`;
    }
}

export function createViewElement(element: OneOfElementDataNodes): OneOfViewNodes {
    if (isGroupDataNode(element)) {
        throw new Error('View elements can not be created from a group node.');
    }

    const viewElement = {
        kind: element.kind,
        __data: element
    } as OneOfViewNodes;

    viewElement.elementCid = createElementCid();
    viewElement.cidIndex = getCurrentCidIndex();
    viewElement.id = element.id;
    viewElement.name = element.name;
    viewElement.ratio = element.ratio;

    viewElement.time = element.time;
    viewElement.duration = element.duration;

    if (isTextNode(viewElement) && isTextNode(element)) {
        viewElement.font = element.font;
        viewElement.content = element.content;
    } else if (isImageNode(element) && isImageNode(viewElement)) {
        viewElement.feed = element.feed;
    } else if (isWidgetNode(viewElement) && isWidgetNode(element)) {
        viewElement.customProperties = element.customProperties;
    }

    return viewElement;
}

export function initializeElements(
    creative: ICreativeDataNode,
    elements: IElement[],
    values: IElementValues
): IElement[] {
    const patchedElements: IElement[] = [];

    for (const node of creative.nodeIterator_m(true)) {
        let globalElement = elements.find(el => el.id === node.id);

        if (!globalElement) {
            console.error(`Could not find global element with id '${node.id}'.`);

            // Hack to patch orphan elements SUP-4476
            const properties: IElementProperty[] = [];
            if (isImageNode(node)) {
                properties.push({
                    id: '',
                    name: AssetReference.Image,
                    unit: 'id',
                    value: node.imageAsset?.id,
                    clientId: uuidv4()
                });
            }

            if (isVideoNode(node)) {
                properties.push({
                    id: '',
                    name: AssetReference.Video,
                    unit: 'id',
                    value: node.videoAsset?.id,
                    clientId: uuidv4()
                });
            }

            const patchedElement: IElement = {
                id: node.id,
                name: node.kind,
                type: node.kind,
                properties
            };

            globalElement = patchedElement;
            patchedElements.push(patchedElement);
        }

        if (isGroupDataNode(node)) {
            node.name = globalElement.name;
            continue;
        }
        node.name = globalElement.name;

        initializeElementProperties(node, {
            elementProperties: globalElement.properties,
            versionProperties: values.versionProperties,
            defaultVersionProperties: values.defaultVersionProperties,
            fontFamilies: values.fontFamilies
        });
    }

    return patchedElements;
}

export function initializeFonts<Kind extends ElementKind>(
    textElement: ITextDataNode<Kind>,
    fontFamilies: Readonly<IFontFamily[]>
): void {
    for (const style of textElement.characterStyles.values()) {
        if (style.font) {
            if (typeof style.font === 'string') {
                try {
                    const fontStyle = getFontStyleById(fontFamilies, style.font);
                    style.font = {
                        id: fontStyle!.id,
                        src: fontStyle!.fontUrl,
                        weight: fontStyle!.weight,
                        style: fontStyle!.italic ? 'italic' : 'normal',
                        fontFamilyId: fontStyle!.fontFamilyId!
                    };
                } catch (err) {
                    console.error(err);
                    delete style.font;
                }
            }
        }
    }
}

export function initializeElementProperties(
    element: OneOfElementDataNodes,
    values: IElementPropertyValues
): void {
    const {
        elementProperties,
        versionProperties: versionValues,
        defaultVersionProperties: defaultVersionValues
    } = values;

    if (isTextNode(element)) {
        const fontStyleId = element.font?.id || element.__fontStyleId;
        const fontStyle = tryGetFontStyleById(values.fontFamilies, fontStyleId || '');
        element.font = undefined;
        if (fontStyle) {
            // Note: font is undefined before initialization, we just store the font style id as a reference.
            element.font = {
                id: fontStyle.id,
                src: fontStyle.fontUrl,
                weight: fontStyle.weight,
                style: fontStyle.italic ? 'italic' : 'normal',
                fontFamilyId: fontStyle.fontFamilyId!
            };
        }
    }

    for (const property of elementProperties) {
        if (property.versionPropertyId) {
            let versionValue = versionValues.find(v => v.id === property.versionPropertyId);
            if (!versionValue) {
                versionValue = defaultVersionValues.find(e => e.id === property.versionPropertyId);
            }
            if (!versionValue) {
                throw new Error(
                    `Could not find value from global elements, selected version or original. ID: ${property.versionPropertyId}`
                );
            }
            const name = property.name;
            if (name === 'content' && isTextNode(element) && isVersionedText(versionValue)) {
                initializeFonts(element, values.fontFamilies);
                element.content = createTextFromVersionedText(
                    element,
                    versionValue.value,
                    element.characterStyles
                );
            } else if (isWidgetNode(element)) {
                const versionProperty = element.customProperties.find(
                    prop => prop.versionPropertyId === property.versionPropertyId
                );
                if (versionProperty) {
                    versionProperty.value = versionValue.value;
                }
            } else {
                element[name] = versionValue.value;
            }
        } else {
            element[property.name] = property.value;
        }
    }
}

export function createVersionedTextFromText(text: IText): IVersionedText {
    let position = 0;
    const textSpans: ITextSpan[] = [];
    let _text = '';
    for (const span of text.spans) {
        if (span.type === SpanType.End) {
            break;
        }

        const length =
            span.type === SpanType.Variable
                ? span.style.variable!.path.length + 1
                : span.content.length;
        textSpans.push({
            position,
            length,
            type: span.type,
            styleIds: { ...(span.styleIds || {}) },
            variable: span.style.variable,
            __previousStyleIds: [...(span.__previousStyleIds || [])],
            __previousStyleIdToHistoryIndexMap: new Map(span.__previousStyleIdToHistoryIndexMap || [])
        });
        position += length;
        _text +=
            span.type === SpanType.Variable
                ? VARIABLE_PREFIX + span.style.variable!.path
                : span.content;
    }
    return {
        text: _text,
        styles: textSpans
    };
}

export function createInlineStyledTextFromText(text: IText): IInlineStyledText {
    let position = 0;
    const textSpans: IInlinedStyledTextSpan[] = [];
    let _text = '';

    const copyText = JSON.parse(JSON.stringify(text));

    for (const span of copyText.spans) {
        if (span.type === SpanType.End) {
            break;
        }

        const length = span.content.length;
        textSpans.push({
            position,
            length,
            type: span.type,
            style: span.style,
            variable: span.style.variable
        });
        position += length;
        _text += span.content;
    }
    return {
        text: _text,
        styles: textSpans
    };
}

export function createTextFromInlineStyledText(
    inlineStyledText: IInlineStyledText,
    textProperties?: ITextProperties
): IText {
    const text = inlineStyledText.text;
    const spans: OneOfEditableSpans[] = [];
    for (const span of inlineStyledText.styles) {
        switch (span.type) {
            case SpanType.Word:
            case SpanType.Variable:
            case SpanType.Space: {
                spans.push({
                    type: span.type,
                    attributes: {},
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    content: text.substr(span.position, span.length),
                    style: cloneDeep(span.style),
                    styleIds: {},
                    __previousStyleIds: [],
                    __previousStyleIdToHistoryIndexMap: new Map<string, number>()
                } as IWordSpan | ISpaceSpan);
                break;
            }
            case SpanType.Newline: {
                spans.push({
                    type: span.type,
                    attributes: {},
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    content: '\n',
                    style: cloneDeep(span.style),
                    styleIds: {},
                    __previousStyleIds: [],
                    __previousStyleIdToHistoryIndexMap: new Map<string, number>()
                } as INewlineSpan);
                break;
            }
            default:
                throw new TypeError(
                    `Expected span of type 'word', 'space' 'newline', instead got '${span.type}'.`
                );
        }
    }

    spans.push({
        type: SpanType.End,
        content: 'END'
    } as IEndSpan);

    return {
        style: textProperties ?? createDefaultTextProperties(),
        spans
    };
}

export function createTextFromVersionedText(
    element: ITextElementDataNode | IButtonElementDataNode,
    versionedText: IVersionedText,
    elementsCharacterStyles: ICharacterStylesMap
): IText {
    // This should hopefully not be a problem since the types have been updated, but I'll leave it here
    // just in case
    if (!element.__rootNode) {
        throw new Error(
            `Expected versioned text with root node, received '${typeof element.__rootNode}'`
        );
    }

    if (typeof versionedText === 'string') {
        versionedText = deserializeVersionedText(versionedText);
    }
    const text = versionedText.text;
    const spans: OneOfEditableSpans[] = [];
    for (const span of versionedText.styles) {
        const styleId = span.styleIds[element.__rootNode.id];
        const characterStyleValue = elementsCharacterStyles.get(styleId) || {};

        switch (span.type) {
            case SpanType.Variable: {
                spans.push({
                    type: span.type,
                    attributes: {},
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    content: VARIABLE_PREFIX + span.variable!.path,
                    style: Object.assign(cloneDeep(characterStyleValue), {
                        variable: span.variable
                    }),
                    styleIds: cloneDeep(span.styleIds),
                    styleId,
                    __previousStyleIds: [...(span.__previousStyleIds || [])],
                    __previousStyleIdToHistoryIndexMap: new Map(
                        span.__previousStyleIdToHistoryIndexMap || []
                    )
                });
                break;
            }
            case SpanType.Word:
            case SpanType.Space: {
                spans.push({
                    type: span.type,
                    attributes: {},
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    content: text.substr(span.position, span.length),
                    style: cloneDeep(characterStyleValue),
                    styleIds: cloneDeep(span.styleIds),
                    styleId,
                    __previousStyleIds: [...(span.__previousStyleIds || [])],
                    __previousStyleIdToHistoryIndexMap: new Map(
                        span.__previousStyleIdToHistoryIndexMap || []
                    )
                } as IWordSpan | ISpaceSpan);
                break;
            }
            case SpanType.Newline: {
                spans.push({
                    type: span.type,
                    attributes: {},
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    lineHeight: 0,
                    content: '\n',
                    style: cloneDeep(characterStyleValue),
                    styleIds: cloneDeep(span.styleIds),
                    styleId,
                    __previousStyleIds: [...(span.__previousStyleIds || [])],
                    __previousStyleIdToHistoryIndexMap: new Map(
                        span.__previousStyleIdToHistoryIndexMap || []
                    )
                } as INewlineSpan);
                break;
            }
            default:
                throw new TypeError(
                    `Expected span of type 'word', 'space' 'newline', instead got '${span.type}'.`
                );
        }
    }

    spans.push({
        type: SpanType.End,
        content: 'END'
    } as IEndSpan);

    return {
        style: createDefaultTextProperties(element),
        spans
    };
}

/**
 * If it is a widget imported from BF library or if the widget is custom but was published to the BF library
 */
export function isAnyBannerflowLibraryWidget(element: IElement): boolean {
    return isBannerFlowLibraryWidget(element) || isOriginalBannerFlowLibraryWidget(element);
}

export function isBannerFlowLibraryWidget(element: IElement): boolean {
    // If the type is of bannerflowLibraryWidget then it's imported
    return (
        element.type === ElementKind.BannerflowLibraryWidget ||
        !!element.properties?.find(prop => prop.name === 'bannerflowLibraryWidgetReference')
    );
}

/**
 * Whether it's a bannerflow library widget instance element or not
 */
export function isBFLWidgetElementInstance(element?: IElement): boolean {
    return element !== undefined && element.type === ElementKind.BannerflowLibraryWidgetInstance;
}

export function isCustomWidgetElement(element?: IElement): boolean {
    return element !== undefined && element.type === ElementKind.Widget;
}

export function isOriginalBannerFlowLibraryWidget(element: IElement): boolean {
    // If the type is of bannerflowLibraryWidget then it's imported
    return !!(
        isCustomWidgetElement(element) &&
        element.properties?.find(prop => prop.name === 'bannerflowLibraryWidgetReference')
    );
}

export function getTextElements(creative: ICreativeDataNode): OneOfTextDataNodes[] {
    const result: OneOfTextDataNodes[] = [];
    forEachDataElement(creative, element => {
        if (isTextNode(element)) {
            result.push(element);
        }
    });
    return result;
}

export function isOrphanElement(element: IElement, creativeset: ICreativeset): boolean {
    for (const design of creativeset.designs) {
        const hasElement = design.elements.some(el => el.id === element.id);

        if (hasElement) {
            return false;
        }
    }
    return true;
}

export function ratioLockSvgElement(url: string, imageNode: IImageElementDataNode): void {
    const isSvg = /(\.(svg))(\/?(\?.*)?)$/gi;

    if ((url || '').match(isSvg)) {
        imageNode.ratio = imageNode.width / imageNode.height;
    }
}
