import { Injectable, OnDestroy } from '@angular/core';
import { getAnimationFromTemplate } from '@creative/animation-templates';
import {
    TimelineResizeDirection,
    getAnimationDuration,
    getInAnimationDuration,
    getOutAnimationDuration,
    removeAnimationsOfType,
    setAnimationDuration,
    setDurationOfElements,
    setDurationOfType,
    setElementTimeAndDuration,
    sortAnimations,
    validateAnimations
} from '@creative/animation.utils';
import { createBaseElement } from '@creative/element-templates';
import { calculateBoundingBox } from '@creative/elements/utils';
import {
    GroupDataNode,
    getClosestGroupIndex,
    getClosestMaskIndex,
    isMaskNode,
    isMaskingSupported
} from '@creative/nodes';
import {
    findExpandedAncestor,
    getBoundingBoxOfGroup,
    getSizeAndPositionOfNode,
    hasLockedAncestor,
    isLocked
} from '@creative/nodes/data-node.utils';
import {
    getElementIdentifier,
    isElementDataNode,
    isGroupDataNode,
    isHidden,
    isImageNode,
    isTextNode,
    isVideoNode,
    toFlatElementNodeList,
    toFlatNodeList
} from '@creative/nodes/helpers';
import { ElementSelection, isElementSelection } from '@creative/nodes/selection';
import { IRenderer } from '@creative/renderer.header';
import { getBoundingBoxOfElementWithState, isFormulaValue, isStateProperty } from '@creative/rendering';
import { convertStateToDto, deserializeState, validateElementProperty } from '@creative/serialization';
import { AnimationType, IAnimation, IAnimationTemplate } from '@domain/animation';
import { IPreloadImage } from '@domain/creativeset/creative';
import { IElement } from '@domain/creativeset/element';
import { IImageElementAsset, IVideoElementAsset } from '@domain/creativeset/element-asset';
import { IBoundingBox, IOffset, IPosition, ISize } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import {
    IImageSettings,
    ImageSettingsKeys,
    ImageSizeMode,
    imageSettingsProperties
} from '@domain/image';
import { Masking } from '@domain/mask';
import {
    AllDataNodes,
    CreativeKind,
    ICreativeDataNode,
    IElementViewNode,
    IGroupElementDataNode,
    IImageElementDataNode,
    INodeWithChildren,
    ITextViewElement,
    IVideoElementDataNode,
    OneOfDataNodes,
    OneOfElementDataNodes,
    OneOfSelectableElements,
    OneOfTextDataNodes,
    OneOfTextViewElements
} from '@domain/nodes';
import { OneOfElementPropertyKeys } from '@domain/property';
import { IState } from '@domain/state';
import { IVideoSettings, VideoSizeMode } from '@domain/video';
import { IWidgetElementDataNode, IWidgetViewElement } from '@domain/widget';
import { TransformMode } from '@domain/workspace';
import { ISocialGuide } from '@studio/domain/social';
import { ActivityLoggerService } from '@studio/monitoring/activity-logger.service';
import { cloneDeep } from '@studio/utils/clone';
import { isSVGFile } from '@studio/utils/url';
import { clamp, decimal, rotatePosition } from '@studio/utils/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { PropertiesService } from '../properties-panel';
import { ElementAlign } from '../properties-panel/element-align.enum';
import { ElementDistribution } from '../properties-panel/element-distribution.enum';
import { TimelineElementComponent, TimelineElementService } from '../timeline';
import { EditorEventService, ElementChangeType } from './editor-event';
import { EditorStateService } from './editor-state.service';
import { ElementSelectionBoundingBoxService } from './element-selection-bounding-box.service';
import { ElementSelectionService } from './element-selection.service';

@Injectable()
export class MutatorService implements OnDestroy {
    currentUpdatingElement?: OneOfSelectableElements;
    editMode: TransformMode;
    workspaceFocused = true;
    renderer: IRenderer;
    creativeDocument: ICreativeDataNode;
    preview = false;

    private startAnimationValues = new Map<string, { time: number; duration: number }>();
    private startSelectionAnimationValues: {
        time: number;
        duration: number;
        minDuration: number;
        elements?: OneOfElementDataNodes[];
    };
    private startCreativeAnimationValues: { duration: number; stopTime: number };
    startResize: IBoundingBox = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
    };
    private startResizeStateScaleX = 0;
    private startResizeStateScaleY = 0;
    private startResizeStateX = 0;
    private startResizeStateY = 0;
    private startResizeLayouts: (Pick<IElementViewNode, 'id'> & IBoundingBox)[] = [];
    private startResizeOrigin: IPosition;
    private mirroredXElements = new Set<OneOfElementDataNodes>();
    private mirroredYElements = new Set<OneOfElementDataNodes>();
    private resizeWithoutDiffing = false;
    public richTextBlurSuspensionElements = new Set<HTMLElement>();
    private isEditingText = false;
    private unsubscribe$ = new Subject<void>();

    private get calculatedState(): IState | undefined {
        return this.propertiesService.getCalculatedStateAtCurrentTime();
    }

    constructor(
        private editorStateService: EditorStateService,
        private editorEventService: EditorEventService,
        private activityLoggerService: ActivityLoggerService,
        private propertiesService: PropertiesService,
        private elementSelectionService: ElementSelectionService,
        private timelineElementService: TimelineElementService,
        private elementSelectionBoundingBoxService: ElementSelectionBoundingBoxService,
        private versionsService: VersionsService
    ) {
        editorStateService.renderer$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.creativeDocument = editorStateService.document;
            this.renderer = editorStateService.renderer;
        });

        this.editorEventService.creative.change$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.updateMaskingAndOrder();
        });
    }

    setElementsBlurSuspension(elements: Set<HTMLElement>): void {
        this.richTextBlurSuspensionElements = elements;
        if (this.isEditingText) {
            const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(
                (this.currentUpdatingElement as OneOfTextDataNodes).id
            );
            const richTextRenderer = viewElement?.__richTextRenderer;
            richTextRenderer?.editor_m!.clearBlurSuspensionElements();
            for (const element of elements) {
                richTextRenderer?.editor_m!.addBlurSuspensionElement(element);
            }
        }
    }

    private isElementPropertyOnly(property: string): boolean {
        switch (property) {
            case 'verticalAlignment':
            case 'horizontalAlignment':
            case 'textOverflow':
            case 'maxRows':
            case 'padding':
            case 'width':
            case 'height':
                return true;
        }
        return false;
    }

    setElementValues<Element extends OneOfElementDataNodes>(
        element: Element,
        values: Partial<Element> = {},
        eventType?: ElementChangeType
    ): void {
        const keys = Object.keys(values) as (keyof AllDataNodes)[];
        const updatedValues: Partial<OneOfElementDataNodes> = {};

        const updates = keys.map(key =>
            this.setElementPropertyValue(element, key, values[key], eventType)
        );

        keys.forEach((key, index) => {
            if (updates[index]) {
                updatedValues[key] = values[key];
            }
        });

        if (Object.keys(updatedValues).length) {
            this.valueChange(element, updatedValues, eventType);
        }
    }

    setImageSettings(
        element: IImageElementDataNode,
        values: Partial<IImageSettings> = {},
        eventType?: ElementChangeType
    ): boolean {
        const imageSettings = element.imageSettings;

        const keys = Object.keys(values).filter(
            key => imageSettingsProperties.indexOf(key) > -1
        ) as ImageSettingsKeys[];
        const changes = keys.some(key => values[key] !== imageSettings[key]);

        if (!changes) {
            return false;
        }

        imageSettings.sizeMode =
            typeof values.sizeMode === 'string' ? values.sizeMode : imageSettings.sizeMode;
        imageSettings.highDpi =
            typeof values.highDpi === 'boolean' ? values.highDpi : imageSettings.highDpi;
        imageSettings.x = typeof values.x === 'number' ? values.x : imageSettings.x;
        imageSettings.y = typeof values.y === 'number' ? values.y : imageSettings.y;
        imageSettings.quality = Object.prototype.hasOwnProperty.call(values, 'quality')
            ? values.quality
            : imageSettings.quality;

        this.valueChange(element, imageSettings, eventType);

        return true;
    }

    private setVideoSettings(
        element: IVideoElementDataNode,
        values: Partial<IVideoSettings> = {}
    ): void {
        const videoSettings = element.videoSettings;

        if (values.sizeMode) {
            videoSettings.sizeMode = values.sizeMode;
        }

        this.valueChange(element, element, ElementChangeType.Skip);
    }

    setElementPropertyValue(
        dataNode: OneOfDataNodes,
        property: OneOfElementPropertyKeys,
        // TODO: Fix type of value
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
        eventType?: ElementChangeType
    ): boolean {
        // Never allow null
        if (value === null) {
            value = undefined;
        }

        const valueCopy = cloneDeep(value);
        const state =
            this.propertiesService.inStateView && isStateProperty(property)
                ? this.propertiesService.stateData
                : undefined;

        // Same value: no need to do all this
        if (!isTextNode(dataNode) && eventType !== ElementChangeType.Force) {
            if (
                (!state && dataNode[property] === valueCopy) ||
                (state && state[property] === valueCopy)
            ) {
                if (property !== 'duration' && property !== 'time') {
                    return false;
                }
            }
        }

        // Make sure to not set any invalid properties that messes up the creative later.
        if (!validateElementProperty(property as any, value, !!state)) {
            // TODO FIX ANY
            throw new Error(
                `Could not set "${property}" to invalid value "${value}"${state ? ' on state' : ''}.`
            );
        }

        /**
         * Notifies animation recorder that a property is about to change
         * so that a new state is created if there's not keyframe-state
         * relation on the current time in the timeline
         */
        this.propertiesService.propertyChanging$.next({ property, value });

        if (state) {
            if (valueCopy === undefined) {
                delete state[property];
            } else {
                state[property] = valueCopy;
            }
        } else {
            switch (property) {
                case 'font':
                case 'fontSize':
                case 'textColor':
                case 'uppercase':
                case 'underline':
                case 'strikethrough':
                case 'characterSpacing':
                case 'lineHeight':
                case 'textShadows': {
                    const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(
                        dataNode.id
                    );
                    const richTextRenderer = viewElement?.__richTextRenderer;
                    richTextRenderer?.editor_m!.applyStyleToSelection(property, value);
                    const inEditMode = richTextRenderer?.editor_m?.inEditMode;

                    /**
                     * Only apply text styles only to the base element data
                     * when not in edit mode, i.e., when it's not collapsed and not when partial text is selected.
                     */
                    if (!inEditMode && eventType !== ElementChangeType.Skip) {
                        dataNode[property] = valueCopy;
                    }

                    this.valueChange(dataNode, { [property]: value }, eventType);

                    return true;
                }
                case 'customProperties': {
                    (dataNode as IWidgetElementDataNode).customProperties = valueCopy;
                    const viewElement = this.renderer.getViewElementById<IWidgetViewElement>(
                        dataNode.id
                    );
                    if (viewElement) {
                        (viewElement as IWidgetViewElement).customProperties = valueCopy;
                    }
                    break;
                }
                case 'imageAsset': {
                    // if the new image asset is a svg, fix image settings as well
                    const dElement = dataNode as IImageElementDataNode;
                    const newImageUrl = valueCopy.url;
                    const imageSizeMode = dElement.imageSettings.sizeMode;
                    if (isSVGFile(newImageUrl) && imageSizeMode !== ImageSizeMode.Fit) {
                        dElement.imageSettings.sizeMode = ImageSizeMode.Fit;
                    }
                    dElement.imageAsset = valueCopy;
                    dElement.feed = undefined;
                    break;
                }
                case 'videoAsset': {
                    const videoElement = dataNode as IVideoElementDataNode;
                    videoElement.videoAsset = valueCopy;
                    videoElement.feed = undefined;
                    break;
                }
                case 'feed': {
                    const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(
                        dataNode.id
                    );

                    if (isTextNode(viewElement)) {
                        viewElement.__richTextRenderer?.editor_m!.insertVariableInSelection(value);
                    } else if (isImageNode(dataNode)) {
                        dataNode.imageAsset = undefined;
                        dataNode.feed = valueCopy;
                        if (this.renderer.feedStore) {
                            this.renderer.feedStore.addFeedElement(
                                dataNode.id,
                                valueCopy,
                                dataNode,
                                true
                            );
                        }
                    } else if (isVideoNode(dataNode)) {
                        dataNode.videoAsset = undefined;
                        dataNode.feed = valueCopy;
                        if (this.renderer.feedStore) {
                            this.renderer.feedStore.addFeedElement(
                                dataNode.id,
                                valueCopy,
                                dataNode,
                                true
                            );
                        }
                    }
                    break;
                }
                case 'time':
                case 'duration':
                    if (!isGroupDataNode(dataNode)) {
                        dataNode[property] = valueCopy;
                    }
                    break;
                case 'imageSettings':
                    if (isImageNode(dataNode)) {
                        return this.setImageSettings(dataNode, value, eventType);
                    }
                    break;
                case 'masking':
                    if (valueCopy === undefined) {
                        const viewElement = this.renderer.getViewElementById(dataNode.id);
                        if (viewElement) {
                            viewElement.__svgBackground?.removeMasking_m();
                        }
                    }
                    dataNode[property] = valueCopy;
                    break;
                default:
                    dataNode[property] = valueCopy;
            }

            if (isTextNode(dataNode)) {
                const viewElement = this.renderer.getViewElementById<ITextViewElement>(dataNode.id);

                this.renderer.shouldRerender_m = true;
                this.renderer.setViewElementValues_m(dataNode, this.renderer.time_m);
                this.renderer.shouldRerender_m = false;
                const textElement = viewElement;

                if (textElement?.__richTextRenderer && this.isElementPropertyOnly(property)) {
                    dataNode.content.style[property] = valueCopy;
                    textElement.__richTextRenderer.style[property] = valueCopy;
                    textElement.__richTextRenderer.rerender();
                }
            }
        }

        this.valueChange(dataNode, { [property]: value }, eventType);

        return true;
    }

    private valueChange(
        element: OneOfSelectableElements | OneOfDataNodes,
        values: Partial<OneOfElementDataNodes>,
        eventType?: ElementChangeType
    ): void {
        if (isElementSelection(element)) {
            element.elements.forEach(e => {
                this.editorEventService.elements.change(e, {}, eventType);
            });
        } else if (isElementDataNode(element)) {
            this.editorEventService.elements.change(element, values, eventType);
        }
    }

    removeNodeGroups(groups: GroupDataNode[]): void {
        const selection = groups.reduce(
            (acc: OneOfDataNodes[], current) => [...acc, ...current.nodes],
            []
        );

        groups.forEach(group => group.remove_m());

        this.editorEventService.creative.change('elements', this.creativeDocument.elements);
        this.elementSelectionService.setSelection(...selection);
    }

    moveNodesToGroup(
        group: IGroupElementDataNode,
        nodes: OneOfDataNodes[],
        eventType: ElementChangeType = ElementChangeType.Instant
    ): void {
        const groupIsChildOfNodes = nodes.some(
            node => isGroupDataNode(node) && node.findNodeById_m(group.id, true)
        );

        if (groupIsChildOfNodes) {
            return;
        }

        let nodesWasMoved = false;

        nodes.forEach(node => {
            if (group.id === node.id) {
                return;
            }

            const parent = node.__parentNode || node.__rootNode;
            parent?.removeNodeById_m(node.id);
            group.addNode_m(node);
            nodesWasMoved = true;
        });

        if (nodesWasMoved) {
            this.editorEventService.creative.change(
                'elements',
                this.creativeDocument.elements,
                eventType
            );
        }
    }

    /**
     * Method for resolving all character styles. Styles are just stored locally in rich text. In order to
     * propogate changes to versions and element.characterStyles, we have to call `resolveCharacterStyles`.
     */
    resolveAllCharacterStyles(): void {
        const notHiddenElements = this.renderer.creativeDocument.elements.filter(
            element => !isHidden(element)
        );

        for (const element of notHiddenElements) {
            if (isTextNode(element)) {
                this.renderer.updateCharacterStyles_m(element);
            }
        }
    }

    removeSelection(selection: OneOfSelectableElements, force = false, emitRemoval = true): void {
        if (!this.workspaceFocused && !force) {
            return;
        }
        const propertiesToRemove: string[] = [];

        const removeVersionProperties = (versionPropertyIds: string[], elementId: string): void => {
            const propertiesToRemoveForElement = versionPropertyIds.filter(versionPropertyId =>
                this.canPropertyBeRemoved(versionPropertyId)
            );

            if (!propertiesToRemoveForElement.length) {
                return;
            }

            const elements = this.editorStateService.elements.filter(el => el.id !== elementId);
            this.editorStateService.updateElements(elements);

            propertiesToRemove.push(...propertiesToRemoveForElement);
        };

        const removeElement = (node: OneOfDataNodes): void => {
            const globalElement = this.removeElement(node);
            if (!globalElement) {
                return;
            }
            // clear all version properties of removed element
            const versionPropertyIds = globalElement.properties
                .filter(p => !!p.versionPropertyId)
                .reduce<string[]>((cur, prev) => {
                    cur.push(prev.versionPropertyId!);
                    return cur;
                }, []);

            removeVersionProperties(versionPropertyIds, node.id);
        };

        if (isElementSelection(selection)) {
            for (const element of toFlatNodeList(selection.nodes)) {
                removeElement(element);
            }

            this.elementSelectionService.clearSelection();
        } else {
            removeElement(selection);
        }

        if (emitRemoval) {
            // Emit that elements have been removed
            this.editorEventService.creative.change('elements', undefined, ElementChangeType.Burst);
        }
        this.versionsService.removeVersionPropertiesByIds(propertiesToRemove);
    }

    private removeElement(element: OneOfDataNodes): IElement | undefined {
        const renderer = this.renderer;
        renderer.destroyElement_m(element);

        const globalElement = this.editorStateService.getElementById(element.id);
        const elements = this.editorStateService.elements.filter(({ id }) => id !== globalElement.id);
        this.editorStateService.updateElements(elements);

        return globalElement;
    }

    setElementIndex(
        selection: OneOfSelectableElements | IGroupElementDataNode,
        newIndex: number
    ): boolean {
        const renderer = this.renderer;
        const nodes = toFlatNodeList(renderer.creativeDocument);
        const selectedNodes = isElementSelection(selection) ? selection.nodes : [selection];
        if (!nodes.length) {
            selectedNodes.forEach(node => {
                renderer.creativeDocument.addNode_m(node);
            });
            return true;
        }

        /** If user tries to move node out from a group that contains first timeline node index */
        if (newIndex === -1 && selectedNodes.some(node => node.__parentNode)) {
            selectedNodes.forEach((node, i) => {
                (node.__parentNode || node.__rootNode)!.removeNodeById_m(node.id);
                renderer.creativeDocument.addNode_m(node, 0 + i);
            });
            this.renderer.updateElementOrder_m();

            return true;
        }

        const currentIndex = Math.min(...selectedNodes.map(el => nodes.indexOf(el)));
        newIndex = clamp(newIndex, 0, nodes.length - 1);

        if (currentIndex === newIndex) {
            return false;
        }

        /**
         * If nodeAtIndex is a group, that means that we're moving the node downwards
         * on top of a group node in TL, so that means we're moving it into the group.
         * */

        const nodeAtIndex = nodes[newIndex];
        const indexWithSelectionOffset = newIndex + selectedNodes.length - 1;
        const nodeAtOffset = nodes[indexWithSelectionOffset];

        const tlNodes = this.timelineElementService.nodes;
        const isCollapsedNodeAtOffset = tlNodes.some(
            tl => tl.node?.id === nodeAtOffset.id && tl.collapsed
        );

        let targetNodeTree: INodeWithChildren =
            isGroupDataNode(nodeAtOffset) && indexWithSelectionOffset < currentIndex
                ? nodeAtOffset
                : nodeAtOffset.__parentNode || renderer.creativeDocument;

        if (isCollapsedNodeAtOffset) {
            // If node at offset is collapsed, use first expanded ancestor as target tree instead
            targetNodeTree = findExpandedAncestor(nodeAtOffset, tlNodes) || renderer.creativeDocument;
        }

        let groupIndexOffset = 0;

        /**
         * Make sure we're not trying to move a group into itself
         * which would occur when moving group with subgroups around
         * around in the timeline
         */
        const selectionHasNodeAtIndex = selectedNodes.some(node => {
            if (isGroupDataNode(node)) {
                return node.findNodeById_m(nodeAtIndex.id, true);
            } else if (isGroupDataNode(nodeAtIndex)) {
                return node.id === nodeAtIndex.id;
            }
        });

        if (selectionHasNodeAtIndex) {
            return false;
        }

        /**
         * Recalculate the index based on nodes in groups
         */
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if (isGroupDataNode(targetNodeTree)) {
                if (node.__parentNode !== targetNodeTree) {
                    groupIndexOffset++;
                }
            } else if (targetNodeTree.kind === CreativeKind.Creative) {
                if (isGroupDataNode(node.__parentNode)) {
                    groupIndexOffset++;
                }
            }

            if (i === newIndex) {
                break;
            }
        }

        // Get correct index if moving node from group to the root tree
        if (isGroupDataNode(nodeAtIndex) && targetNodeTree.id !== nodeAtIndex.id) {
            newIndex = targetNodeTree.nodes.findIndex(node => node.id === nodeAtIndex.id) + 1;
        } else if (!isGroupDataNode(nodeAtIndex)) {
            newIndex = clamp(Math.abs(groupIndexOffset - newIndex), 0, nodes.length);
        }

        selectedNodes.forEach((node, i) => {
            (node.__parentNode || node.__rootNode)!.removeNodeById_m(node.id);
            targetNodeTree.addNode_m(node, newIndex + i);
        });

        this.renderer.updateElementOrder_m();

        return true;
    }

    setIndexFromTimelineIndex(
        selection: OneOfSelectableElements | IGroupElementDataNode,
        elementAtIndex: TimelineElementComponent | undefined,
        direction: 'up' | 'down',
        timelineElements: TimelineElementComponent[]
    ): boolean {
        const nodeAtIndex = elementAtIndex?.node;
        const selectedNodes = isElementSelection(selection)
            ? selection.nodesAsSortedArray()
            : [selection];

        const selectedNodesCopy = [...selectedNodes].reverse();

        // move to bottom
        if (!nodeAtIndex) {
            this.moveNodesToIndex(selectedNodesCopy, this.creativeDocument, 0);
            return true;
        }

        // multiple selections
        if (selectedNodes.includes(nodeAtIndex)) {
            return false;
        }

        const timelineElementsCopy = [...timelineElements].reverse();

        const firstSelected = timelineElementsCopy.find(
            element => element.node.id === selectedNodes[0].id
        );

        // do nothing when index does not change
        if (nodeAtIndex.id === firstSelected!.node.id) {
            return false;
        }

        // prevent moving group into itself
        if (this.selectionHasNodeAtIndex(selectedNodes, nodeAtIndex)) {
            return false;
        }

        const isCollapsedNodeAtIndex =
            isGroupDataNode(nodeAtIndex) &&
            !!nodeAtIndex.nodes.length &&
            this.timelineElementService.isCollapsed(nodeAtIndex.nodes[0]);
        let targetNodeTree: IGroupElementDataNode | ICreativeDataNode =
            nodeAtIndex.__parentNode ?? nodeAtIndex.__rootNode!;

        if (isGroupDataNode(nodeAtIndex) && direction === 'down' && !isCollapsedNodeAtIndex) {
            targetNodeTree = nodeAtIndex;
        }

        let index = this.getNewIndexForMovedElement(
            nodeAtIndex,
            targetNodeTree,
            direction,
            isCollapsedNodeAtIndex
        );
        const targetTreeContainsSelected = targetNodeTree.nodes.some(targetTreeNode =>
            selectedNodes.find(selectedNode => selectedNode.id === targetTreeNode.id)
        );

        if (direction === 'up' && selectedNodes.length > 1 && targetTreeContainsSelected) {
            let offset = -1;
            selectedNodes.forEach(node => {
                if (targetNodeTree.nodes.find(targetTreeNode => targetTreeNode.id === node.id)) {
                    offset++;
                }
            });
            index -= offset;
        }

        this.moveNodesToIndex(selectedNodesCopy, targetNodeTree, index);

        this.renderer.updateElementOrder_m();

        return true;
    }

    moveNodesToIndex(
        nodes: OneOfDataNodes[],
        nodeTree: IGroupElementDataNode | ICreativeDataNode,
        index: number,
        emitChange = true
    ): void {
        this.moveNodes(nodes, nodeTree, index, emitChange);
    }

    private moveNodes(
        nodes: OneOfDataNodes[],
        nodeTree: IGroupElementDataNode | ICreativeDataNode,
        index: number,
        emitChange = true
    ): void {
        this.creativeDocument.preserveEmptyChildren(true);

        nodes.forEach(node => {
            (node.__parentNode || node.__rootNode)!.removeNodeById_m(node.id);
        });
        nodes.forEach(node => {
            nodeTree.addNode_m(node, index);
        });

        if (emitChange) {
            this.editorEventService.creative.change('nodes', nodes, ElementChangeType.Skip);
        }

        this.creativeDocument.preserveEmptyChildren(false);
    }

    private getNewIndexForMovedElement(
        nodeAtIndex: OneOfDataNodes,
        targetNodeTree: IGroupElementDataNode | ICreativeDataNode,
        direction: 'up' | 'down',
        isCollapsedNodeAtIndex: boolean
    ): number {
        let isNodeAtIndexLastElementInGroup = false;
        if (nodeAtIndex.__parentNode) {
            const parent = nodeAtIndex.__parentNode;
            isNodeAtIndexLastElementInGroup = parent.nodes[0] === nodeAtIndex;
        }
        let index = targetNodeTree.nodes.findIndex(node => node.id === nodeAtIndex.id);
        if (isGroupDataNode(nodeAtIndex) && direction === 'down' && !isCollapsedNodeAtIndex) {
            index = nodeAtIndex.nodes.length;
        } else if (isGroupDataNode(nodeAtIndex) && direction === 'up' && !isCollapsedNodeAtIndex) {
            index++;
        } else if (isNodeAtIndexLastElementInGroup && direction === 'up') {
            index++;
        }
        return index;
    }

    private selectionHasNodeAtIndex(
        selectedNodes: OneOfDataNodes[],
        nodeAtIndex: OneOfDataNodes
    ): boolean {
        return selectedNodes.some(node => {
            let containsNodeAtIndex = false;
            if (isGroupDataNode(node)) {
                containsNodeAtIndex = !!node.findNodeById_m(nodeAtIndex.id, true);
            }
            let containedByNodyAtIndex = false;
            if (isGroupDataNode(nodeAtIndex)) {
                containedByNodyAtIndex = node.id === nodeAtIndex.id;
            }
            return containsNodeAtIndex || containedByNodyAtIndex;
        });
    }

    updateMaskingAndOrder(): void {
        for (const node of this.creativeDocument.nodeIterator_m()) {
            const parent = node.__parentNode || this.creativeDocument;
            const nodesInParent = parent.nodes;
            const nodeIndex = nodesInParent.findIndex(({ id }) => id === node.id);
            const closestMaskNodeIndex = getClosestMaskIndex(nodeIndex, nodesInParent);
            const closestGroupIndex = getClosestGroupIndex(nodeIndex, nodesInParent);

            if (nodeIndex === closestMaskNodeIndex) {
                continue;
            }

            let mask: Masking | undefined;
            const canBeMasked = nodeIndex < closestMaskNodeIndex;
            const blockedByGroup = closestGroupIndex > -1 && closestMaskNodeIndex > closestGroupIndex;

            if (canBeMasked && !blockedByGroup) {
                if (isMaskNode(nodesInParent[nodeIndex])) {
                    break;
                }

                if (!isMaskingSupported(node)) {
                    this.moveNodes([node], parent, closestMaskNodeIndex);
                    continue;
                }

                mask = {
                    isMask: false,
                    elementId: nodesInParent[closestMaskNodeIndex].id
                };
            }

            this.setElementPropertyValue(node, 'masking', mask, ElementChangeType.Skip);
        }
    }

    getElementViewIndex(element: OneOfElementDataNodes): { index: number; length: number } {
        const viewTreeArray = this.renderer.creativeDocument.elements
            .map(el => this.renderer.getViewElementById(el.id))
            .filter(element => element);
        return {
            index: viewTreeArray.findIndex(el => el?.id === element.id),
            length: viewTreeArray.length - 1
        };
    }

    private canPropertyBeRemoved(versionPropertyId: string): boolean {
        for (const design of this.editorStateService.designs) {
            const isInOtherDesigns = design.id !== this.editorStateService.designFork.id;
            for (const element of design.elements) {
                for (const property of element.properties) {
                    if (property.versionPropertyId === versionPropertyId) {
                        // Don't do anything if element is on other designs
                        if (isInOtherDesigns) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    alignSelection(elementAlign: ElementAlign, selection: ElementSelection): void {
        if (!selection.nodes.length) {
            return;
        }
        if (selection.nodes.length === 1) {
            this.alignNode(elementAlign, selection.nodes[0]!);
        } else {
            const alignments = this.getElementsAlignmentWithinSelection(selection);
            const alreadyAligned = alignments.indexOf(elementAlign) >= 0;
            const relativeElement = this.getRelativeElementForAlignment(elementAlign, selection);
            selection.nodes.forEach(node =>
                this.alignNode(elementAlign, node, alreadyAligned ? undefined : relativeElement)
            );
        }
    }

    alignNode(elementAlign: ElementAlign, node: OneOfDataNodes, relativeTo?: IBoundingBox): void {
        if (
            node.locked ||
            (relativeTo &&
                node === relativeTo &&
                elementAlign !== ElementAlign.Middle &&
                elementAlign !== ElementAlign.Center)
        ) {
            return;
        }

        const state = this.propertiesService.inStateView
            ? this.propertiesService.getCalculatedStateAtCurrentTime()
            : undefined;
        if (isGroupDataNode(node)) {
            this.alignGroup(node, elementAlign, relativeTo);
        } else if (state) {
            this.alignElementWithState(node, elementAlign, state);
        } else {
            this.alignElement(node, elementAlign, relativeTo);
        }
    }

    private alignGroup(
        node: IGroupElementDataNode,
        elementAlign: ElementAlign,
        relativeTo?: IBoundingBox
    ): void {
        const box = getBoundingBoxOfGroup(node);
        const canvasSize = this.renderer.canvasSize_m;

        node.elements.forEach(element => {
            let x: number | undefined;
            let y: number | undefined;

            switch (elementAlign) {
                case ElementAlign.Left:
                    x = relativeTo ? element.x - (box.x - relativeTo.x) : element.x - box.x;
                    break;
                case ElementAlign.Center:
                    x = relativeTo
                        ? element.x + (relativeTo.x - box.width / 2) - box.x
                        : element.x + canvasSize.width / 2 - box.width / 2 - box.x;
                    break;
                case ElementAlign.Right:
                    x = relativeTo
                        ? element.x + (relativeTo.x + relativeTo.width - box.x - box.width)
                        : element.x + canvasSize.width - box.width - box.x;
                    break;
                case ElementAlign.Top:
                    y = relativeTo ? element.y - (box.y - relativeTo.y) : element.y - box.y;
                    break;
                case ElementAlign.Middle:
                    y = relativeTo
                        ? element.y + (relativeTo.y - box.height / 2) - box.y
                        : element.y + canvasSize.height / 2 - box.height / 2 - box.y;
                    break;
                case ElementAlign.Bottom:
                    y = relativeTo
                        ? element.y + (relativeTo.y + relativeTo.height - box.y - box.height)
                        : element.y + canvasSize.height - box.height - box.y;
                    break;
            }
            if (x === undefined) {
                x = element.x;
            }
            if (y === undefined) {
                y = element.y;
            }
            this.setElementPosition({ x, y }, element);
        });
    }

    private alignElement(
        node: OneOfElementDataNodes,
        elementAlign: ElementAlign,
        relativeTo?: IBoundingBox
    ): void {
        const canvasSize = this.renderer.canvasSize_m;
        const { scaleX, scaleY, originX, originY } = node;
        const box = getBoundingBoxOfElementWithState(node, { scaleX, scaleY, originX, originY }, true);

        let x: number | undefined;
        let y: number | undefined;

        switch (elementAlign) {
            case ElementAlign.Left:
                x = relativeTo ? relativeTo.x : 0;
                break;
            case ElementAlign.Center:
                x = relativeTo ? relativeTo.x - box.width / 2 : canvasSize.width / 2 - box.width / 2;
                break;
            case ElementAlign.Right:
                x = relativeTo
                    ? relativeTo.x + relativeTo.width - box.width
                    : canvasSize.width - box.width;
                break;
            case ElementAlign.Top:
                y = relativeTo ? relativeTo.y : 0;
                break;
            case ElementAlign.Middle:
                y = relativeTo ? relativeTo.y - box.height / 2 : canvasSize.height / 2 - box.height / 2;
                break;
            case ElementAlign.Bottom:
                y = relativeTo
                    ? relativeTo.y + relativeTo.height - box.height
                    : canvasSize.height - box.height;
                break;
        }
        if (x === undefined) {
            x = node.x;
        }
        if (y === undefined) {
            y = node.y;
        }
        this.setElementPosition({ x, y }, node);
    }

    private alignElementWithState(
        node: OneOfElementDataNodes,
        elementAlign: ElementAlign,
        state: IState
    ): void {
        const canvasSize = this.renderer.canvasSize_m;
        const { scaleX, scaleY, originX, originY } = state;
        const box = getBoundingBoxOfElementWithState(node, { scaleX, scaleY, originX, originY }, true);

        let x: number | undefined;
        let y: number | undefined;

        switch (elementAlign) {
            case ElementAlign.Left:
                x = -box.x;
                break;
            case ElementAlign.Center:
                x = canvasSize.width / 2 - box.width / 2 - box.x;
                break;
            case ElementAlign.Right:
                x = canvasSize.width - box.width - box.x;
                break;
            case ElementAlign.Top:
                y = -box.y;
                break;
            case ElementAlign.Middle:
                y = canvasSize.height / 2 - box.height / 2 - box.y;
                break;
            case ElementAlign.Bottom:
                y = canvasSize.height - box.height - box.y;
                break;
        }

        if (x === undefined) {
            x = typeof state.x === 'number' ? state.x : 0;
        }
        if (y === undefined) {
            y = typeof state.y === 'number' ? state.y : 0;
        }
        this.setElementPosition({ x, y }, node);
    }

    getRelativeElementForAlignment(
        elementAlign: ElementAlign,
        selection: ElementSelection
    ): OneOfElementDataNodes {
        switch (elementAlign) {
            case ElementAlign.Left:
                return [...selection.elements].reduce((prev, next) => (prev.x < next.x ? prev : next));
            case ElementAlign.Top:
                return [...selection.elements].reduce((prev, next) => (prev.y < next.y ? prev : next));
            case ElementAlign.Right:
                return [...selection.elements].reduce((prev, next) => (prev.x > next.x ? prev : next));
            case ElementAlign.Bottom:
                return [...selection.elements].reduce((prev, next) => (prev.y > next.y ? prev : next));
            case ElementAlign.Middle: {
                const avgY =
                    [...selection.nodes]
                        .map(getSizeAndPositionOfNode)
                        .map(pos => pos.y + pos.height / 2)
                        .reduce((prev, next) => prev + next) / selection.nodes.length;
                return this.fakeElementAtPosition({ x: 0, y: avgY });
            }
            case ElementAlign.Center: {
                const avgX =
                    [...selection.nodes]
                        .map(getSizeAndPositionOfNode)
                        .map(pos => pos.x + pos.width / 2)
                        .reduce((prev, next) => prev + next) / selection.nodes.length;
                return this.fakeElementAtPosition({ x: avgX, y: 0 });
            }
        }
    }

    getElementsAlignmentWithinSelection(selection: ElementSelection | undefined): ElementAlign[] {
        if (!selection?.nodes.length) {
            return [];
        }

        let sameX = true;
        let sameY = true;
        let sameX2 = true; // x + width
        let sameY2 = true; // y + width
        let sameXBaseline = true; // x + width / 2
        let sameYBaseline = true; // y + width / 2
        const result: ElementAlign[] = [];
        const canvasSize = this.renderer.canvasSize_m;
        const tolerance = 0.5;

        if (selection.nodes.length === 1) {
            let box: IBoundingBox;
            if (isGroupDataNode(selection.nodes[0])) {
                box = getBoundingBoxOfGroup(selection.nodes[0]);
            } else {
                const state = this.propertiesService.inStateView
                    ? this.propertiesService.stateData
                    : undefined;
                box = getBoundingBoxOfElementWithState(selection.element!, state, true);
            }

            sameX = Math.abs(box.x) <= tolerance;
            sameY = Math.abs(box.y) <= tolerance;
            sameX2 = Math.abs(box.x + box.width - canvasSize.width) <= tolerance;
            sameY2 = Math.abs(box.y + box.height - canvasSize.height) <= tolerance;
            sameXBaseline = Math.abs(box.x + box.width / 2 - canvasSize.width / 2) <= tolerance;
            sameYBaseline = Math.abs(box.y + box.height / 2 - canvasSize.height / 2) <= tolerance;
        } else {
            const nodes = [...selection.nodes].map(node => {
                if (isGroupDataNode(node)) {
                    const box = getBoundingBoxOfGroup(node);
                    return { ...node, x: box.x, y: box.y, width: box.width, height: box.height };
                } else {
                    return node;
                }
            });

            for (let i = 1; i < nodes.length; i++) {
                const prev = nodes[i - 1];
                const next = nodes[i];
                if (prev.x !== next.x) {
                    sameX = false;
                }
                if (prev.y !== next.y) {
                    sameY = false;
                }
                if (prev.x + prev.width !== next.x + next.width) {
                    sameX2 = false;
                }
                if (prev.y + prev.height !== next.y + next.height) {
                    sameY2 = false;
                }
                if (Math.trunc(prev.x + prev.width / 2) !== Math.trunc(next.x + next.width / 2)) {
                    sameXBaseline = false;
                }
                if (Math.trunc(prev.y + prev.height / 2) !== Math.trunc(next.y + next.height / 2)) {
                    sameYBaseline = false;
                }
            }
        }
        if (sameX) {
            result.push(ElementAlign.Left);
        }
        if (sameY) {
            result.push(ElementAlign.Top);
        }
        if (sameX2) {
            result.push(ElementAlign.Right);
        }
        if (sameY2) {
            result.push(ElementAlign.Bottom);
        }
        if (sameXBaseline) {
            result.push(ElementAlign.Center);
        }
        if (sameYBaseline) {
            result.push(ElementAlign.Middle);
        }
        return result;
    }

    getElementsDistributionWithinSelection(): ElementDistribution[] {
        const selection = this.elementSelectionService.currentSelection;
        if (!selection?.nodes.length) {
            return [];
        }

        const elementsSortedX = [...selection.nodes]
            .map(node => {
                if (isGroupDataNode(node)) {
                    const box = getBoundingBoxOfGroup(node);
                    return { ...node, x: box.x, y: box.y, width: box.width, height: box.height };
                } else {
                    return node;
                }
            })
            .sort((a, b) => (a.x > b.x ? 1 : -1));
        const elementsSortedY = [...selection.nodes]
            .map(node => {
                if (isGroupDataNode(node)) {
                    const box = getBoundingBoxOfGroup(node);
                    return { ...node, x: box.x, y: box.y, width: box.width, height: box.height };
                } else {
                    return node;
                }
            })
            .sort((a, b) => (a.y > b.y ? 1 : -1));
        const result: ElementDistribution[] = [];
        let sameDistanceX = true;
        let sameDistanceY = true;

        if (selection.nodes.length === 1) {
            const alignments = this.getElementsAlignmentWithinSelection(selection);
            if (alignments.indexOf(ElementAlign.Center) >= 0) {
                result.push(ElementDistribution.Horizontal);
            }
            if (alignments.indexOf(ElementAlign.Middle) >= 0) {
                result.push(ElementDistribution.Vertical);
            }

            return result;
        }

        if (selection.nodes.length === 2) {
            return [ElementDistribution.Horizontal, ElementDistribution.Vertical];
        }

        let baseX: number | undefined;
        let baseY: number | undefined;
        for (let i = 1; i < selection.nodes.length; i++) {
            const distanceX = Math.round(
                elementsSortedX[i].x - (elementsSortedX[i - 1].x + elementsSortedX[i - 1].width)
            );
            const distanceY = Math.round(
                elementsSortedY[i].y - (elementsSortedY[i - 1].y + elementsSortedY[i - 1].height)
            );
            if (!baseX) {
                baseX = distanceX;
                baseY = distanceY;
                continue;
            }

            if (baseX !== distanceX) {
                sameDistanceX = false;
            }

            if (baseY !== distanceY) {
                sameDistanceY = false;
            }

            if (!sameDistanceX && !sameDistanceY) {
                break;
            }
        }

        if (sameDistanceX) {
            result.push(ElementDistribution.Horizontal);
        }
        if (sameDistanceY) {
            result.push(ElementDistribution.Vertical);
        }

        return result;
    }

    distributeSelection(distribution: ElementDistribution): void {
        const selection = this.elementSelectionService.currentSelection;

        if (!selection) {
            throw new Error('No selection found when distributing selection');
        }

        if (selection.nodes.length === 1) {
            this.alignSelection(
                distribution === ElementDistribution.Horizontal
                    ? ElementAlign.Center
                    : ElementAlign.Middle,
                selection
            );
            return;
        }

        const currentDistributions = this.getElementsDistributionWithinSelection();
        const withinCanvas =
            currentDistributions.indexOf(distribution) >= 0 || selection.nodes.length === 2;
        distribution === ElementDistribution.Horizontal
            ? this.distributeSelectionHorizontally(withinCanvas)
            : this.distributeSelectionVertically(withinCanvas);
    }

    private distributeSelectionHorizontally(withinCanvas = false): void {
        const selection = this.elementSelectionService.currentSelection;
        const selectionBoundingBox =
            this.elementSelectionBoundingBoxService.boundingBoxes?.lockedAndHiddenExcluded;

        if (!selection || !selectionBoundingBox) {
            throw new Error(
                'No selection or selection bounding box supplied when distributing selection horizontally'
            );
        }

        const nodes = [...selection.nodes]
            .map(node => {
                if (isGroupDataNode(node)) {
                    const box = getBoundingBoxOfGroup(node);
                    return {
                        ...node,
                        x: box.x,
                        y: box.y,
                        width: box.width,
                        height: box.height,
                        elements: node.elements
                    };
                } else {
                    return node;
                }
            })
            .sort((a, b) => (a.x > b.x ? 1 : -1));
        const availableWidth = withinCanvas
            ? this.renderer.canvasSize_m.width
            : selectionBoundingBox.width - nodes[0].width - nodes[nodes.length - 1].width;

        const startIndex = withinCanvas ? 0 : 1;
        const endIndex = withinCanvas ? nodes.length : nodes.length - 1;
        const spacesCount = withinCanvas ? nodes.length + 1 : nodes.length - 1;

        const elementsWidth = nodes
            .slice(startIndex, endIndex)
            .map(el => el.width)
            .reduce((prev, next) => prev + next);
        const margin = (availableWidth - elementsWidth) / spacesCount;

        if (withinCanvas) {
            const maxX = this.renderer.canvasSize_m.width - nodes[nodes.length - 1].width;
            const firstX = margin > 0 ? margin : 0;
            const firstY = nodes[0].y;
            const lastX = margin > 0 ? maxX - margin : maxX;
            const lastY = nodes[nodes.length - 1].y;

            const firstNode = nodes[0];
            if (isGroupDataNode(firstNode)) {
                firstNode.elements.forEach(element => {
                    this.setElementPosition(
                        { x: element.x - (firstNode.x - firstX), y: element.y },
                        element
                    );
                });
            } else {
                this.setElementPosition({ x: firstX, y: firstY }, firstNode);
            }

            const lastNode = nodes[nodes.length - 1];
            if (isGroupDataNode(lastNode)) {
                lastNode.elements.forEach(element => {
                    this.setElementPosition(
                        { x: element.x - (lastNode.x - lastX), y: element.y },
                        element
                    );
                });
            } else {
                this.setElementPosition({ x: lastX, y: lastY }, lastNode);
            }
        }

        for (let i = 1; i < nodes.length - 1; i++) {
            const node = nodes[i];
            const prev = nodes[i - 1];
            const y = node.y;
            const x = prev.x + prev.width + margin;

            if (isGroupDataNode(node)) {
                node.elements.forEach(element => {
                    this.setElementPosition({ x: element.x - (node.x - x), y: element.y }, element);
                });
            } else {
                this.setElementPosition({ x, y }, node);
            }
        }

        if (nodes[0].x > nodes[1].x) {
            // Not enough space to distribute
            const widestElement = [...selection.nodes]
                .map(node => {
                    if (isGroupDataNode(node)) {
                        const box = getBoundingBoxOfGroup(node);
                        return {
                            ...node,
                            x: box.x,
                            y: box.y,
                            width: box.width,
                            height: box.height,
                            elements: node.elements
                        };
                    } else {
                        return node;
                    }
                })
                .reduce((prev, next) => (prev.width > next.width ? prev : next));
            const startPoint = selectionBoundingBox.x;
            const availableSpace =
                (selectionBoundingBox.width - widestElement.width) / (nodes.length - 2);

            for (let i = 1; i < nodes.length - 1; i++) {
                const node = nodes[i];
                if (isGroupDataNode(node)) {
                    node.elements.forEach(element => {
                        this.setElementPosition(
                            {
                                x: element.x + nodes[0].x - startPoint + availableSpace * (i - 1),
                                y: element.y
                            },
                            element
                        );
                    });
                } else {
                    this.setElementPosition(
                        { x: startPoint + availableSpace * (i - 1), y: nodes[i].y },
                        node
                    );
                }
            }
        }
    }

    private distributeSelectionVertically(withinCanvas = false): void {
        const selection = this.elementSelectionService.currentSelection;
        const selectionBoundingBox =
            this.elementSelectionBoundingBoxService.boundingBoxes?.lockedAndHiddenExcluded;

        if (!selection || !selectionBoundingBox) {
            throw new Error(
                'No selection or selection bounding box supplied when distributing selection horizontally'
            );
        }

        const nodes = [...selection.nodes]
            .map(node => {
                if (isGroupDataNode(node)) {
                    const box = getBoundingBoxOfGroup(node);
                    return {
                        ...node,
                        x: box.x,
                        y: box.y,
                        width: box.width,
                        height: box.height,
                        elements: node.elements
                    };
                } else {
                    return node;
                }
            })
            .sort((a, b) => (a.y > b.y ? 1 : -1));
        const availableHeight = withinCanvas
            ? this.renderer.canvasSize_m.height
            : selectionBoundingBox.height - nodes[0].height - nodes[nodes.length - 1].height;

        const startIndex = withinCanvas ? 0 : 1;
        const endIndex = withinCanvas ? nodes.length : nodes.length - 1;
        const spacesCount = withinCanvas ? nodes.length + 1 : nodes.length - 1;

        const elementsHeight = nodes
            .slice(startIndex, endIndex)
            .map(el => el.height)
            .reduce((prev, next) => prev + next);
        const margin = (availableHeight - elementsHeight) / spacesCount;

        if (withinCanvas) {
            const maxY = this.renderer.canvasSize_m.height - nodes[nodes.length - 1].height;
            const firstX = nodes[0].x;
            const firstY = margin > 0 ? margin : 0;
            const lastX = nodes[nodes.length - 1].x;
            const lastY = margin > 0 ? maxY - margin : maxY;

            const firstNode = nodes[0];
            if (isGroupDataNode(firstNode)) {
                firstNode.elements.forEach(element => {
                    this.setElementPosition(
                        { x: element.x, y: element.y - (firstNode.y - firstY) },
                        element
                    );
                });
            } else {
                this.setElementPosition({ x: firstX, y: firstY }, firstNode);
            }

            const lastNode = nodes[nodes.length - 1];
            if (isGroupDataNode(lastNode)) {
                lastNode.elements.forEach(element => {
                    this.setElementPosition(
                        { x: element.x, y: element.y - (lastNode.y - lastY) },
                        element
                    );
                });
            } else {
                this.setElementPosition({ x: lastX, y: lastY }, lastNode);
            }
        }

        for (let i = 1; i < nodes.length - 1; i++) {
            const node = nodes[i];
            const prev = nodes[i - 1];
            const x = node.x;
            const y = prev.y + prev.height + margin;
            if (isGroupDataNode(node)) {
                node.elements.forEach(element => {
                    this.setElementPosition({ x: element.x, y: element.y - (node.y - y) }, element);
                });
            } else {
                this.setElementPosition({ x, y }, node);
            }
        }
    }

    fakeElementAtPosition(position: IPosition): OneOfElementDataNodes {
        const element = {
            ...createBaseElement(ElementKind.Rectangle, {}),
            time: 0,
            duration: 0,
            width: 0,
            height: 0,
            x: position.x,
            y: position.y,
            id: ''
        } as OneOfElementDataNodes;

        return {
            ...element
        };
    }

    selectionBoundingBox(element: OneOfSelectableElements): IBoundingBox {
        if (isElementSelection(element)) {
            const selectionBoundingBox =
                this.elementSelectionBoundingBoxService.boundingBoxes?.lockedExcluded;
            if (!selectionBoundingBox) {
                throw new Error('No selection bounding box found');
            }
            return selectionBoundingBox;
        } else {
            return getBoundingBoxOfElementWithState(element, this.calculatedState, true);
        }
    }

    moveStart(element: OneOfSelectableElements): void {
        this.editStart(element);
    }

    move(x: number, y: number): void {
        const selection = this.currentUpdatingElement;

        if (!selection) {
            return;
        }

        const { inStateView, stateData, selectedElement } = this.propertiesService;
        if (inStateView && selectedElement && stateData) {
            const { x: stateX, y: stateY } = stateData;

            if (isFormulaValue(stateX) || isFormulaValue(stateY)) {
                return;
            }
        }

        if (isElementSelection(selection)) {
            const state = this.calculatedState;
            const notLockedDirectlySelectedNodes = selection.nodes.filter(node => !isLocked(node));
            const elementsToMove = toFlatElementNodeList(notLockedDirectlySelectedNodes);
            const boundingBox = calculateBoundingBox([...elementsToMove], state);
            const diffX = x - boundingBox.x;
            const diffY = y - boundingBox.y;
            for (const e of elementsToMove) {
                const currentPosition = state || e;
                const position = {
                    x: Math.round(Number(currentPosition.x || 0) + diffX),
                    y: Math.round(Number(currentPosition.y || 0) + diffY)
                };
                this.setElementPosition(position, e, ElementChangeType.Skip);
            }
        } else {
            this.setElementPosition(
                { x: Math.round(x), y: Math.round(y) },
                selection,
                ElementChangeType.Skip
            );
        }
    }

    moveEnd(): void {
        this.valueChange(this.currentUpdatingElement!, {}, ElementChangeType.Force);
        this.editEnd();
    }

    nudgeMove(elements: OneOfElementDataNodes[], x: number, y: number): void {
        const { inStateView, stateData, selectedElement } = this.propertiesService;
        if (inStateView && selectedElement && stateData) {
            const { x: stateX, y: stateY } = stateData;

            if (isFormulaValue(stateX) || isFormulaValue(stateY)) {
                return;
            }
        }

        const state = this.calculatedState;
        const elementsToMove = toFlatElementNodeList(elements).filter(node => !isLocked(node));

        for (const element of elementsToMove) {
            const currentPosition = state || element;
            const position = {
                x: Math.round(Number(currentPosition.x || 0) + x),
                y: Math.round(Number(currentPosition.y || 0) + y)
            };
            this.setElementPosition(position, element, ElementChangeType.Burst);
        }
    }

    moveAnimationStart(element: OneOfSelectableElements): void {
        this.editStart(element);

        if (isElementSelection(element)) {
            const selection = element.asSortedArray();

            this.startSelectionAnimationValues = {
                time: element.time,
                duration: element.duration,
                minDuration: Infinity,
                elements: selection
            };

            for (const el of selection) {
                this.startAnimationValues.set(el.id, { time: el.time, duration: el.duration });
                if (el.duration < this.startSelectionAnimationValues.minDuration) {
                    this.startSelectionAnimationValues.minDuration = el.duration;
                }
            }
        } else {
            this.startAnimationValues.set(element.id, {
                time: element.time,
                duration: element.duration
            });
        }
    }

    moveAnimation(time: number): void {
        const element = this.currentUpdatingElement;
        if (element) {
            if (isElementSelection(element)) {
                const timeChange =
                    Math.min(Math.max(time, 0), Infinity) - this.startSelectionAnimationValues.time;
                for (const el of element.elements) {
                    const startValues = this.startAnimationValues.get(el.id)!;
                    const newTime = startValues.time + timeChange;
                    this.setElementPropertyValue(el, 'time', newTime, ElementChangeType.Skip);
                }
            } else {
                this.setElementPropertyValue(
                    element,
                    'time',
                    Math.max(time, 0),
                    ElementChangeType.Skip
                );
            }
        }
    }

    private editStart(element: OneOfSelectableElements): void {
        this.currentUpdatingElement = element;
    }

    private editEnd(): void {
        if (this.editMode === TransformMode.None) {
            this.currentUpdatingElement = undefined;
        }
    }

    scaleElementDurationStart(element: OneOfSelectableElements): void {
        this.editStart(element);

        this.startCreativeAnimationValues = {
            duration: this.creativeDocument.duration,
            stopTime: this.creativeDocument.stopTime || 0
        };

        if (isElementSelection(element)) {
            const elements = element.asSortedArray();

            this.startSelectionAnimationValues = {
                time: element.time,
                duration: element.duration,
                minDuration: 1,
                elements: elements // this.getElementsAnimationStarts(elements)
            };

            let longestTransitionDuration = 0;
            let elementWithLongestDuration: OneOfElementDataNodes | undefined;
            for (const el of elements) {
                const animations = el.animations;
                const inDuration = getInAnimationDuration(animations);
                const outDuration = getOutAnimationDuration(animations);

                this.startAnimationValues.set(el.id, { time: el.time, duration: el.duration });

                if (inDuration + outDuration > longestTransitionDuration) {
                    longestTransitionDuration = inDuration + outDuration;
                    elementWithLongestDuration = el;
                }
            }
            const durationDiff = elementWithLongestDuration
                ? elementWithLongestDuration.duration / element.duration
                : 1;
            this.startSelectionAnimationValues.minDuration =
                (longestTransitionDuration + 0.02) / durationDiff;
        } else {
            this.startAnimationValues.set(element.id, {
                time: element.time,
                duration: element.duration
            });
        }
    }

    setDurationOfSelection(duration: number, direction: TimelineResizeDirection): void {
        const selectedElement = this.currentUpdatingElement!;
        const elements = isElementSelection(selectedElement)
            ? selectedElement.elements
            : [selectedElement];
        const dataElements = elements.filter(e => !e.locked && !hasLockedAncestor(e)).map(e => e);

        setDurationOfElements(dataElements, duration, direction);

        // Still needed to update this way?
        elements.forEach(e => {
            this.valueChange(
                e,
                {
                    time: e.time,
                    duration: e.duration
                },
                ElementChangeType.Skip
            );
        });

        // All selected, move stop time indicator
        if (elements.length === this.creativeDocument.elements.length) {
            if (this.creativeDocument.loops > 0 && typeof this.creativeDocument.stopTime === 'number') {
                const diff =
                    this.creativeDocument.duration / this.startCreativeAnimationValues.duration;
                this.creativeDocument.stopTime = this.roundTime(
                    this.startCreativeAnimationValues.stopTime * diff
                );
            }
        }
    }

    setElementAnimationTimeAndDuration(
        time: number,
        duration: number,
        element: OneOfElementDataNodes,
        isPaste?: boolean
    ): void {
        time = this.roundTime(time);
        duration = this.roundTime(duration);

        const data = {
            duration,
            time
        };
        setElementTimeAndDuration(element, time, duration);
        this.setElementValues(element, data);

        if (!isPaste) {
            this.valueChange(element, { time, duration });
        }
    }

    setElementName(name: string, node: OneOfDataNodes): void {
        const sanitizedName = name.trim();
        if (sanitizedName === node.name) {
            return;
        }

        const element = this.editorStateService.getElementById(node.id);

        this.setElementPropertyValue(node, 'name', sanitizedName);
        element.name = sanitizedName;
    }

    setCreativeDuration(duration: number): void {
        const tmpSelection = new ElementSelection(this.renderer.creativeDocument.elements);
        this.scaleElementDurationStart(tmpSelection);

        this.setDurationOfSelection(duration - tmpSelection.time, 'right');

        tmpSelection.elements.forEach(element =>
            this.valueChange(element, {
                duration: element.duration,
                time: element.time
            })
        );

        this.editorEventService.creative.change('duration', duration, ElementChangeType.Burst);
    }

    setCreativeLoops(loops: number): void {
        this.creativeDocument.loops = loops;
        this.editorEventService.creative.change('loops', loops, ElementChangeType.Instant);
    }

    showManualGifFrames(manual: boolean): void {
        if (!this.creativeDocument.gifExport) {
            return;
        }
        if (this.creativeDocument.gifExport.show !== manual) {
            this.creativeDocument.gifExport.show = manual;

            this.editorEventService.creative.change(
                'gifExport',
                this.creativeDocument.gifExport,
                ElementChangeType.Instant
            );
        }
    }

    setCreativeStopTime(stopTime?: number, emitChanges = true): void {
        this.creativeDocument.stopTime =
            typeof stopTime === 'number' ? Math.max(0, stopTime) : undefined;

        if (emitChanges) {
            this.editorEventService.creative.change('stopTime', stopTime, ElementChangeType.Burst);
        }
    }

    setAnimationStartTime(startTime?: number, emitChanges = true): void {
        this.creativeDocument.startTime =
            typeof startTime === 'number' ? Math.max(0, startTime) : undefined;

        if (emitChanges) {
            this.editorEventService.creative.change('startTime', startTime, ElementChangeType.Burst);
        }
    }

    setPreloadImage(preloadImage: IPreloadImage, emitChanges = true): void {
        this.creativeDocument.preloadImage = preloadImage;
        if (emitChanges) {
            this.editorEventService.creative.change(
                'preloadImage',
                preloadImage,
                ElementChangeType.Burst
            );
        }
    }

    setPreloadImageFrames(frames: number[], emitChanges = true): void {
        if (!this.creativeDocument.preloadImage) {
            return;
        }
        this.creativeDocument.preloadImage.frames = frames.map(frame => Math.max(0, frame));
        if (emitChanges) {
            this.editorEventService.creative.change(
                'preloadImage',
                this.creativeDocument.preloadImage,
                ElementChangeType.Burst
            );
        }
    }

    /**
     * Sets duration of the in or out animation
     */
    setDurationOnAnimationsOfType(
        element: OneOfElementDataNodes,
        type: 'in' | 'out' | 'keyframe',
        duration: number
    ): void {
        setDurationOfType(element, duration, type);
        validateAnimations(element);
    }

    applyAnimationTemplateOnElement(
        template: IAnimationTemplate,
        element: OneOfElementDataNodes,
        duration = 0,
        eventType?: ElementChangeType
    ): void {
        const templateDuration = getAnimationDuration(template);
        const { animation, states } = getAnimationFromTemplate(template, element.duration);
        return this.applyAnimationOnElement(
            element,
            animation,
            states,
            duration || templateDuration,
            eventType
        );
    }

    applyAnimationOnElement(
        element: OneOfElementDataNodes,
        animation: IAnimation,
        states: IState[] = [],
        duration = 0,
        eventType = ElementChangeType.Skip
    ): void {
        const type = animation.type;
        const animationDuration = getAnimationDuration(animation);

        // Clear eventual existing keyframes with same transition type (in/out)
        if (type === 'in' || type === 'out') {
            removeAnimationsOfType(element, type);
        }

        setAnimationDuration(animation, duration || animationDuration);

        // Reserialize states to filter out invalid properties
        const newStates = states.map(state => deserializeState(convertStateToDto(state)));
        element.animations.push(animation);
        element.states.push(...newStates);

        element.animations.sort(sortAnimations);

        // Check this when running
        this.valueChange(element, { animations: element.animations }, eventType);
    }

    removeAnimationTypeOnElement(
        type: AnimationType,
        element: OneOfElementDataNodes,
        isPaste?: boolean
    ): void {
        removeAnimationsOfType(element, type);

        if (!isPaste) {
            this.valueChange(element, { animations: element.animations });
        }
    }

    setElementPosition(
        position: IPosition,
        element: OneOfElementDataNodes,
        eventType?: ElementChangeType
    ): void {
        const stateOrElement = this.propertiesService.inStateView
            ? this.propertiesService.stateData!
            : element;

        if (stateOrElement.x !== position.x || stateOrElement.y !== position.y) {
            this.setElementValues(element, { x: position.x, y: position.y }, eventType);
        }
    }

    private setElementSize(
        size: ISize,
        element: OneOfElementDataNodes,
        eventType?: ElementChangeType
    ): void {
        this.setElementValues(element, size, eventType);
    }

    rotateStart(element: OneOfSelectableElements): void {
        this.editStart(element);
    }

    rotate(diffAngle: number): void {
        if (!this.currentUpdatingElement) {
            throw new Error('You must set the current updating element.');
        }

        if (!isElementSelection(this.currentUpdatingElement)) {
            const stateRotation = this.propertiesService.stateData?.rotationZ;

            // If we're on a state, fetch the state even if it's undefined
            const initialValue =
                typeof this.propertiesService.stateData !== 'undefined' &&
                typeof stateRotation !== 'string'
                    ? stateRotation || 0
                    : this.currentUpdatingElement.rotationZ;

            this.setElementPropertyValue(
                this.currentUpdatingElement,
                'rotationZ',
                initialValue - diffAngle,
                ElementChangeType.Skip
            );
        } else {
            throw new Error('Multiple element rotation is not supported yet.');
        }
    }

    rotateEnd(eventType = ElementChangeType.Force): void {
        if (!this.currentUpdatingElement) {
            throw new Error('You must set the current updating element.');
        }
        if (!isElementSelection(this.currentUpdatingElement)) {
            this.currentUpdatingElement.rotationZ =
                (Math.round((this.currentUpdatingElement.rotationZ * 180) / Math.PI) * Math.PI) / 180;
            if (isHidden(this.currentUpdatingElement)) {
                return;
            }
            const viewElement = this.renderer.getViewElementById(this.currentUpdatingElement.id);
            if (!viewElement) {
                return;
            }
            const styleRule = this.renderer.styleRules_m.get(viewElement.elementCid!)!;
            styleRule.current.styleRule.style.transform =
                this.renderer.getTransformMatrix_m(viewElement);
        }
        this.valueChange(this.currentUpdatingElement, {}, eventType);
    }

    setRotationZ(
        element: OneOfSelectableElements,
        rotation: number,
        eventType: ElementChangeType = ElementChangeType.Skip
    ): void {
        if (!isElementSelection(element)) {
            this.setElementPropertyValue(element, 'rotationZ', rotation, eventType);
        }
    }

    flip(
        element: OneOfElementDataNodes,
        direction: 'mirrorX' | 'mirrorY',
        eventType: ElementChangeType = ElementChangeType.Skip
    ): void {
        this.setElementPropertyValue(element, direction, !element[direction], eventType);
    }

    resizeStart(selection: OneOfSelectableElements): void {
        const box = this.selectionBoundingBox(selection);
        const stateData = this.calculatedState;

        if (isElementSelection(selection) && selection.element && stateData) {
            selection = selection.element;
        }

        this.editStart(selection);
        this.startResize = {
            ...box
        };
        this.startResizeOrigin = {
            x: box.x + box.width / 2,
            y: box.y + box.height / 2
        };

        this.startResizeStateScaleX = stateData?.scaleX !== undefined ? stateData.scaleX : 1;
        this.startResizeStateScaleY = stateData?.scaleY !== undefined ? stateData.scaleY : 1;
        this.startResizeStateX = (stateData?.x as number) || 0;
        this.startResizeStateY = (stateData?.y as number) || 0;

        if (isElementSelection(selection)) {
            for (const e of selection.elements) {
                const b = e;
                this.startResizeLayouts.push({
                    id: e.id,
                    x: b.x - box.x,
                    y: b.y - box.y,
                    width: e.width,
                    height: e.height
                });
            }
        }
    }

    resize(
        offset: Partial<IOffset>,
        inPreviewMode = false,
        eventType: ElementChangeType = ElementChangeType.Skip
    ): void {
        const element = this.currentUpdatingElement!;
        if (!isElementSelection(element)) {
            this.resizeElement(offset, inPreviewMode, eventType);
        } else {
            this.resizeSelection(offset, eventType);
        }
    }

    private resizeSelection(offset: Partial<IOffset>, eventType?: ElementChangeType): void {
        const selection = this.currentUpdatingElement as ElementSelection;
        const { x, y, width, height } = this.startResize;

        let startX: number;
        if (offset.left) {
            if (offset.left < 0 && offset.right && x - offset.left > x + width + offset.right) {
                startX = x + width + offset.right;
            } else if (offset.left < -width) {
                startX = x + width;
            } else {
                startX = x - offset.left;
            }
        } else if (offset.right && offset.right < -width) {
            startX = x + width + offset.right;
        } else {
            startX = x;
        }

        let startY: number;
        if (offset.top) {
            if (offset.top < 0 && offset.bottom && y - offset.top > y + height + offset.bottom) {
                startY = y + height + offset.bottom;
            } else if (offset.top < -height) {
                startY = y + height;
            } else {
                startY = y - offset.top;
            }
        } else if (offset.bottom && offset.bottom < -height) {
            startY = y + height + offset.bottom;
        } else {
            startY = y;
        }

        const xDiff = (offset.left || 0) + (offset.right || 0);
        const yDiff = (offset.top || 0) + (offset.bottom || 0);
        const xRatio = Math.abs(xDiff + width) / width;
        const yRatio = Math.abs(yDiff + height) / height;

        for (const element of selection.elements) {
            const startLayout = this.startResizeLayouts.find(layout => layout.id === element.id)!;
            this.setElementSize(
                {
                    width: Math.max(1, startLayout.width * xRatio),
                    height: Math.max(1, startLayout.height * yRatio)
                },
                element,
                eventType
            );
            this.handleResizeMirroring(this.getResizeBoundingBox(offset), element);
            this.setElementPosition(
                { x: startX + startLayout.x * xRatio, y: startY + startLayout.y * yRatio },
                element,
                ElementChangeType.Skip
            );
            const viewElement = this.renderer.getViewElementById(element.id);
            if (!viewElement) {
                continue;
            }
            this.renderer.setBackgroundElement_m(viewElement);

            if (isTextNode(viewElement)) {
                viewElement.__richTextRenderer?.rerender();
            }
        }
    }

    private getResizeBoundingBox(offset: Partial<IOffset>): IBoundingBox {
        let x: number | undefined;
        let y: number | undefined;

        const startWidth = this.startResize.width;
        const startHeight = this.startResize.height;

        const startX = this.startResize.x + this.startResizeStateX;
        const startY = this.startResize.y + this.startResizeStateY;

        const width = startWidth + (offset.left || 0) + (offset.right || 0);
        const height = startHeight + (offset.top || 0) + (offset.bottom || 0);

        if (typeof offset.left !== 'undefined' && typeof offset.right !== 'undefined') {
            if (width < 0) {
                x = startX + startWidth + (offset.left + offset.right) / 2;
            } else {
                x = startX - (offset.left + offset.right) / 2;
            }
        } else if (typeof offset.left !== 'undefined') {
            if (width < 0) {
                x = startWidth + startX;
            } else {
                x = startX - offset.left;
            }
        } else if (typeof offset.right !== 'undefined') {
            if (width < 0) {
                x = startX + startWidth + offset.right;
            } else {
                x = startX;
            }
        }

        if (typeof offset.top !== 'undefined' && typeof offset.bottom !== 'undefined') {
            if (height < 0) {
                y = startY + startHeight + (offset.top + offset.bottom) / 2;
            } else {
                y = startY - (offset.top + offset.bottom) / 2;
            }
        } else if (typeof offset.top !== 'undefined') {
            if (height < 0) {
                y = startHeight + startY;
            } else {
                y = startY - offset.top;
            }
        } else if (typeof offset.bottom !== 'undefined') {
            if (height < 0) {
                y = startY + startHeight + offset.bottom;
            } else {
                y = startY;
            }
        }

        return {
            x: typeof x !== 'undefined' ? x : startX,
            y: typeof y !== 'undefined' ? y : startY,
            width,
            height
        };
    }

    private calculateRelativeScales(offset: Partial<IOffset>): { scaleX: number; scaleY: number } {
        const data = this.currentUpdatingElement as OneOfElementDataNodes;
        const state = this.propertiesService.stateData;
        if (!data || !state) {
            throw new Error('Could not calculate relative scale of undefined element');
        }

        // Since scaling is never just done on one side, the offset should be mirrored
        // (multiplied by two)
        const horizontalOffset = (offset.left || offset.right || 0) * 2;
        const verticalOffset = (offset.top || offset.bottom || 0) * 2;

        const startWidth = this.startResize.width || 0;
        const startHeight = this.startResize.height || 0;

        const destinationWidth = Math.abs(horizontalOffset + startWidth);
        const destinationHeight = Math.abs(verticalOffset + startHeight);

        let scaleX = destinationWidth / data.width;
        let scaleY = destinationHeight / data.height;

        const ratio = state.ratio;

        if (ratio) {
            if (ratio > 1) {
                scaleY = (1 / ratio) * scaleX;
            } else {
                scaleX = scaleY * ratio;
            }
        }

        return { scaleX, scaleY };
    }

    private resizeElement(
        offset: Partial<IOffset>,
        keepElementWithinWorkspaceBounds = false,
        eventType?: ElementChangeType
    ): void {
        const element = this.currentUpdatingElement as OneOfElementDataNodes;
        const dataRotation = element.rotationZ;

        if (this.propertiesService.inStateView) {
            const scale = this.calculateRelativeScales(offset);

            this.setScale(element, scale, eventType);
            return;
        }
        const resizeBoundingBox = this.getResizeBoundingBox(offset);

        this.handleResizeMirroring(resizeBoundingBox, element);
        const { x, y } = resizeBoundingBox;

        let width = Math.max(1, Math.abs(resizeBoundingBox.width));
        let height = Math.max(1, Math.abs(resizeBoundingBox.height));

        const resizePosition: IPosition = {
            x: x + width / 2,
            y: y + height / 2
        };
        const rotatedResizeOrigin = rotatePosition(
            resizePosition,
            this.startResizeOrigin,
            -dataRotation
        );
        const diffX = rotatedResizeOrigin.x - resizePosition.x;
        const diffY = rotatedResizeOrigin.y - resizePosition.y;
        const newPosition = { x: x + diffX, y: y + diffY };

        if (keepElementWithinWorkspaceBounds) {
            width = clamp(width, 0, this.creativeDocument.width);
            height = clamp(height, 0, this.creativeDocument.height);
            if (newPosition.y < 0) {
                newPosition.y = 0;
            }

            if (newPosition.x < 0) {
                newPosition.x = 0;
            }
        }

        this.setElementSize({ width, height }, element, eventType);

        /**
         * If we don't use the original x and y positions when replacing image
         * the image gets the wrong position
         */

        this.setElementPosition(this.resizeWithoutDiffing ? { x, y } : newPosition, element, eventType);
    }

    private handleResizeMirroring({ width, height }: ISize, element: OneOfElementDataNodes): void {
        if (width < 0 && !this.mirroredXElements.has(element)) {
            this.flip(element, 'mirrorX');
            this.mirroredXElements.add(element);
        } else if (this.mirroredXElements.size > 0 && width > 0) {
            this.flip(element, 'mirrorX');
            this.mirroredXElements.delete(element);
        }
        if (height < 0 && !this.mirroredYElements.has(element)) {
            this.flip(element, 'mirrorY');
            this.mirroredYElements.add(element);
        } else if (this.mirroredYElements.size > 0 && height > 0) {
            this.flip(element, 'mirrorY');
            this.mirroredYElements.delete(element);
        }
    }

    resizeEnd(eventType = ElementChangeType.Force): void {
        const element = this.currentUpdatingElement!;
        this.startResizeLayouts = [];
        this.mirroredXElements = new Set();
        this.mirroredYElements = new Set();

        if (isTextNode(element)) {
            const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(element.id);
            viewElement?.__richTextRenderer?.rerender();
        }

        this.valueChange(element, {}, eventType);
    }

    resetResize(): void {
        const element = this.currentUpdatingElement!;
        if (!isElementSelection(element)) {
            if (this.propertiesService.inStateView) {
                this.setScale(element, {
                    scaleX: this.startResizeStateScaleX,
                    scaleY: this.startResizeStateScaleY
                });
                this.setElementPosition(
                    {
                        x: this.startResizeStateX,
                        y: this.startResizeStateY
                    },
                    element
                );
            } else {
                this.setElementSize(
                    {
                        width: this.startResize.width,
                        height: this.startResize.height
                    },
                    element
                );
                this.setElementPosition(
                    {
                        x: this.startResize.x,
                        y: this.startResize.y
                    },
                    element
                );
            }
        }
    }

    async startEditText(element: OneOfTextDataNodes, quiet = false): Promise<void> {
        if (!isTextNode(element)) {
            throw new Error('Text content can only be set on Text elements');
        }
        const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(element.id);
        if (!viewElement) {
            return;
        }

        if (this.isEditingText) {
            this.stopEditText();
        }

        this.editStart(element);
        const richTextRenderer = viewElement.__richTextRenderer;
        this.renderer.bringElementToTop_m(viewElement);
        await richTextRenderer?.editor_m!.startEdit();
        richTextRenderer?.editor_m!.selection.selectAllText(quiet);
        if (this.richTextBlurSuspensionElements) {
            for (const el of this.richTextBlurSuspensionElements) {
                richTextRenderer?.editor_m!.addBlurSuspensionElement(el);
            }
        }
        this.isEditingText = true;
        this.activityLoggerService.log(
            `Started editing text on element '${getElementIdentifier(element)}'`
        );
    }

    stopEditText(): void {
        if (!this.isEditingText) {
            return;
        }

        const textElement = this.currentUpdatingElement! as OneOfTextDataNodes;
        const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(textElement.id);
        if (!viewElement) {
            return;
        }
        const richTextRenderer = viewElement.__richTextRenderer;
        richTextRenderer?.editor_m!.stopEdit();
        richTextRenderer?.editor_m!.clearBlurSuspensionElements();
        this.isEditingText = false;
        this.renderer.updateElementOrder_m();
        this.activityLoggerService.log(
            `Stopped editing text on element '${getElementIdentifier(textElement)}'`
        );
    }

    setPosition(
        element: OneOfElementDataNodes,
        position: IPosition,
        eventType?: ElementChangeType
    ): void {
        this.setElementPosition(position, element, eventType);
        if (isHidden(element)) {
            return;
        }
        const viewElement = this.renderer.getViewElementById(element.id);
        if (!viewElement) {
            return;
        }
        const styleRule = this.renderer.styleRules_m.get(viewElement.elementCid!)!;
        Object.assign(
            styleRule.current.styleRule.style,
            this.renderer.getPositionAndSizeStyle_m(viewElement)
        );
    }

    setSelectionPosition(
        elements: OneOfElementDataNodes[],
        boundingBox: IBoundingBox,
        position: IPosition,
        eventType?: ElementChangeType
    ): void {
        const diffX = position.x - boundingBox.x;
        const diffY = position.y - boundingBox.y;
        for (const element of elements) {
            this.setElementPosition({ x: element.x + diffX, y: element.y + diffY }, element, eventType);
        }
    }

    setSize(
        element: OneOfSelectableElements,
        size: ISize,
        resizeWithoutDiffing = false,
        eventType?: ElementChangeType
    ): void {
        this.resizeWithoutDiffing = resizeWithoutDiffing;
        if (isElementSelection(element)) {
            const box = this.elementSelectionBoundingBoxService.boundingBoxes?.lockedAndHiddenExcluded;
            if (!box) {
                throw new Error('No selection bounding box found when setting size of selection');
            }
            const ratioX = size.width / box.width;
            const ratioY = size.height / box.height;
            for (const e of element.elements) {
                this.setElementPosition(
                    {
                        x: box.x + (e.x - box.x) * ratioX,
                        y: box.y + (e.y - box.y) * ratioY
                    },
                    e,
                    eventType
                );

                this.setElementSize(
                    {
                        width: e.width * ratioX,
                        height: e.height * ratioY
                    },
                    e,
                    eventType
                );

                if (isTextNode(e)) {
                    const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(e.id);
                    viewElement?.__richTextRenderer?.rerender();
                }
            }
        } else {
            this.resizeStart(element);
            const data = this.propertiesService.inStateView
                ? this.propertiesService.stateData!
                : element;

            const width = 'width' in data && typeof data.width === 'number' ? data.width : 0;
            const height = 'height' in data && typeof data.height === 'number' ? data.height : 0;

            this.resize(
                {
                    right: size.width - width,
                    bottom: size.height - height
                },
                false,
                eventType
            );

            if (isTextNode(element)) {
                const viewElement = this.renderer.getViewElementById<OneOfTextViewElements>(element.id);
                viewElement?.__richTextRenderer?.rerender();
            }
        }

        this.resizeWithoutDiffing = false;
    }

    setScale(
        element: OneOfSelectableElements,
        scale: { scaleX?: number; scaleY?: number },
        eventType?: ElementChangeType
    ): void {
        const state = this.propertiesService.stateData;
        if (!isElementSelection(element) && state) {
            // Do not modify scale of formula values
            if (isFormulaValue(state.scaleX) || isFormulaValue(state.scaleY)) {
                return;
            }

            scale.scaleX = scale.scaleX !== undefined ? decimal(scale.scaleX) : undefined;
            scale.scaleY = scale.scaleY !== undefined ? decimal(scale.scaleY) : undefined;

            if (state.scaleX !== scale.scaleX || state.scaleY !== scale.scaleY) {
                this.setElementValues(element, scale, eventType);
            }
        }
    }

    fillToCanvas(selection: ElementSelection): void {
        const elements = selection.elements;

        // Not supported for states
        if (this.propertiesService.inStateView) {
            return;
        }

        const canvasSize = this.renderer.canvasSize_m;
        for (const element of elements) {
            if (typeof element.ratio === 'number') {
                const canvasRatio = canvasSize.width / canvasSize.height;
                let width = 0;
                let height = 0;

                if (canvasRatio > element.ratio) {
                    width = Math.ceil(canvasSize.width);
                    height = Math.ceil(canvasSize.width / element.ratio);
                } else {
                    width = Math.ceil(canvasSize.height * element.ratio);
                    height = Math.ceil(canvasSize.height);
                }

                const position = {
                    x: canvasSize.width / 2 - width / 2,
                    y: canvasSize.height / 2 - height / 2
                };

                if (this.propertiesService.inStateView) {
                    this.setSizeAndPositionOfState(element, canvasSize, position);
                } else {
                    this.setSize(element, { width, height });
                    this.setPosition(element, position);
                }
            } else {
                const size: ISize = Object.create(canvasSize);
                const position = { x: 0, y: 0 };
                if (element.kind === ElementKind.Image) {
                    const { imageSettings, imageAsset } = element;
                    if (imageSettings.sizeMode === ImageSizeMode.Stretch) {
                        // Do nothing
                    } else if (
                        imageSettings.sizeMode === ImageSizeMode.Crop &&
                        element.x === 0 &&
                        element.y === 0 &&
                        element.width === canvasSize.width &&
                        element.height === canvasSize.height
                    ) {
                        this.setImageSettings(
                            element,
                            { sizeMode: ImageSizeMode.Fit },
                            ElementChangeType.Skip
                        );
                        // Feed images should only swtich to contain.
                        if (imageAsset && imageAsset.width && imageAsset.height) {
                            this.setAssetSizeAndPosition(imageAsset, canvasSize, position, size);
                        }
                    } else if (!imageAsset || !isSVGFile(imageAsset.url)) {
                        this.setImageSettings(
                            element,
                            { sizeMode: ImageSizeMode.Crop },
                            ElementChangeType.Skip
                        );
                    }
                } else if (element.kind === ElementKind.Video) {
                    const { videoSettings, videoAsset } = element;

                    if (
                        videoSettings.sizeMode === VideoSizeMode.Crop &&
                        element.x === 0 &&
                        element.y === 0 &&
                        element.width === canvasSize.width &&
                        element.height === canvasSize.height
                    ) {
                        this.setVideoSettings(element, { sizeMode: VideoSizeMode.Fit });
                        // Feed videos should only swtich to contain.
                        if (videoAsset && videoAsset.width && videoAsset.height) {
                            this.setAssetSizeAndPosition(videoAsset, canvasSize, position, size);
                        }
                    } else if (!videoAsset || !isSVGFile(videoAsset.url)) {
                        this.setVideoSettings(element, { sizeMode: VideoSizeMode.Crop });
                    }
                }
                if (this.propertiesService.inStateView) {
                    this.setSizeAndPositionOfState(element, canvasSize, position);
                } else {
                    this.setSize(element, size);
                    this.setPosition(element, position);
                }
            }

            this.setRotationZ(element, 0);
        }
    }

    private setAssetSizeAndPosition(
        asset: IImageElementAsset | IVideoElementAsset,
        canvasSize: ISize,
        position: IPosition,
        size: ISize
    ): void {
        if (asset && asset.width && asset.height) {
            const widthRatio = asset.width / canvasSize.width;
            const heightRatio = asset.height / canvasSize.height;
            let width: number;
            let height: number;
            if (widthRatio === heightRatio) {
                width = canvasSize.width;
                height = canvasSize.height;
            } else if (widthRatio > heightRatio) {
                width = (canvasSize.height * asset.width) / asset.height;
                height = canvasSize.height;
            } else {
                width = canvasSize.width;
                height = (canvasSize.width * asset.height) / asset.width;
            }
            const x = -Math.round((width - canvasSize.width) / 2);
            const y = -Math.round((height - canvasSize.height) / 2);
            position.x = x;
            position.y = y;
            size.width = Math.round(width);
            size.height = Math.round(height);
        }
    }

    private setSizeAndPositionOfState(
        element: OneOfElementDataNodes,
        size: ISize,
        position: { x: number; y: number }
    ): void {
        const state = this.propertiesService.stateData;
        const viewElement = this.renderer.getViewElementById(element.id);
        if (!viewElement) {
            return;
        }
        if (state) {
            delete state.scaleX;
            delete state.scaleY;
            const x = typeof state.x === 'number' ? state.x : 0;
            const y = typeof state.y === 'number' ? state.y : 0;
            const newPos = {
                x: position.x + size.width / 2 - (viewElement.x + viewElement.width / 2) + x,
                y: position.y + size.height / 2 - (viewElement.y + viewElement.height / 2) + y
            };
            const newSize = {
                width: size.width - viewElement.width,
                height: size.height - viewElement.height
            };
            this.setSize(element, newSize);
            this.setPosition(element, newPos);
        }
    }

    setCreativeAppearance<Key extends keyof ICreativeDataNode, Property = ICreativeDataNode[Key]>(
        styleKey: Key,
        styleProperty: Property,
        eventType?: ElementChangeType
    ): void {
        this.renderer.setCreativeStyle(styleKey, styleProperty);

        if (eventType !== undefined) {
            this.editorEventService.creative.change(styleKey, undefined, eventType);
        }
    }

    setCreativeSocialGuide(socialGuide: ISocialGuide): void {
        this.creativeDocument.socialGuide = {
            ...socialGuide,
            network: socialGuide.network?.type,
            placement: socialGuide.placement?.type
        };
    }

    setLocked(elements: ReadonlyArray<OneOfDataNodes>, value: boolean): void {
        for (const element of elements) {
            const dataElement = element;
            // If not all elements are locked, lock all of them
            this.setElementPropertyValue(dataElement, 'locked', value);
        }
    }

    setElementVisibility(element: OneOfDataNodes, isHidden: boolean): void {
        this.setElementPropertyValue(element, 'hidden', isHidden);
    }

    setElementsVisibility(elements: OneOfDataNodes[], isHidden: boolean): void {
        elements.forEach(element => this.setElementVisibility(element, isHidden));
    }

    useCreative(creative: ICreativeDataNode): void {
        this.creativeDocument = creative;
    }

    roundTime(time: number): number {
        return time;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
