import { convertCreativeToDocumentDto, deserializeDesignDocument } from '@creative/serialization';
import {
    serializeVersion,
    serializeVersions
} from '@creative/serialization/versions/version-serializer';
import { adjustWeightCalculation } from '@creative/utils';
import {
    CreativeDtoV2,
    CreativeSetDtoV2,
    DesignDtoV2,
    ElementDtoV2,
    ImageAssetDtoV2,
    VideoAssetDtoV2
} from '@domain/api/generated/sapi';
import { ImageLibraryAsset } from '@domain/brand/brand-library/image-asset';
import { VideoLibraryAsset } from '@domain/brand/brand-library/video-asset';
import { ApprovalStatus, ICreative, ICreativeDto } from '@domain/creativeset/creative';
import { ICreativeset } from '@domain/creativeset/creativeset';
import { IDesign } from '@domain/creativeset/design';
import { IElement } from '@domain/creativeset/element';
import { IVersion, IVersionDto } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { deserializeImageAssets } from './asset';
import { deserializeVersion } from './version';

export function deserializeCreativeset(
    creativesetDto: CreativeSetDtoV2,
    creativeId?: string
): ICreativeset {
    const { creatives, designs, versions, elements } = deserializeCreativeData(
        creativesetDto,
        creativeId
    );
    const defaultVersion = deserializeVersion(creativesetDto.defaultVersion);
    const images = deserializeImageAssets(creativesetDto.images);

    return {
        id: creativesetDto.id,
        name: creativesetDto.name,
        brandId: creativesetDto.brandId,
        stateId: creativesetDto.stateId,
        creatives,
        designs,
        versions,
        defaultVersion,
        sizes: creativesetDto.sizes,
        elements,
        images,
        videos: creativesetDto.videos,
        widgets: creativesetDto.widgets
    };
}

export function deserializeCreativeData(
    creativesetDto: CreativeSetDtoV2,
    creativeId?: string
): { creatives: ICreative[]; designs: IDesign[]; versions: IVersion[]; elements: IElement[] } {
    let creatives: ICreative[];
    let designs: IDesign[];
    let versions: IVersion[];
    const elements = deserializeCreativesetElements(creativesetDto.elements);

    if (creativeId) {
        // just deserialize data for one creative
        const creative = creativesetDto.creatives.find(c => c.id === creativeId);
        if (!creative) {
            throw new Error('Creative not found');
        }

        const design = creativesetDto.designs.find(({ id }) => id === creative.design?.id);
        const parsedCreative = deserializeCreative(
            creativesetDto,
            creative,
            design ? [deserializeDesign(design, elements)] : []
        );

        creatives = [parsedCreative];
        designs = parsedCreative.design ? [parsedCreative.design] : [];
        versions = [parsedCreative.version];
    } else {
        designs = creativesetDto.designs.map(design => deserializeDesign(design, elements));
        creatives = creativesetDto.creatives.map(creative =>
            deserializeCreative(creativesetDto, creative, designs)
        );
        versions = creativesetDto.versions.map(version => deserializeVersion(version));
    }

    return { creatives, designs, versions, elements };
}

export function deserializeCreative(
    creativesetDto: CreativeSetDtoV2,
    creativeDto: CreativeDtoV2 | ICreativeDto,
    designs: IDesign[]
): ICreative {
    const { sizes, versions } = creativesetDto;

    const design = creativeDto.design ? designs.find(d => d.id === creativeDto.design?.id) : undefined; // creative can have no design
    const size = sizes.find(s => s.id === creativeDto.size.id);
    const version = versions.find(v => v.id === creativeDto.version.id);

    if (!size) {
        throw new Error('No size');
    }

    if (!version) {
        throw new Error('No version');
    }

    // Graphql property
    removeGraphQlTypename(creativeDto);

    const { id, checksum, targetUrl } = creativeDto;

    return {
        id: `${id}`,
        checksum,
        targetUrl,
        design,
        size,
        version: Object.freeze(deserializeVersion(version)),
        approvalStatus: (creativeDto.approvalStatus as ApprovalStatus) ?? ApprovalStatus.None,
        connectedCampaigns: creativeDto.connectedCampaigns,
        creativeWeights: creativeDto.creativeWeights
            ? adjustWeightCalculation(creativeDto.creativeWeights)
            : undefined
    };
}

export function deserializeDesign(design: DesignDtoV2, elements: IElement[]): IDesign {
    const designDocument = deserializeDesignDocument(design.document);
    return {
        id: design.id,
        name: design.name,
        hasHeavyVideo: design.hasHeavyVideo,
        elements: elements.filter(({ id: elementId }) =>
            design.elements.some(({ id: designElementId }) => designElementId === elementId)
        ),
        document: designDocument
    };
}

export function deserializeCreativesetElements(elements: ElementDtoV2[]): IElement[] {
    return elements.map(deserializeCreativesetElement);
}

export function deserializeCreativesetElement(element: ElementDtoV2): IElement {
    removeGraphQlTypename(element);
    element.properties.forEach(property => {
        removeGraphQlTypename(property);
    });

    return {
        ...element,
        type: element.type as ElementKind
    };
}

export function removeGraphQlTypename(obj: object): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (obj as any)['__typename'];
}

export function convertCreativesetToDto(
    creativeSet: ICreativeset,
    sentinelService?: { trackPageAction: (message: string, data: unknown) => void }
): CreativeSetDtoV2 {
    const { id, name, brandId, images, videos, widgets, sizes, stateId, elements, designs } =
        creativeSet;

    const versions: IVersionDto[] = serializeVersions(creativeSet.versions, sentinelService);
    const creatives: CreativeDtoV2[] = convertCreativesV1ToV2(creativeSet.creatives);
    const defaultVersion = serializeVersion(creativeSet.defaultVersion, sentinelService);

    // We can spread creativeset when the Creativeset data class is removed
    return {
        id: `${id}`,
        name,
        brandId,
        images: convertImageAssetsToDto(images),
        videos: convertVideoAssetsToDto(videos),
        widgets,
        sizes,
        stateId,
        elements: convertElementsV1ToV2(elements),
        designs: convertDesignsToDto(designs),
        versions,
        creatives,
        defaultVersion
    };
}

export function convertElementsV1ToV2(elements: IElement[]): ElementDtoV2[] {
    return elements.map(element => ({
        ...element,
        type: element.type as ElementDtoV2['type'],
        properties: element.properties.map(property => {
            return {
                ...property,
                unit: convertUnitsV1ToV2(property.unit)
            };
        })
    }));
}

const validUnits = [
    'array',
    'boolean',
    'color',
    'feed',
    'font',
    'id',
    'image',
    'number',
    'object',
    'select',
    'string',
    'text'
] as const;
type UnitType = (typeof validUnits)[number];

export function convertUnitsV1ToV2(unit?: string): UnitType | undefined {
    return validUnits.includes(unit as UnitType) ? (unit as UnitType) : undefined;
}

export function convertDesignsToDto(designs: IDesign[]): DesignDtoV2[] {
    return designs.map(design => ({
        id: `${design.id}`,
        name: design.name,
        hasHeavyVideo: design.hasHeavyVideo,
        elements: design.elements.map(({ id }) => ({ id })),
        document: convertCreativeToDocumentDto(design.document)
    }));
}

export function convertCreativesV1ToV2(creatives: ICreative[]): CreativeDtoV2[] {
    return creatives.map(creative => convertCreativeV1ToV2(creative));
}

export function convertCreativeV1ToV2(creative: ICreative): CreativeDtoV2 {
    let mappedStatus: CreativeDtoV2['approvalStatus'];
    switch (creative.approvalStatus) {
        case ApprovalStatus.InProgress:
            mappedStatus = 'In progress';
            break;
        case ApprovalStatus.ForReview:
            mappedStatus = 'For review';
            break;
        case ApprovalStatus.NotApproved:
            mappedStatus = 'Not approved';
            break;
        case ApprovalStatus.Approved:
            mappedStatus = 'Approved';
            break;
        default:
            mappedStatus = undefined;
    }
    return {
        id: creative.id,
        connectedCampaigns: creative.connectedCampaigns,
        checksum: creative.checksum,
        creativeWeights: creative.creativeWeights,
        targetUrl: creative.targetUrl,
        design: creative.design ? { id: creative.design.id } : undefined,
        version: { id: creative.version.id },
        size: { id: creative.size.id },
        approvalStatus: mappedStatus
    };
}

function convertImageAssetsToDto(images: ImageLibraryAsset[]): ImageAssetDtoV2[] {
    return images.map(image => ({
        id: image.id,
        created: image.created,
        fileSize: image.fileSize,
        name: image.name,
        original: image.original,
        thumbnail: image.thumbnail,
        animatedThumbnail: image.animatedThumbnail,
        isGenAi: image.isGenAi,
        modified: image.modified
    }));
}

function convertVideoAssetsToDto(videos: VideoLibraryAsset[]): VideoAssetDtoV2[] {
    return videos.map(video => ({
        id: video.id,
        created: video.created,
        fileSize: video.fileSize,
        name: video.name,
        thumbnail: video.thumbnail,
        modified: video.modified,
        durationInMilliseconds: video.durationInMilliseconds,
        height: video.height,
        width: video.width,
        url: video.url
    }));
}

/** NOTE: BELOW WILL BE USED/RESUMED IN THE FUTURE IN ONE WAY OR ANOTHER */

// export function deserializeDesignApiCreativeset(creativeSetDto: CreativeSetDto): IDesignApiCreativeset {
//     const elements = creativeSetDto.elements.map(deserializeOneOfDesignApiElement);
//     const creatives = creativeSetDto.creatives.map(deserializeDesignApiCreative);
// const versions = creativeSetDto.versions.map(deserializeDesignApiVersion);
//     const sizes = creativeSetDto.sizes.map(deserializeDesignApiSize);
//     const defaultVersion = deserializeDesignApiVersion(creativeSetDto.defaultVersion);
//     const images = deserializeImageAssets(creativeSetDto.images);

//     return {
//         id: creativeSetDto.id,
//         name: creativeSetDto.name,
//         stateId: creativeSetDto.stateId,
//         brandId: creativeSetDto.brandId,
//         videos: creativeSetDto.videos,
//         widgets: creativeSetDto.widgets,
//         images,
//         elements,
//         creatives,
//         versions,
//         sizes,
//         defaultVersion
//     };
// }

// function deserializeDesignApiCreative(creativeDto: CreativeDto): IDesignApiCreative {
//     return {
//         id: creativeDto.id,
//         checksum: creativeDto.checksum,
//         design: {} as any, // TODO
//         sizeId: creativeDto.size.id,
//         versionId: creativeDto.version.id,
//         hasHeavyVideo: false, // TODO
//         elements: creativeDto.elements
//     };
// }

// function deserializeDesignApiVersion(versionDto: VersionDto): IDesignApiVersion {
//     return {
//         id: versionDto.id,
//         name: versionDto.name,
//         localization: versionDto.localization,
//         targetUrl: versionDto.targetUrl,
//         elements: versionDto.elements
//     };
// }

// function deserializeDesignApiSize(sizeDto: SizeDto): IDesignApiSize {
//     return {
//         id: sizeDto.id,
//         name: sizeDto.name,
//         width: sizeDto.width,
//         height: sizeDto.height,
//         elements: sizeDto.elements
//     };
// }

// function deserializeOneOfDesignApiElement(
//     elementDto: DesignApiElementOverrideOrElement
// ): MaybePartial<OneOfDesignApiElements> {
//     if ('__textKind' in elementDto) {
//         return deserializeDesignApiTextElement(elementDto);
//     }

//     throw new Error('Element could not be deserialized. Unknown element kind.');
// }

// function deserializeDesignApiPrimitiveElement(
//     elementDto: MaybePartial<DesignApiElement>
// ): MaybePartial<IPrimitiveElement> {

//     const kind = Object.keys(elementDto).find(key => key.indexOf('__') > -1 ? key.replace('__', '').replace('Kind', '') : key)

//     return {
//         id: elementDto.id,
//         name: elementDto.name,
//         hidden: elementDto.hidden,
//         locked: elementDto.locked,
//         parentNodeId: elementDto.parentNodeId,
//         kind: kind as ElementKind // Remove cast and parsing
//     };
// }

// function deserializeDesignApiBaseElement(
//     elementDto: MaybePartial<Exclude<DesignApiElement, GroupNodeDto>>
// ): MaybePartial<IBaseElement> {
//     return {
//         ...deserializeDesignApiPrimitiveElement(elementDto),
//         x: elementDto.x,
//         y: elementDto.y,
//         width: elementDto.width,
//         height: elementDto.height,
//         parentId: elementDto.parentId,
//         parentNodeId: elementDto.parentNodeId,
//         time: elementDto.time,
//         duration: elementDto.duration,
//     };
// }

// function deserializeDesignApiElement(
//     elementDto: MaybePartial<Exclude<DesignApiElement, GroupNodeDto>>
// ): MaybePartial<IElementV2> {
//     return {
//         ...deserializeDesignApiBaseElement(elementDto),
//         filters: elementDto.filters,
//         mirrorX: elementDto.mirrorX,
//         mirrorY: elementDto.mirrorY,
//         originX: elementDto.originX,
//         originY: elementDto.originY,
//         opacity: elementDto.opacity,
//         rotationX: elementDto.rotationX,
//         rotationY: elementDto.rotationY,
//         rotationZ: elementDto.rotationZ,
//         scaleX: elementDto.scaleX,
//         scaleY: elementDto.scaleY,
//         ratio: elementDto.ratio,
//         // TODO - Remove fallback (CURRENTLY UNDEFINED)
//         states: (elementDto.states ?? []).map(deserializeState),
//         animations: (elementDto.animations ?? []).map(deserializeAnimation),
//         actions: (elementDto.actions ?? []).map(action => deserializeAction(action)),
//         fill: deserializeProperty('fill', elementDto.fill, true),
//         border: deserializeProperty('border', elementDto.border, true),
//         masking: 'masking' in elementDto // TODO: use helper function
//             ? deserializeProperty('masking', elementDto.masking, true)
//             : undefined,
//         shadows: deserializeProperty('shadows', elementDto.shadows, true),
//         radius: deserializeProperty('radius', elementDto.radius, true)
//     };
// }

// function deserializeDesignApiTextElement(textElementDto: MaybePartial<TextElementDto>): MaybePartial<IDesignApiTextElement> {
//     return {
//         ...deserializeDesignApiElement(textElementDto),
//         kind: ElementKind.Text,
//         characterSpacing: textElementDto.characterSpacing,
//         horizontalAlignment: textElementDto.horizontalAlignment,
//         verticalAlignment: textElementDto.verticalAlignment,
//         lineHeight: textElementDto.lineHeight,
//         fontSize: textElementDto.fontSize,
//         font: textElementDto.font,
//         maxRows: textElementDto.maxRows,
//         padding: textElementDto.padding,
//         textOverflow: textElementDto.textOverflow,
//         strikethrough: textElementDto.strikethrough,
//         underline: textElementDto.underline,
//         uppercase: textElementDto.uppercase,
//         content: textElementDto.text, // TODO (TEMP)
//         characterStyles: textElementDto.characterStyles,
//         textColor: textElementDto.textColor,
//         // textShadows: textElementDto.textShadows
//     };
// }
