import {
    ChangeDetectorRef,
    Directive,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { Role } from '@domain/user';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreativesetShowcaseService } from '../creativeset-showcase/state/creativeset-showcase.service';
import { EnvironmentService } from '../services/environment.service';
import { UserService } from '../user/state/user.service';

/**
 * This directive is a structural directive (uses TemplateRef) and therefore has to use such a
 * convention (similar to ngIf), e.g:
 * *permissions="'EditCreative'"
 * *permissions="['EditCreative', 'TranslateVersions']; operation 'OR'"
 *
 * If and when the rules are needed elsewhere, e.g in order prevent clicks or similar, the rules can be accessed
 * with the directive's context. E.g
 * <div *permissions="'EditCreative'; let permissions = permissions">
 *     <div (click)="click(permissions)">Click me</div>
 * </div>
 */

@Directive({
    selector: '[permissions]',
    standalone: true
})
export class PermissionsDirective implements OnInit, OnDestroy {
    @Input() permissions: any | ViewPermissionType | ViewPermissionType[];

    /**
     * Used as:
     * *permissions="['EditCreative', 'TranslateVersions']; operation 'OR'"
     */
    @Input() permissionsOperation: 'AND' | 'OR' = 'AND';

    permissionRules: IPermissionRules = {} as IPermissionRules;
    context: IPermissionContext;
    private role?: Role;
    private isHidden = true;
    private unsubscribe$ = new Subject<void>();

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        if (!this.permissionRules.preventClicks) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    constructor(
        private templateRef: TemplateRef<IPermissionContext>,
        private viewContainer: ViewContainerRef,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
        private environmentService: EnvironmentService,
        private creativesetShowcaseService: CreativesetShowcaseService
    ) {}

    async ngOnInit(): Promise<void> {
        this.userService.role$.pipe(takeUntil(this.unsubscribe$)).subscribe(role => {
            this.role = role;
        });

        await this.updateView();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private async updateView(): Promise<void> {
        const hasPermission = await this.validatePermissions();
        if (hasPermission && this.isHidden) {
            this.viewContainer.createEmbeddedView(this.templateRef, this.context);
            this.isHidden = false;
        } else {
            this.viewContainer.clear();
            this.isHidden = true;
        }

        this.cdr.detectChanges();
    }

    private async checkPermission(permission: ViewPermissionType): Promise<boolean> {
        const basicPermission = permission.replace(/!/g, '') as ViewPermissionType;
        const isReversed = permission.charAt(0) === '!';

        if (!this.permissions || !ViewPermissions.includes(basicPermission)) {
            throw new Error(
                `Invalid permission. '${permission}' is not a valid view permission.\nAvailable permissions are: '${ViewPermissions.join(
                    "', '"
                )}'`
            );
        }

        if (this.environmentService.inShowcaseMode) {
            this.setShowcasePermissionRules(basicPermission);
        } else {
            await this.setStudioPermissionRules(basicPermission);
        }

        if (isReversed) {
            this.permissionRules.permitted = !this.permissionRules.permitted;
        }
        return this.permissionRules.permitted;
    }

    private async validatePermissions(): Promise<boolean> {
        let hasPermission = false;
        if (Array.isArray(this.permissions)) {
            for (const permission of this.permissions) {
                if (await this.checkPermission(permission)) {
                    hasPermission = true;

                    if (this.permissionsOperation === 'OR') {
                        break;
                    }
                } else {
                    hasPermission = false;

                    if (this.permissionsOperation === 'AND') {
                        break;
                    }
                }
            }
        } else {
            if (await this.checkPermission(this.permissions)) {
                hasPermission = true;
            }
        }

        this.context = {
            permissions: this.permissionRules
        };

        return hasPermission;
    }

    private async setStudioPermissionRules(basicPermission: ViewPermissionType): Promise<void> {
        switch (basicPermission) {
            case 'Default':
                this.permissionRules.permitted = true;
                break;
            case 'Showcase':
                this.permissionRules.permitted = false;
                break;
            case 'Comments':
                this.permissionRules.permitted = true;
                break;
            case 'TranslateVersions':
                this.permissionRules.permitted = true;
                break;
            case 'EditCreative':
                this.permissionRules.permitted = true;
                this.permissionRules.preventClicks = this.userService.hasRoleAccess([
                    Role.Analyzer,
                    Role.Publisher,
                    Role.TextEditor,
                    Role.TextEditorExtended,
                    Role.PublisherExtended
                ]);
                break;
            case 'Analytics':
                this.permissionRules.permitted =
                    (await this.userService.hasPermission('Analytics')) &&
                    !this.userService.hasRoleAccess([
                        Role.ExternalUser,
                        Role.Publisher,
                        Role.TextEditor,
                        Role.TextEditorExtended
                    ]);
                break;
            case 'CalculateWeights':
                this.permissionRules.permitted = true;
                break;
            case 'Status':
                this.permissionRules.permitted = true;
                break;
            case 'StudioGenAiImage':
                this.permissionRules.permitted = await this.userService.hasPermission(
                    'StudioGenAiImage',
                    true
                );
                break;
            case 'CampaignActions':
                this.permissionRules.permitted = !this.userService.hasRoleAccess([
                    Role.Analyzer,
                    Role.Designer,
                    Role.TextEditor,
                    Role.TextEditorExtended
                ]);
                break;
            case 'ChangeDesign':
                this.permissionRules.permitted = !this.userService.hasRoleAccess([
                    Role.Analyzer,
                    Role.Publisher,
                    Role.TextEditor,
                    Role.TextEditorExtended,
                    Role.PublisherExtended
                ]);
                break;
            case 'AddSize':
                this.permissionRules.permitted = !this.userService.hasRoleAccess([
                    Role.Analyzer,
                    Role.Publisher,
                    Role.TextEditor,
                    Role.TextEditorExtended,
                    Role.PublisherExtended
                ]);
                break;
            case 'ManageVersions':
                this.permissionRules.permitted = !this.userService.hasRoleAccess([
                    Role.Analyzer,
                    Role.Publisher
                ]);
                break;
            case 'ShareCreativeSet':
                this.permissionRules.permitted = !this.userService.hasRoleAccess([
                    Role.Analyzer,
                    Role.Publisher,
                    Role.TextEditor,
                    Role.TextEditorExtended,
                    Role.PublisherExtended
                ]);
                break;
            case 'Duplicate':
                this.permissionRules.permitted = !this.userService.hasRoleAccess([
                    Role.Analyzer,
                    Role.Publisher,
                    Role.TextEditor,
                    Role.TextEditorExtended,
                    Role.PublisherExtended
                ]);
                break;
            case 'VideoLibrary':
                this.permissionRules.permitted =
                    await this.userService.hasPermission('StudioVideoLibrary');
                break;
            case 'SocialCampaignManager':
                this.permissionRules.permitted =
                    await this.userService.hasPermission('SocialCampaignManager');
                break;
            case 'BannerflowLibrary':
                this.permissionRules.permitted =
                    await this.userService.hasPermission('BannerflowLibrary');
                break;
            case 'TranslationPage':
                this.permissionRules.permitted =
                    await this.userService.hasPermission('StudioTranslationPage');
                break;
            case 'AutoTranslate':
                this.permissionRules.permitted =
                    await this.userService.hasPermission('StudioAutoTranslate');
                break;
        }
    }

    private setShowcasePermissionRules(basicPermission: ViewPermissionType): void {
        switch (basicPermission) {
            case 'Default':
                this.permissionRules.permitted = false;
                break;
            case 'Showcase':
                this.permissionRules.permitted = true;
                break;
            case 'Comments':
                this.permissionRules.permitted =
                    !this.environmentService.isMobile &&
                    this.creativesetShowcaseService.operationsAllowed(['makeComments']);
                break;
            case 'TranslateVersions':
                this.permissionRules.permitted =
                    !this.environmentService.isMobile &&
                    this.creativesetShowcaseService.operationsAllowed(['updateVersions']);
                break;
            case 'EditCreative':
                this.permissionRules.permitted = false;
                break;
            case 'CalculateWeights':
                this.permissionRules.permitted = false;
                break;
            case 'Status':
                this.permissionRules.permitted =
                    !this.environmentService.isMobile &&
                    this.creativesetShowcaseService.operationsAllowed(['setApprovalStatusOnCreatives']);
                break;
            case 'CampaignActions':
                this.permissionRules.permitted = false;
                break;
            case 'ChangeDesign':
                this.permissionRules.permitted = false;
                break;
            case 'Duplicate':
                this.permissionRules.permitted = false;
                break;
        }
    }
}
/*
    View permissions are Studio internal defined permission used for hiding / showing components.
    These are not a directly translatable from user permissions, but rather allow to change permissions on more granular level.
    They are mapped in this permissions.directive by checking user permissions, role, other flags, etc.
 */
const ViewPermissions = [
    'AddSize',
    'Analytics',
    'BannerflowLibrary',
    'CalculateWeights',
    'CampaignActions',
    'ChangeDesign',
    'Comments',
    'Default', // Explicit for non-showcase
    'Duplicate',
    'EditCreative',
    'ManageVersions',
    'ShareCreativeSet',
    'Showcase', // Explicit for showcase
    'SocialCampaignManager',
    'Status',
    'StudioGenAiImage',
    'TranslateVersions',
    'VideoLibrary',
    'TranslationPage',
    'AutoTranslate'
] as const;

type ViewPermissionType = (typeof ViewPermissions)[number];

export interface IPermissionRules {
    /** The element will be shown if permitted is true */
    permitted: boolean;
    preventClicks: boolean;
}

interface IPermissionContext {
    permissions: IPermissionRules;
}
