import { Injectable } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { Logger } from '@bannerflow/sentinel-logger';
import {
    addKeyframeWithState,
    createAnimation,
    getAbsoluteTimeBetween,
    getAnimationsOfType,
    hasAnimationsOfType,
    removeKeyframes
} from '@creative/animation.utils';
import { parseColor } from '@creative/color.utils';
import { isVersionedText } from '@creative/elements/rich-text/text-nodes';
import {
    createInlineStyledTextFromText,
    createTextFromInlineStyledText,
    isGroupDataNode,
    isGroupNodeDto,
    isTextDataElement,
    isTextNode,
    isWidgetNode,
    toFlatNodeList
} from '@creative/nodes/helpers';
import { ElementSelection } from '@creative/nodes/selection';
import {
    cloneCreativeDocument,
    convertNodeToDto,
    deserializeDesignDocument,
    deserializeFeedString,
    deserializeOneOfNodes,
    deserializeShadows,
    deserializeVersionProperties,
    stringifyDesignDocument
} from '@creative/serialization';
import {
    deserializeInlineStyledText,
    serializeInlineStyledText
} from '@creative/serialization/text-serializer';
import { serializeVersion } from '@creative/serialization/versions/version-serializer';
import { IAnimation, IAnimationKeyframe } from '@domain/animation';
import { DocumentDto } from '@domain/api/generated/sapi';
import { IDirtyContent, IDirtyTextContent, ISerializedCopy } from '@domain/copy-paste';
import { IElement } from '@domain/creativeset/element';
import { ISerializedVersion, IVersion } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { IFontFamily } from '@domain/font-families';
import { OneOfDataNodes, OneOfElementDataNodes, OneOfTextViewElements } from '@domain/nodes';
import { OneOfNodesDto, TextLikeElementDto } from '@domain/serialization';
import { IStyleIdMap } from '@domain/text';
import { IGuideline } from '@domain/workspace';
import { EventLoggerService, StoreSnapshotError } from '@studio/monitoring/events';
import { cloneDeep } from '@studio/utils/clone';
import { EditorStateService } from './editor-state.service';
import { IEditorSnapshot } from './history.service';

@Injectable()
export class CopyPasteService {
    private logger = new Logger('CopyPasteService');
    copiedSnapshot?: IEditorSnapshot;

    constructor(
        private editorStateService: EditorStateService,
        private eventLoggerService: EventLoggerService,
        private sentinelService: SentinelService
    ) {}

    copyAndSetLocalstorage(
        snapshot: IEditorSnapshot,
        brandId: string,
        fontFamilies: IFontFamily[]
    ): void {
        const elements: IElement[] = [];
        const creativeDoc = snapshot.document;
        const selectionNodes = toFlatNodeList(snapshot.selection?.nodes || []);
        for (const selectionElement of selectionNodes) {
            const element = snapshot.elements.find(({ id }) => id === selectionElement.id);
            if (element) {
                elements.push(element);
            }
        }

        const sanitizedNodes = selectionNodes.map(e => this.sanitizeElementObject(e));
        const dirtyContent: IDirtyTextContent = {} as IDirtyTextContent;
        sanitizedNodes?.forEach(el => {
            if (el.dirtyTextContent) {
                dirtyContent[el.node.id] = el.dirtyTextContent;
            }
        });

        const version = snapshot.versions.find(({ id }) => id === snapshot.selectedVersionId)!;
        const defaultVersion = snapshot.versions.find(({ id }) => id === snapshot.defaultVersionId);

        const cleanedSnapshot: ISerializedCopy = {
            brandId: brandId,
            document: stringifyDesignDocument(creativeDoc),
            version: serializeVersion(version, this.sentinelService),
            defaultVersion: defaultVersion
                ? serializeVersion(defaultVersion, this.sentinelService)
                : undefined,
            elements: elements,
            selectionNodes: sanitizedNodes?.map(el => el.node),
            dirtyTextContent: dirtyContent,
            textSelection: snapshot.textSelection,
            fontFamilies
        };

        try {
            window.localStorage.setItem('copiedSnapshot', JSON.stringify(cleanedSnapshot));
        } catch (e) {
            this.eventLoggerService.log(new StoreSnapshotError(e as Error), this.logger);
        }
    }

    private sanitizeElementObject(node: OneOfDataNodes): {
        node: OneOfNodesDto;
        dirtyTextContent?: IDirtyContent;
    } {
        if (node && isTextNode(node)) {
            const viewElement =
                this.editorStateService.renderer.getViewElementById<OneOfTextViewElements>(node.id);

            const content = node.__dirtyContent ? node.__dirtyContent : node.content;

            if (!content.style.font) {
                content.style.font = node.font;
            }

            const dirtyContentStyle = content.style
                ? content.style
                : viewElement?.__richTextRenderer?.text_m.style;

            const dirtyTextContent = {
                __dirtyContent: serializeInlineStyledText(createInlineStyledTextFromText(content)),
                __dirtyContentStyle: dirtyContentStyle
            } as IDirtyContent;

            return Object.assign({}, { node: convertNodeToDto(node), dirtyTextContent });
        } else {
            return Object.assign({}, { node: convertNodeToDto(node) });
        }
    }

    // Cannot paste if different brand and element is feed, text, button or widget.
    // due to brandspecific fonts etc..
    /**
     * Return true if the node is allowed to be pasted on other brand
     */
    copyPasteChecker(parsedCopy: ISerializedCopy, brandId: string): boolean {
        if (parsedCopy.brandId === brandId) {
            return true;
        }

        const documentDto = JSON.parse(parsedCopy.document) as DocumentDto;
        const cannotBeCopied = [ElementKind.Button, ElementKind.Text, ElementKind.Widget];

        if (parsedCopy.selectionNodes) {
            for (const nodeDto of parsedCopy.selectionNodes) {
                const node = deserializeOneOfNodes(nodeDto, documentDto);
                const nodeKind = node.kind;
                const disallowedMediaNode =
                    (nodeKind === ElementKind.Image || nodeKind === ElementKind.Video) && node.feed;
                if (cannotBeCopied.includes(nodeKind) || disallowedMediaNode) {
                    return false;
                }
            }
        }

        return true;
    }

    updateCopyFromStorage(
        parsedCopy: ISerializedCopy,
        animatorTime: number,
        guidelines: IGuideline[],
        activeGuideline: IGuideline | undefined,
        selectedVersionId: string
    ): IEditorSnapshot {
        const parsedDocument = deserializeDesignDocument(parsedCopy.document);
        const documentDto = JSON.parse(parsedCopy.document) as DocumentDto;
        const creativeDocument = cloneCreativeDocument(this.editorStateService.document, true);
        const copiedElements = parsedCopy.elements;
        const copiedVersion: ISerializedVersion = parsedCopy.version;
        const copiedDefaultVersion: ISerializedVersion | undefined = parsedCopy.defaultVersion;
        const elements: IElement[] = cloneDeep(this.editorStateService.elements);
        const versions: IVersion[] = cloneDeep(this.editorStateService.versions);
        const mappedDirtyContents = parsedCopy.dirtyTextContent;
        const selectionNodes: OneOfDataNodes[] = [];
        const activeState = undefined;
        elements.push(...copiedElements);

        for (const node of parsedCopy.selectionNodes || []) {
            const selectionNode = deserializeOneOfNodes(node, documentDto);
            const documentElement = parsedDocument.findNodeById_m(node.id, true);
            const creativeElement = copiedElements.find(({ id }) => id === node.id);

            if (documentElement) {
                if (isTextNode(documentElement) && isTextDataElement(selectionNode)) {
                    const textElementDto = node as TextLikeElementDto;
                    const font = documentElement.font;
                    documentElement.font = font;
                    const dirtyContent = mappedDirtyContents[selectionNode.id];

                    if (dirtyContent) {
                        const inlineStyledText = deserializeInlineStyledText(
                            dirtyContent?.__dirtyContent || ''
                        );
                        let dirtyContentStyle;

                        if (dirtyContent?.__dirtyContentStyle?.textColor) {
                            dirtyContentStyle = Object.assign({}, dirtyContent.__dirtyContentStyle, {
                                textColor: parseColor(dirtyContent.__dirtyContentStyle.textColor)
                            });
                        } else {
                            dirtyContentStyle = dirtyContent?.__dirtyContentStyle;
                        }

                        if (dirtyContent?.__dirtyContentStyle?.textShadows) {
                            for (const textShadow of dirtyContent.__dirtyContentStyle.textShadows) {
                                textShadow.color = parseColor(textShadow.color);
                            }
                        }

                        documentElement.__dirtyContent = createTextFromInlineStyledText(
                            inlineStyledText,
                            dirtyContentStyle
                        );
                    }

                    if (textElementDto.textShadows) {
                        for (let i = 0; i < textElementDto.textShadows.length; i++) {
                            documentElement.textShadows = selectionNode.textShadows || [];
                            documentElement.textShadows[i] = {
                                ...textElementDto.textShadows[i],
                                color: parseColor(textElementDto.textShadows[i].color)
                            };
                        }
                    }
                }

                // update dataelement with properties css, html ,js etc ..
                if (isWidgetNode(documentElement)) {
                    const copiedElement = copiedElements.find(({ id }) => id === node.id);
                    copiedElement!.properties.forEach(
                        property => (documentElement[property.name] = property.value)
                    );

                    const allVersionProperties = [
                        ...parsedCopy.version.properties,
                        ...(parsedCopy.defaultVersion?.properties || [])
                    ];

                    allVersionProperties.forEach(versionProperty => {
                        documentElement.customProperties = documentElement.customProperties.map(
                            customProperty => {
                                if (customProperty.versionPropertyId === versionProperty.id) {
                                    if (customProperty.unit === 'feed') {
                                        customProperty.value = deserializeFeedString(
                                            versionProperty.value
                                        );
                                    } else {
                                        customProperty.value = {
                                            text: JSON.parse(`${versionProperty.value}`).text
                                        };
                                    }
                                }
                                return customProperty;
                            }
                        );
                    });
                }

                if (!isGroupNodeDto(node) && !isGroupDataNode(documentElement)) {
                    if (node.fill) {
                        documentElement.fill = parseColor(node.fill);
                    }

                    if (node.shadows) {
                        documentElement.shadows = deserializeShadows(node.shadows);
                    }
                }

                if (creativeElement!.name) {
                    documentElement.name = creativeElement!.name;
                }

                selectionNodes.push(documentElement);
                if (!documentElement.__parentNode) {
                    creativeDocument.addNode_m(documentElement);
                }
            }
        }

        // find current version
        const currentVersion = versions.find(({ id }) => id === selectedVersionId);

        if (!currentVersion) {
            throw new Error('Selected version not found.');
        }

        for (const copiedElement of copiedElements) {
            for (const property of copiedElement.properties) {
                const propertyId = property.versionPropertyId;
                const versionProperties = deserializeVersionProperties(copiedVersion.properties);

                let copiedVersionProperty = versionProperties.find(({ id }) => id === propertyId);
                if (!copiedVersionProperty && copiedDefaultVersion) {
                    const defaultVersionProperties = deserializeVersionProperties(
                        copiedDefaultVersion.properties
                    );
                    copiedVersionProperty = defaultVersionProperties.find(
                        ({ id }) => id === propertyId
                    );
                }

                if (!copiedVersionProperty) {
                    continue;
                }

                if (isVersionedText(copiedVersionProperty)) {
                    // updating versionproperty with new destination creativedoc id
                    const destinationCreativeDataId = creativeDocument.id;
                    for (const style of copiedVersionProperty.value.styles) {
                        if (Object.keys(style.styleIds).length > 0) {
                            const updatedMap: IStyleIdMap = {};
                            Object.values(style.styleIds).forEach(function (value: string): void {
                                updatedMap[destinationCreativeDataId] = value;
                            });
                            style.styleIds = updatedMap;
                        }
                    }
                }

                // add copied versionproperty to current version.
                currentVersion.properties.push(copiedVersionProperty);
            }
        }

        const selection = new ElementSelection(selectionNodes);

        const snapshot: IEditorSnapshot = {
            copyType: undefined,
            elements: elements,
            document: parsedDocument,
            selection: selection,
            textSelection: parsedCopy.textSelection,
            versions: versions,
            isVersionable: false,
            selectedVersionId: selectedVersionId,
            playhead: animatorTime,
            activeGuideline: activeGuideline
                ? JSON.parse(JSON.stringify(activeGuideline))
                : activeGuideline,
            guidelines: JSON.parse(JSON.stringify(guidelines)),
            activeState,
            keyframeSelection: undefined,
            expandedAnimationElements: [],
            textStyleCursor: undefined,
            defaultVersionId: undefined,
            latestSelectionType: undefined,
            fontFamilies: parsedCopy.fontFamilies
        };

        this.copiedSnapshot = Object.freeze(snapshot);

        return snapshot;
    }

    pasteKeyframes(
        snapshot: IEditorSnapshot,
        targetDataElements: ReadonlyArray<OneOfElementDataNodes>,
        time: number
    ): IAnimationKeyframe[] {
        const { keyframes, states } = cloneDeep(snapshot.keyframeSelection!);

        return targetDataElements.reduce((memo: IAnimationKeyframe[], element) => {
            let animation: IAnimation;

            if (!hasAnimationsOfType(element, 'keyframe')) {
                animation = createAnimation(element);
                element.animations.push(animation);
            } else {
                animation = getAnimationsOfType(element, 'keyframe')[0];
            }

            const timeRelativeToElement = time - element.time;

            const updatedKeyframes = keyframes.reduce(
                (keyframesMemo: IAnimationKeyframe[], kf, index) => {
                    const state = states.find(({ id }) => id === kf.stateId) || {};
                    const newTime =
                        index > 0
                            ? timeRelativeToElement + getAbsoluteTimeBetween(keyframes[0], kf)
                            : timeRelativeToElement;

                    const keyframe = addKeyframeWithState(element, animation, {
                        ...state,
                        ...kf,
                        time: newTime
                    })?.keyframe;

                    if (keyframe) {
                        /**
                         * If the keyframe time is adjusted and is less than the given time then
                         * we remove it as it means that it was adjusted and the copied
                         * structure is no longer the same, most likely due to element overflow.
                         */
                        if (keyframe.time < newTime) {
                            removeKeyframes(element, [keyframe]);
                            return [...keyframesMemo];
                        }

                        return [...keyframesMemo, keyframe];
                    }

                    return keyframesMemo;
                },
                []
            );

            return [...memo, ...updatedKeyframes];
        }, []);
    }
}
