import { Color } from '@creative/color';
import {
    BorderDto,
    FilterDto,
    ImageAssetDataDto,
    ImageSettingsDto,
    MaskDto,
    PaddingDto,
    RadiusDto,
    ShadowDto,
    TextShadowDto,
    VideoAssetDataDto,
    VideoSettingsDto
} from '@domain/api/generated/sapi';
import { IImageElementAsset, IVideoElementAsset } from '@domain/creativeset/element-asset';
import { IImageSettings } from '@domain/image';
import { Masking } from '@domain/mask';
import { NumberAndStringNodeProperties } from '@domain/nodes';
import {
    BorderStyle,
    IBorder,
    IFilterMap,
    IPadding,
    IRadius,
    IShadow,
    ITextShadow,
    RadiusType
} from '@domain/style';
import { IVideoSettings, PlaybackRate, VideoSizeMode } from '@domain/video';
import { cloneDeep } from '@studio/utils/clone';
import { handleError } from '@studio/utils/errors';
import { decimal, isNumber } from '@studio/utils/utils';
import { parseColor, toRGBA } from '../color.utils';
import { DEFAULT_VIDEO_SETTINGS } from '../elements/video/video';
import { serializeNumber } from './serialization.utils';

const allowedFormulaProperties = ['x', 'y', 'rotationX', 'rotationY', 'rotationZ'];

const highPrecisionProperties = ['rotationX', 'rotationY', 'rotationZ'];

export function isFormulaAllowedProperty(property: NumberAndStringNodeProperties): boolean {
    return allowedFormulaProperties.some(p => p === property);
}

export function isHighPrecisionProperty(property: NumberAndStringNodeProperties): boolean {
    return highPrecisionProperties.some(p => p === property);
}

export function convertBorderToDto(border?: IBorder): BorderDto | undefined {
    if (!border) {
        return undefined;
    }

    return {
        thickness: decimal(border.thickness),
        style: border.style,
        color: border.color.toString()
    };
}

export function convertPaddingToDto(padding: IPadding): PaddingDto {
    return {
        top: decimal(padding.top),
        left: decimal(padding.left),
        right: decimal(padding.right),
        bottom: decimal(padding.bottom)
    };
}

export function convertShadowsToDto(value: IShadow[]): ShadowDto[] {
    return value?.map(s => {
        let color: string;
        if (s.color instanceof Color) {
            color = toRGBA(s.color);
        } else {
            handleError('Shadow color is not an instance of Color', {
                contexts: { color: s.color }
            });
            color = 'rgba(0,0,0,1)'; // default to black if we somehow do not have a color
        }
        return {
            offsetX: decimal(s.offsetX),
            offsetY: decimal(s.offsetY),
            blur: decimal(s.blur),
            spread: decimal(s.spread),
            color
        };
    });
}

export function convertTextShadowsToDtos(value: ITextShadow[]): TextShadowDto[] {
    return value?.map(s => {
        let color: string;
        if (s.color instanceof Color) {
            color = toRGBA(s.color);
        } else {
            handleError('Text shadow color is not an instance of Color', {
                contexts: { color: s.color }
            });
            color = 'rgba(0,0,0,1)'; // default to black if we somehow do not have a color
        }

        return {
            offsetX: decimal(s.offsetX) || 0,
            offsetY: decimal(s.offsetY) || 0,
            blur: decimal(s.blur) || 0,
            color
        };
    });
}

export function convertToNewRadius(value: number): IRadius {
    return {
        type: RadiusType.Joint,
        topLeft: value,
        topRight: value,
        bottomRight: value,
        bottomLeft: value
    };
}

export function convertImageSettingsToDto(imageSettings: IImageSettings): ImageSettingsDto {
    return {
        ...imageSettings,
        x: serializeNumber(imageSettings.x, true),
        y: serializeNumber(imageSettings.y, true)
    };
}

export function convertFiltersToDto(value: IFilterMap): FilterDto {
    return {
        ...value
    };
}

export function convertRadiusToDto(value: IRadius): RadiusDto {
    return {
        type: value.type || RadiusType.Joint,
        topLeft: value.topLeft || 0,
        topRight: value.topRight || 0,
        bottomRight: value.bottomRight || 0,
        bottomLeft: value.bottomLeft || 0
    };
}

export function convertImageAssetToDto(
    imageAsset: IImageElementAsset | undefined
): ImageAssetDataDto | undefined {
    if (!imageAsset) {
        return;
    }

    return {
        id: imageAsset.id,
        src: imageAsset.url,
        name: imageAsset.name,
        width: Math.round(imageAsset.width),
        height: Math.round(imageAsset.height)
    };
}

export function convertVideoAssetToDto(
    videoAsset: IVideoElementAsset | undefined
): VideoAssetDataDto | undefined {
    if (!videoAsset) {
        return;
    }

    return {
        id: videoAsset.id,
        name: videoAsset.name,
        url: videoAsset.url,
        width: Math.round(videoAsset.width),
        height: Math.round(videoAsset.height),
        fileSize: videoAsset.fileSize > 1 ? videoAsset.fileSize : 1
    };
}

export function convertVideoSettingsToDto(videoSettings: IVideoSettings): VideoSettingsDto {
    const playbackButton = videoSettings.playbackButton;
    return {
        ...videoSettings,
        volume: videoSettings.volume || 0,
        playbackButton: {
            enabled: playbackButton.enabled,
            size: playbackButton.size,
            color: videoSettings.playbackButton.color.toString()
        }
    };
}

export function convertColorToDto(color: Color): string {
    return color.toString();
}

export function deserializeVideoSettings(videoSettings: VideoSettingsDto): IVideoSettings {
    // why ever this is a string
    if (typeof videoSettings === 'string') {
        videoSettings = JSON.parse(videoSettings);
    }
    const playbackButton = videoSettings.playbackButton;
    const streaming = videoSettings.streaming || DEFAULT_VIDEO_SETTINGS.streaming;
    const optimization = videoSettings.optimization || DEFAULT_VIDEO_SETTINGS.optimization;
    return {
        ...videoSettings,
        streaming,
        optimization,
        playbackRate: videoSettings.playbackRate as PlaybackRate,
        sizeMode: videoSettings.sizeMode as VideoSizeMode,
        playbackButton: {
            enabled: playbackButton.enabled,
            size: playbackButton.size,
            color: parseColor(videoSettings.playbackButton.color)
        }
    };
}

export function getBorderStyle(borderDto: BorderDto): BorderStyle {
    const style: BorderStyle = borderDto.style;

    // Fix corrupt data in DB (seen cases of "undefined")
    if (style === 'dashed' || style === 'solid' || style === 'dotted') {
        return style;
    }
    handleError('Invalid border style', { contexts: { border: borderDto } });
    return 'solid';
}

export function deserializeRadius(radius: RadiusDto | number): IRadius {
    if (isNumber(radius)) {
        return convertToNewRadius(radius);
    }

    return {
        ...radius,
        type: radius.type as RadiusType
    };
}

export function deserializeMasking(masking: MaskDto): Masking {
    return {
        isMask: masking.isMask,
        elementId: masking.elementId
    };
}

export function deserializeBorder(borderDto?: BorderDto): IBorder | undefined {
    if (!borderDto) {
        return undefined;
    }

    const style = getBorderStyle(borderDto);

    return {
        thickness: borderDto.thickness,
        style, // Both might exist in library / documents
        color: parseColor(borderDto.color)
    };
}

export function deserializeShadows(shadowDto?: ShadowDto[]): IShadow[] | undefined {
    if (!shadowDto) {
        return;
    }

    return shadowDto.map(s => {
        return {
            offsetX: s.offsetX,
            offsetY: s.offsetY,
            blur: s.blur,
            spread: s.spread,
            color: parseColor(s.color)
        } satisfies IShadow;
    });
}

export function deserializeFilter(filterDto: FilterDto = {}): IFilterMap {
    return cloneDeep(filterDto);
}

export function deserializeTextShadows(textShadowDto?: TextShadowDto[]): ITextShadow[] | undefined {
    if (!textShadowDto) {
        return;
    }

    return textShadowDto.map(s => {
        return {
            offsetX: s.offsetX,
            offsetY: s.offsetY,
            blur: s.blur,
            color: parseColor(s.color)
        } satisfies ITextShadow;
    });
}
