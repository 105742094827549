import { NgModule } from '@angular/core';
import { CreativeConverterStoreModule } from './pages/manage-view/creative-converter/state/creative-converter.module';
import { DuplicateCreativesStoreModule } from './pages/manage-view/duplicate-creatives/state/duplicate-creatives.store.module';
import { TranslationPageStoreModule } from './pages/translation-page/state/translation-page.store.module';
import { GenAIStoreModule } from './shared/ai-studio/state/gen-ai.store.module';
import { AnimationControlStoreModule } from './shared/animation-control/state/animation-control.store.module';
import { BrandStoreModule } from './shared/brand/state/brand.store.module';
import { CreativesStoreModule } from './shared/creatives/state/creatives.store.module';
import { CreativesetShowcaseStoreModule } from './shared/creativeset-showcase/state/creativeset-showcase.store.module';
import { CreativesetStoreModule } from './shared/creativeset/state/creativeset.store.module';
import { DisplayCampaignStoreModule } from './shared/display-campaign/state/display-campaign.store.module';
import { FiltersStoreModule } from './shared/filters/state/filters.store.module';
import { FontFamiliesStoreModule } from './shared/font-families/state/font-families.store.module';
import { MediaLibraryStoreModule } from './shared/media-library/state/media-library.store.module';
import { UserSettingsStoreModule } from './shared/user-settings/state/user-settings.store.module';
import { UserStoreModule } from './shared/user/state/user.store.module';
import { VersionsStoreModule } from './shared/versions/state/versions.store.module';

@NgModule({
    imports: [
        AnimationControlStoreModule,
        BrandStoreModule,
        CreativeConverterStoreModule,
        CreativesStoreModule,
        CreativesetShowcaseStoreModule,
        CreativesetStoreModule,
        DisplayCampaignStoreModule,
        DuplicateCreativesStoreModule,
        FiltersStoreModule,
        FontFamiliesStoreModule,
        GenAIStoreModule,
        MediaLibraryStoreModule,
        TranslationPageStoreModule,
        UserSettingsStoreModule,
        UserStoreModule,
        VersionsStoreModule
    ]
})
export class StoresModule {}
