import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CreativesetShowcaseService } from '../../shared/creativeset-showcase/state/creativeset-showcase.service';
import { EnvironmentService } from '../../shared/services/environment.service';
import { StudioHubService } from '../../shared/services/studio-hub.service';

/**
 Intercepts HTTPClient requests and adds the authorization header,
 as well as the signalR connection header.

 This works for all libraries that use the Angular HttpClient. The apollo client for collections is not included.
 **/
@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    private auth0HttpInterceptor = inject(AuthHttpInterceptor);
    private environmentService = inject(EnvironmentService);
    private studioHubService = inject(StudioHubService);
    private creativesetShowcaseService = inject(CreativesetShowcaseService);

    intercept(request: HttpRequest<HttpHeaders>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // E2E TESTS
        if (environment.stage === 'test') {
            return next.handle(request);
        }
        // SHOWCASE
        if (this.environmentService.inShowcaseMode) {
            return this.creativesetShowcaseService.showcaseToken$.pipe(
                switchMap(showcaseToken => {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${showcaseToken}`
                        }
                    });

                    return next.handle(request);
                })
            );
        }

        // REGULAR
        const connectionId = this.studioHubService.connectionId;
        if (connectionId) {
            request = request.clone({
                setHeaders: {
                    'X-BF-ConnectionId': connectionId,
                    'BF-Studio-SignalR-ConnectionId': connectionId
                }
            });
        }

        const urlChunks = location.pathname.split('/') ?? [];
        const bfHeaders = {
            'BF-Brand-Id': urlChunks[2] ?? '',
            'BF-CreativeSet-Id': urlChunks[4] ?? ''
        };

        request = request.clone({
            setHeaders: { ...bfHeaders }
        });

        return this.auth0HttpInterceptor.intercept(request, next);
    }
}
