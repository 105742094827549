import { ImageLibraryAsset } from '@domain/brand/brand-library';
import { IImageElementAsset } from '@domain/creativeset/element-asset';
import { IAsyncState } from '@domain/store/async';
import { Action, createReducer, on } from '@ngrx/store';
import { AIStudioSnapshot, GenAIOption, SaveType } from '@studio/domain/components/ai-studio.types';
import {
    BackgroundRemovalSettings,
    BrushSettings,
    OutpaintSettings
} from '@studio/domain/components/gen-ai';
import { clamp } from '@studio/utils/utils';
import {
    addSnapshotToHistory,
    createSnapshot,
    getSnapshotIndex,
    removeSnapshotsByType,
    revokeObjectURLs
} from '../utils/state.utils';
import * as genAIActions from './gen-ai.actions';

export const GEN_AI_FEATURE_KEY = 'genAi';

export interface GenAIPartialState {
    readonly [GEN_AI_FEATURE_KEY]: GenAIState;
}

export interface GenAIState extends IAsyncState {
    openedElementId: string | undefined;

    assetName: string | undefined;

    currentImage: string | undefined;
    previousImage: string | undefined;

    currentImageAsset?: ImageLibraryAsset | IImageElementAsset;

    generativeFillBrushSettings: BrushSettings;
    generativeFillImageMaskLink?: string;

    eraseBrushSettings: BrushSettings;
    eraseImageMaskLink?: string;

    currentOptionSelected?: GenAIOption;

    history: {
        undoStack: AIStudioSnapshot[];
        redoStack: AIStudioSnapshot[];
        urlsToClean: string[];
    };

    zoomLevel: number;

    backgroundRemovalSettings: BackgroundRemovalSettings;

    outpaintSettings: OutpaintSettings;

    currentRequest: {
        outpaint: boolean;
        inpaint: boolean;
        erase: boolean;
        searchAndReplace: boolean;
        generation: boolean;
        backgroundRemoval: boolean;
        openInAIStudio: boolean;
        saveToBrandLibrary: boolean;
        brandLibrarySave: boolean;
        brandLibraryDuplicate: boolean;
        onCanvasSaveAndReplace: boolean;
        onCanvasSaveAsDuplicate: boolean;
    };

    saveOnCanvasPayload?: {
        saveType: SaveType;
        imageAsset: IImageElementAsset;
        replaceInAllDesigns?: boolean;
    };
}

const initialBrushSettings: BrushSettings = {
    size: 40,
    history: []
};

const initialBlackgroundRemovalSettings: BackgroundRemovalSettings = {
    backgroundRemoved: false,
    showOriginalBackground: true
};

const initialOutpaintSettings: OutpaintSettings = {
    left: 0,
    right: 0,
    up: 0,
    down: 0
};

export const initialState: GenAIState = {
    loaded: true,
    error: undefined,

    openedElementId: undefined,

    assetName: undefined,

    currentImageAsset: undefined,

    currentImage: undefined,
    previousImage: undefined,

    generativeFillBrushSettings: initialBrushSettings,
    generativeFillImageMaskLink: undefined,

    eraseBrushSettings: initialBrushSettings,
    eraseImageMaskLink: undefined,

    currentOptionSelected: GenAIOption.GenerativeFill,
    history: {
        undoStack: [],
        redoStack: [],
        urlsToClean: []
    },
    zoomLevel: 100,
    backgroundRemovalSettings: initialBlackgroundRemovalSettings,
    outpaintSettings: initialOutpaintSettings,
    currentRequest: {
        generation: false,
        inpaint: false,
        erase: false,
        searchAndReplace: false,
        outpaint: false,
        backgroundRemoval: false,
        openInAIStudio: false,
        saveToBrandLibrary: false,

        brandLibrarySave: false,
        brandLibraryDuplicate: false,
        onCanvasSaveAndReplace: false,
        onCanvasSaveAsDuplicate: false
    },
    saveOnCanvasPayload: undefined
};

export const genAIReducer = createReducer(
    initialState,

    on(
        genAIActions.generateImage,
        genAIActions.inpaint,
        genAIActions.erase,
        genAIActions.searchAndReplace,
        genAIActions.outpaint,
        genAIActions.removeBackground,
        genAIActions.saveGeneratedImageToBrandLibrary,
        genAIActions.openInAiStudio,
        genAIActions.saveToBrandLibrary,
        state => {
            return {
                ...state,
                loaded: false,
                error: undefined
            };
        }
    ),

    on(genAIActions.searchAndReplace, state => {
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                searchAndReplace: true
            }
        };
    }),

    on(genAIActions.outpaint, state => {
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                outpaint: true
            }
        };
    }),

    on(genAIActions.generateImage, state => {
        return {
            ...state,
            generating: true,
            currentRequest: {
                ...state.currentRequest,
                generation: true
            }
        };
    }),

    on(genAIActions.inpaint, state => {
        return {
            ...state,
            generating: true,
            currentRequest: {
                ...state.currentRequest,
                inpaint: true
            }
        };
    }),

    on(genAIActions.erase, state => {
        return {
            ...state,
            generating: true,
            currentRequest: {
                ...state.currentRequest,
                erase: true
            }
        };
    }),

    on(genAIActions.removeBackground, state => {
        return {
            ...state,
            removingBackground: true,
            currentRequest: {
                ...state.currentRequest,
                backgroundRemoval: true
            }
        };
    }),

    on(genAIActions.openInAiStudio, state => {
        return {
            ...state,
            openingInAiStudio: true,
            currentRequest: {
                ...state.currentRequest,
                openInAIStudio: true
            }
        };
    }),

    on(genAIActions.saveGeneratedImageToBrandLibrary, state => {
        return {
            ...state,
            savingToBrandLibrary: true,
            currentRequest: {
                ...state.currentRequest,
                saveToBrandLibrary: true
            }
        };
    }),

    on(genAIActions.saveToBrandLibrary, (state, { replace }) => {
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                brandLibrarySave: replace,
                brandLibraryDuplicate: !replace
            }
        };
    }),

    on(genAIActions.saveOnCanvas, (state, { saveType }) => {
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                onCanvasSaveAndReplace: saveType === SaveType.Replace,
                onCanvasSaveAsDuplicate: saveType === SaveType.Duplicate
            }
        };
    }),

    on(genAIActions.saveOnCanvasSuccess, (state, { imageAsset, replaceInAllDesigns, saveType }) => {
        return {
            ...state,
            currentRequest: {
                ...state.currentRequest,
                onCanvasSaveAndReplace: false,
                onCanvasSaveAsDuplicate: false
            },
            saveOnCanvasPayload: {
                imageAsset,
                replaceInAllDesigns,
                saveType
            }
        };
    }),

    on(
        genAIActions.generateImageFailure,
        genAIActions.inpaintFailure,
        genAIActions.eraseFailure,
        genAIActions.searchAndReplaceFailure,
        genAIActions.outpaintFailure,
        genAIActions.removeBackgroundFailure,
        genAIActions.saveGeneratedImageToBrandLibraryFailure,
        genAIActions.openInAiStudioFailure,
        genAIActions.saveToBrandLibraryFailure,
        (state, { error }) => {
            return {
                ...state,
                loaded: true,
                error: error,
                generating: false,
                removingBackground: false,
                savingToBrandLibrary: false,
                openingInAiStudio: false,
                currentRequest: {
                    ...initialState.currentRequest
                }
            };
        }
    ),
    on(
        genAIActions.generateImageSuccess,
        genAIActions.inpaintSuccess,
        genAIActions.eraseSuccess,
        genAIActions.searchAndReplaceSuccess,
        genAIActions.outpaintSuccess,
        genAIActions.removeBackgroundSuccess,
        genAIActions.saveGeneratedImageToBrandLibrarySuccess,
        genAIActions.openInAiStudioSuccess,
        genAIActions.saveToBrandLibrarySuccess,
        state => {
            return {
                ...state,
                loaded: true,
                error: undefined,
                currentRequest: {
                    ...initialState.currentRequest
                }
            };
        }
    ),

    on(
        genAIActions.generateImageSuccess,
        genAIActions.inpaintSuccess,
        genAIActions.eraseSuccess,
        genAIActions.searchAndReplaceSuccess,
        genAIActions.removeBackgroundSuccess,
        genAIActions.outpaintSuccess,
        (state, { result, type }) => {
            const snapshot = createSnapshot(state, 'request');
            const newHistory = addSnapshotToHistory(state.history, snapshot);
            const backgroundRemoved = type === genAIActions.removeBackgroundSuccess.type;

            return {
                ...state,
                currentImage: result,
                previousImage: state.currentImage,
                generativeFillImageMaskLink: undefined,
                generativeFillBrushSettings: {
                    ...state.generativeFillBrushSettings,
                    history: []
                },
                eraseBrushSettings: {
                    ...state.eraseBrushSettings,
                    history: []
                },
                outpaintSettings: {
                    ...initialState.outpaintSettings
                },
                backgroundRemovalSettings: {
                    ...state.backgroundRemovalSettings,
                    backgroundRemoved
                },
                history: newHistory
            };
        }
    ),

    on(genAIActions.zoomIn, state => {
        const newZoomLevel = Math.min(3200, state.zoomLevel * 2);
        return {
            ...state,
            zoomLevel: newZoomLevel
        };
    }),
    on(genAIActions.zoomOut, state => {
        const newZoomLevel = Math.max(1, state.zoomLevel / 2);
        return {
            ...state,
            zoomLevel: newZoomLevel
        };
    }),
    on(genAIActions.setZoomLevel, (state, { zoomLevel }) => {
        return {
            ...state,
            zoomLevel: clamp(zoomLevel, 1, 3200)
        };
    }),

    on(genAIActions.openInAiStudioSuccess, (state, { element, asset, assetName }) => {
        return {
            ...state,
            openedElement: element,
            currentImageAsset: asset,
            history: initialState.history,
            assetName
        };
    }),

    on(genAIActions.changeSelectedOption, (state, { option }) => {
        return {
            ...state,
            currentOptionSelected: option
        };
    }),

    on(genAIActions.setGenerativeFillBrushSize, (state, { brushSize }) => {
        return {
            ...state,
            generativeFillBrushSettings: {
                ...state.generativeFillBrushSettings,
                size: brushSize
            }
        };
    }),

    on(genAIActions.setGenerativeFillBrushHistory, (state, { brushHistory }) => {
        const snapshot: AIStudioSnapshot = {
            type: 'generativeFillBrush',
            generativeFillBrushSettings: { history: state.generativeFillBrushSettings.history }
        };
        const newHistory = addSnapshotToHistory(state.history, snapshot);

        return {
            ...state,
            generativeFillBrushSettings: {
                ...state.generativeFillBrushSettings,
                history: brushHistory
            },
            history: newHistory
        };
    }),

    on(genAIActions.setGenerativeFillImageMask, (state, { generativeFillImageMaskLink }) => {
        return {
            ...state,
            generativeFillImageMaskLink
        };
    }),

    on(genAIActions.setEraseBrushSize, (state, { brushSize }) => {
        return {
            ...state,
            eraseBrushSettings: {
                ...state.eraseBrushSettings,
                size: brushSize
            }
        };
    }),

    on(genAIActions.setEraseBrushHistory, (state, { brushHistory }) => {
        const snapshot: AIStudioSnapshot = {
            type: 'eraseBrush',
            eraseBrushSettings: { history: state.eraseBrushSettings.history }
        };
        const newHistory = addSnapshotToHistory(state.history, snapshot);

        return {
            ...state,
            eraseBrushSettings: {
                ...state.eraseBrushSettings,
                history: brushHistory
            },
            history: newHistory
        };
    }),

    on(genAIActions.setEraseImageMask, (state, { eraseImageMaskLink }) => {
        return {
            ...state,
            eraseImageMaskLink
        };
    }),

    on(genAIActions.toggleOriginalBackground, (state, { show }) => {
        return {
            ...state,
            backgroundRemovalSettings: {
                ...state.backgroundRemovalSettings,
                showOriginalBackground: show
            }
        };
    }),

    on(genAIActions.openElementInAIStudio, (state, { elementId, elementName, asset }) => {
        return {
            ...state,
            openedAsset: asset,
            openedElementId: elementId,
            currentImageAsset: asset,
            assetName: elementName ?? asset?.name
        };
    }),

    on(genAIActions.setOutpaintSettigns, (state, { options }) => {
        return {
            ...state,
            outpaintSettings: options
        };
    }),

    on(genAIActions.resetState, state => {
        revokeObjectURLs(state.history);
        return {
            ...initialState
        };
    }),

    on(genAIActions.setImageAsset, (state, { imageAsset, linkToBase64 }) => {
        const snapshot: AIStudioSnapshot = {
            type: 'initial',
            currentImage: linkToBase64,
            previousImage: undefined
        };
        const newHistory = addSnapshotToHistory(state.history, snapshot);

        return {
            ...state,
            previousImage: undefined,
            currentImageAsset: imageAsset,
            currentImage: linkToBase64,
            history: newHistory
        };
    }),

    on(genAIActions.updateAssetName, (state, { newName }) => {
        return {
            ...state,
            assetName: newName
        };
    }),

    on(genAIActions.redo, state => {
        const currentSnapshotIndex = getSnapshotIndex(
            state.history.redoStack,
            state.currentOptionSelected
        );
        const currentSnapshot = state.history.redoStack.at(currentSnapshotIndex);

        if (state.currentOptionSelected === GenAIOption.GenerativeFill && currentSnapshot) {
            currentSnapshot.eraseBrushSettings = { history: [] };
        }
        if (state.currentOptionSelected === GenAIOption.Erase && currentSnapshot) {
            currentSnapshot.generativeFillBrushSettings = { history: [] };
        }
        let newRedoStack = state.history.redoStack.slice(0, currentSnapshotIndex);
        const newSnapshot = createSnapshot(state, currentSnapshot?.type ?? 'request');
        const newUndoStack = [...state.history.undoStack, newSnapshot];

        if (state.currentOptionSelected === GenAIOption.GenerativeFill) {
            newRedoStack = removeSnapshotsByType(newRedoStack, 'eraseBrush');
        } else if (state.currentOptionSelected === GenAIOption.Erase) {
            newRedoStack = removeSnapshotsByType(newRedoStack, 'generativeFillBrush');
        }

        return {
            ...state,
            history: {
                ...state.history,
                redoStack: newRedoStack,
                undoStack: newUndoStack
            },
            ...currentSnapshot,
            generativeFillBrushSettings: {
                ...state.generativeFillBrushSettings,
                ...currentSnapshot?.generativeFillBrushSettings
            },
            eraseBrushSettings: {
                ...state.eraseBrushSettings,
                ...currentSnapshot?.eraseBrushSettings
            },
            backgroundRemovalSettings: {
                ...state.backgroundRemovalSettings,
                ...currentSnapshot?.backgroundRemovalSettings
            }
        };
    }),
    on(genAIActions.undo, state => {
        const currentSnapshotIndex = getSnapshotIndex(
            state.history.undoStack,
            state.currentOptionSelected
        );
        const currentSnapshot = state.history.undoStack.at(currentSnapshotIndex);

        if (state.currentOptionSelected === GenAIOption.GenerativeFill && currentSnapshot) {
            currentSnapshot.eraseBrushSettings = { history: [] };
        }
        if (state.currentOptionSelected === GenAIOption.Erase && currentSnapshot) {
            currentSnapshot.generativeFillBrushSettings = { history: [] };
        }

        let newUndoStack = state.history.undoStack.slice(0, currentSnapshotIndex);

        if (state.currentOptionSelected === GenAIOption.GenerativeFill) {
            newUndoStack = removeSnapshotsByType(newUndoStack, 'eraseBrush');
        } else if (state.currentOptionSelected === GenAIOption.Erase) {
            newUndoStack = removeSnapshotsByType(newUndoStack, 'generativeFillBrush');
        }

        const newSnapshot = createSnapshot(state, currentSnapshot?.type ?? 'request');
        const newRedoStack = [...state.history.redoStack, newSnapshot];

        return {
            ...state,
            history: {
                ...state.history,
                redoStack: newRedoStack,
                undoStack: newUndoStack
            },
            ...currentSnapshot,
            generativeFillBrushSettings: {
                ...state.generativeFillBrushSettings,
                ...currentSnapshot?.generativeFillBrushSettings
            },
            eraseBrushSettings: {
                ...state.eraseBrushSettings,
                ...currentSnapshot?.eraseBrushSettings
            },
            backgroundRemovalSettings: {
                ...state.backgroundRemovalSettings,
                ...currentSnapshot?.backgroundRemovalSettings
            }
        };
    }),

    on(genAIActions.updateAssetName, (state, { newName }) => {
        return {
            ...state,
            assetName: newName
        };
    })
);

export function reducer(state: GenAIState | undefined, action: Action): GenAIState {
    return genAIReducer(state, action);
}
