import { createReducer, on } from '@ngrx/store';
import { LayerItem, PSDErrorType } from '@studio/domain/components/psd-import/psd';
import {
    fixFont,
    setCollapseOnLayerAndChildren,
    setSelectionOnLayerAndChildren,
    setVisibilityOnLayerAndChildren
} from '../utils/psd-layer.utils';
import * as CreativeConverterActions from './creative-converter.actions';

export const CREATIVE_CONVERTER_FEATURE_KEY = 'creativeConverter';

export interface CreativeConverterPartialState {
    readonly [CREATIVE_CONVERTER_FEATURE_KEY]: CreativeConverterState;
}

export interface CreativeConverterState {
    psdLayers: LayerItem[];

    fontToFix?: string;
    applyToAll?: boolean;
}

export const initialState: CreativeConverterState = {
    psdLayers: [],
    fontToFix: undefined,
    applyToAll: undefined
};

export const reducer = createReducer(
    initialState,

    on(CreativeConverterActions.resetState, () => {
        return { ...initialState };
    }),

    on(CreativeConverterActions.setLayers, (state, { layers }) => {
        return {
            ...state,
            psdLayers: layers
        };
    }),
    on(CreativeConverterActions.setFontToFix, (state, { fontToFix, applyToAll }) => {
        return {
            ...state,
            fontToFix,
            applyToAll
        };
    }),
    on(CreativeConverterActions.resetFontToFix, state => {
        return {
            ...state,
            fontToFix: undefined,
            applyToAll: undefined
        };
    }),
    on(CreativeConverterActions.setApplyToAll, (state, { applyToAll }) => {
        return {
            ...state,
            applyToAll
        };
    }),
    on(CreativeConverterActions.useAlternativeFont, (state, { fontToFix, applyToAll }) => {
        const newPsdLayers: LayerItem[] = [];
        for (const layer of state.psdLayers) {
            if (layer.error?.type !== PSDErrorType.OriginalFontNotFound || layer.error.isFixed) {
                newPsdLayers.push(layer);
                continue;
            }

            if (applyToAll || layer.error.data?.missingFontName === fontToFix) {
                newPsdLayers.push({
                    ...layer,
                    error: {
                        ...layer.error,
                        isFixed: true
                    }
                });
                continue;
            }

            newPsdLayers.push(layer);
        }

        return {
            ...state,
            psdLayers: newPsdLayers
        };
    }),
    on(CreativeConverterActions.toggleVisibility, (state, { layer }) => {
        const newLayers = setVisibilityOnLayerAndChildren(state.psdLayers, layer, !layer.hidden);
        return {
            ...state,
            psdLayers: newLayers
        };
    }),
    on(CreativeConverterActions.toggleSelection, (state, { layer }) => {
        const newLayers = setSelectionOnLayerAndChildren(state.psdLayers, layer, !layer.selected);
        return {
            ...state,
            psdLayers: newLayers
        };
    }),
    on(CreativeConverterActions.toggleAllSelection, (state, { newSelectedValue }) => {
        const newLayers = setSelectionOnLayerAndChildren(state.psdLayers, undefined, newSelectedValue);
        return {
            ...state,
            psdLayers: newLayers
        };
    }),
    on(CreativeConverterActions.toggleCollapse, (state, { layer }) => {
        const newLayers = setCollapseOnLayerAndChildren(state.psdLayers, layer, !layer.collapsed);
        return {
            ...state,
            psdLayers: newLayers
        };
    }),

    on(CreativeConverterActions.fixFontWith, (state, { fontFamily, fontStyle }) => {
        const { fontToFix, applyToAll } = state;
        if (!fontToFix) {
            return { ...state };
        }
        const newLayers = fixFont(state.psdLayers, fontFamily, fontStyle, fontToFix, !!applyToAll);
        return {
            ...state,
            fontToFix: undefined,
            applyToAll: undefined,
            psdLayers: newLayers
        };
    })
);
