import { Injectable, OnDestroy } from '@angular/core';
import { Logger } from '@bannerflow/sentinel-logger';
import { isFeedpathProperty } from '@creative/elements/feed/feeds.utils';
import {
    getWidgetCodeOfElement,
    hasWidgetReference,
    isWidgetCodeProperty,
    mapElementToCustomWidgetProperties
} from '@creative/elements/widget/utils';
import { WidgetCode } from '@creative/elements/widget/widget-renderer.header';
import { getActiveFontsOnThisBrand } from '@creative/font-families.utils';
import { canCreateGroup } from '@creative/nodes/data-node.utils';
import {
    isAnyBannerflowLibraryWidget,
    isBFLWidgetElementInstance,
    isGroupDataNode,
    isWidgetNode,
    ratioLockSvgElement,
    toFlatNodeList
} from '@creative/nodes/helpers';
import { deserializePropertyValue } from '@creative/serialization';
import {
    IElementCreationOptions,
    IInitializedNode,
    INewElementProperty,
    ITextCreationOptions,
    IWidgetCreationOptions
} from '@domain/creativeset/element';
import { AssetReference } from '@domain/creativeset/element-asset';
import { CreativeSize } from '@domain/creativeset/size';
import { IVersion } from '@domain/creativeset/version';
import { IBoundingBox, IBounds } from '@domain/dimension';
import { ElementKind } from '@domain/elements';
import { FeededReference, IFeed } from '@domain/feed';
import { IFontStyle } from '@domain/font';
import { IFontFamily } from '@domain/font-families';
import {
    IButtonElementDataNode,
    ICreativeDataNode,
    IEllipseElementDataNode,
    IImageElementDataNode,
    INewBaseNode,
    IRectangleElementDataNode,
    ITextDataNode,
    ITextElementDataNode,
    IVideoElementDataNode,
    OneOfDataNodes,
    OneOfElementDataNodes
} from '@domain/nodes';
import { unitToVersionedUnitMap } from '@domain/property';
import { IWidgetElementDataNode, IWidgetElementProperty } from '@domain/widget';
import { createElementProperty, getWidgetContentUrlOfElement } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';
import { clamp, omit } from '@studio/utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { CreativesetDataService } from '../../../shared/creativeset/creativeset.data.service';
import { FontFamiliesService } from '../../../shared/font-families/state/font-families.service';
import { BrandLibraryDataService } from '../../../shared/media-library/brand-library.data.service';
import { FeatureService } from '../../../shared/services/feature/feature.service';
import { VersionsService } from '../../../shared/versions/state/versions.service';
import { NodeCreatorService } from './data-node-creator.service';
import { EditorStateService } from './editor-state.service';

@Injectable()
export class ElementCreatorService implements OnDestroy {
    private _create$ = new Subject<IInitializedNode>();
    create$ = this._create$.asObservable();
    private isCopyingNode = false;
    private isWidgetUpdateFromLibrary: boolean;
    private fontFamilies: IFontFamily[] = [];
    private creativeDocument: ICreativeDataNode;
    private logger = new Logger('Workspace');
    private unsubscribe$ = new Subject<void>();
    private selectedVersion: IVersion;
    private versions: IVersion[];

    constructor(
        private nodeCreator: NodeCreatorService,
        private creativesetDataService: CreativesetDataService,
        private editorStateService: EditorStateService,
        private fontFamiliesService: FontFamiliesService,
        private versionsService: VersionsService,
        private brandLibraryDataService: BrandLibraryDataService,
        private featureService: FeatureService
    ) {
        this.versionsService.versions$.pipe(takeUntil(this.unsubscribe$)).subscribe(versions => {
            this.versions = versions;
        });
        this.versionsService.selectedVersion$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(selectedVersion => {
                this.selectedVersion = selectedVersion;
            });

        this.editorStateService.renderer$.pipe(takeUntil(this.unsubscribe$)).subscribe(renderer => {
            this.creativeDocument = renderer.creativeDocument;
            this.nodeCreator.setCreative(renderer.creativeDocument);
        });

        this.fontFamiliesService.visibleFontFamilies$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(fontfamilies => (this.fontFamilies = fontfamilies));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async createElement<
        Kind extends ElementKind,
        ElementData = Extract<OneOfElementDataNodes, { kind: Kind }>
    >(
        kind: Kind,
        data: Partial<ElementData> & IBounds,
        options: IElementCreationOptions & ITextCreationOptions = {}
    ): Promise<OneOfElementDataNodes> {
        switch (kind) {
            case ElementKind.Rectangle:
                return this.createRectangle(data, options);
            case ElementKind.Ellipse:
                return this.createEllipse(data, options);
            case ElementKind.Text:
                return this.createText(data, options);
            case ElementKind.Button:
                return this.createButton(data, options);
            case ElementKind.Image:
                return this.createImage(data, options);
            case ElementKind.Widget:
                return this.createWidget(data, options);
            case ElementKind.Video:
                return this.createVideo(data, options);
        }

        throw new Error(`Could not create element of kind ${kind}`);
    }

    createRectangle(
        data: Partial<IRectangleElementDataNode> & IBounds,
        { values }: IElementCreationOptions = {}
    ): IRectangleElementDataNode {
        const node = this.nodeCreator.create(ElementKind.Rectangle, data);

        this.emitElementCreation({
            node,
            values
        });

        return node;
    }

    createEllipse(
        data: Partial<IEllipseElementDataNode> & IBounds,
        { values }: IElementCreationOptions = {}
    ): IEllipseElementDataNode {
        const node = this.nodeCreator.create(ElementKind.Ellipse, data);

        this.emitElementCreation({
            node,
            values,
            elementProperties: []
        });

        return node;
    }

    createText(
        data: Partial<ITextElementDataNode> & IBounds,
        textCreationOptions: ITextCreationOptions = {}
    ): ITextElementDataNode {
        return this.addTextTypeNode({ kind: ElementKind.Text, data, ...textCreationOptions });
    }

    createButton(
        data: Partial<IButtonElementDataNode> & IBounds,
        textCreationOptions: ITextCreationOptions = {}
    ): IButtonElementDataNode {
        return this.addTextTypeNode({ kind: ElementKind.Button, data, ...textCreationOptions });
    }

    private addTextTypeNode<Kind extends ElementKind.Text | ElementKind.Button>({
        data,
        kind,
        values,
        clickedOut = false,
        shouldRescale = true
    }: AddTextTypeOptions<Kind>): ITextDataNode<Kind> {
        const creativeSize = this.editorStateService.size;
        const font = this.getElligibleFontStyle(data.font);
        const nodeData: Partial<ITextDataNode<Kind>> = {
            ...data,
            ...this.getElementPositionFromBox(data),
            font
        };

        const node = this.nodeCreator.create(kind, nodeData);

        // Only text elements should scale https://bannerflow.atlassian.net/browse/STUDIO-7784
        if (!this.isCopyingNode && node.kind === ElementKind.Text) {
            this.scaleText(creativeSize, node, clickedOut, shouldRescale);
        }

        const properties = (values?.elementProperties || []).map(prop =>
            createElementProperty(omit(prop, 'id'))
        );

        this.emitElementCreation({
            node,
            values,
            elementProperties: properties as INewElementProperty[]
        });

        return node as ITextDataNode<Kind>;
    }

    createImage(
        data: Partial<IImageElementDataNode> & INewBaseNode,
        { values, element }: IElementCreationOptions = {}
    ): IImageElementDataNode {
        const properties: INewElementProperty[] = [];

        const feedProperty = element?.properties.find(p => isFeedpathProperty(p))?.value;
        if (feedProperty || data.feed) {
            const feed = data.feed || (deserializePropertyValue('feed', feedProperty) as IFeed);

            properties.push(
                createElementProperty({
                    name: FeededReference.Image,
                    unit: 'id',
                    value: `${feed.id}.${feed.path}`
                })
            );

            data.imageAsset = undefined;
            data.feed = feed;
        } else {
            if (!data.imageAsset) {
                throw new Error('No imageAsset or feed data found for new image node.');
            }

            properties.push({
                clientId: uuidv4(),
                name: AssetReference.Image,
                unit: 'id',
                value: data.imageAsset.id
            });

            data.feed = undefined;
        }

        const name = element?.name || data.name!;

        const nodeData: Partial<IImageElementDataNode> = {
            ...data,
            ...this.getElementPositionFromBox(data),
            name: this.decodeAssetName(name, data.feed !== undefined)
        };

        const node = this.nodeCreator.create(ElementKind.Image, nodeData);

        if (node.imageAsset) {
            ratioLockSvgElement(node.imageAsset.url, node);
        }

        this.emitElementCreation({
            node,
            values,
            elementProperties: properties
        });

        return node;
    }

    async createWidget(
        data: Partial<IWidgetElementDataNode> & INewBaseNode,
        { values, element, skipEmit, inWidgetEditor }: IWidgetCreationOptions = {}
    ): Promise<IWidgetElementDataNode> {
        const isUpdatingFromLibrary = isWidgetNode(data);
        if (isUpdatingFromLibrary) {
            this.isWidgetUpdateFromLibrary = true;
        }

        const creativeset = this.creativesetDataService.creativeset;
        const brandLibrary = this.brandLibraryDataService.brandLibrary;
        element ||= brandLibrary?.elements.find(({ id }) => id === data.parentId);

        if (!element) {
            this.logger.warn('Pasting widget that does no longer exist in the brand library!');

            element = creativeset.elements.find(({ id }) => id === data.id);

            if (!element) {
                throw new Error(
                    `Widget element[${data.name}] was not found in brand library nor creativeset`
                );
            }
        }

        // Get all custom properties. `customProperties` is populated when copy pasting
        const customProperties = data.customProperties ?? mapElementToCustomWidgetProperties(element);

        if (!skipEmit && !inWidgetEditor) {
            for (const customProperty of customProperties) {
                if (customProperty.unit === 'feed' || customProperty.unit === 'text') {
                    this.editorStateService.propertyAsVersionableProperty(
                        customProperty,
                        unitToVersionedUnitMap[customProperty.unit]
                    );
                }
            }
        }

        const resourceUrl = this.featureService.isWidgetContentUrlEnabled()
            ? getWidgetContentUrlOfElement(element)
            : undefined;

        let codeProperties: Omit<WidgetCode, 'ts'>;

        if (resourceUrl) {
            const widgetCode = await getWidgetCodeOfElement(element);
            codeProperties = { ...omit(widgetCode, 'ts') };
        } else {
            const properties = isBFLWidgetElementInstance(element)
                ? element.properties.map(prop => ({
                      ...omit(prop, 'clientId', 'id')
                  }))
                : (element.properties.filter(({ name }) =>
                      isWidgetCodeProperty({ name })
                  ) as IWidgetElementProperty[]);

            const html = properties.find(({ name }) => name === 'html')!.value! as string;
            const css = properties.find(({ name }) => name === 'css')!.value! as string;
            const js = properties.find(({ name }) => name === 'js')!.value! as string;
            codeProperties = {
                html,
                css,
                js
            };
        }

        const nodeData: Partial<IWidgetElementDataNode> = {
            name: element.name,
            ...data,
            ...this.getElementPositionFromBox(data),
            html: codeProperties.html,
            css: codeProperties.css,
            js: codeProperties.js,
            customProperties
        };

        const node = this.nodeCreator.create(ElementKind.Widget, nodeData);

        const versionedProperties = (nodeData.customProperties || [])
            .filter(({ versionPropertyId }) => versionPropertyId)
            .map(createElementProperty);

        const properties = [
            createElementProperty({
                name: 'html',
                unit: 'string',
                value: codeProperties.html
            }),
            createElementProperty({
                name: 'css',
                unit: 'string',
                value: codeProperties.css
            }),
            createElementProperty({
                name: 'js',
                unit: 'string',
                value: codeProperties.js
            })
        ];

        if (resourceUrl) {
            properties.push(
                createElementProperty({
                    name: AssetReference.WidgetContentUrl,
                    unit: 'string',
                    value: resourceUrl
                })
            );
        }

        const isBannerflowLibraryWidget = isAnyBannerflowLibraryWidget(element);
        if (!isBannerflowLibraryWidget) {
            // widgetReference does not exist for new widgets (in widget editor)
            const { name, value, unit } = element.properties.find(hasWidgetReference) || {};

            if (unit !== undefined && name && value) {
                properties.push(
                    createElementProperty({
                        name,
                        unit,
                        value
                    })
                );
            }
        }

        if (!skipEmit) {
            this.emitElementCreation({
                node,
                values,
                elementProperties: [...properties, ...versionedProperties]
            });
        }

        this.isWidgetUpdateFromLibrary = false;

        return node;
    }

    createVideo(
        data: Partial<IVideoElementDataNode> & INewBaseNode,
        { values, element }: IElementCreationOptions = {}
    ): IVideoElementDataNode {
        const properties: INewElementProperty[] = [];

        const feedProperty = element?.properties.find(p => isFeedpathProperty(p))?.value;
        if (feedProperty || data.feed) {
            const feed = data.feed || (deserializePropertyValue('feed', feedProperty) as IFeed);
            feed.type = 'video';

            properties.push({
                clientId: uuidv4(),
                name: 'feededVideoReference',
                unit: 'id',
                value: `${feed.id}.${feed.path}`
            });

            data.videoAsset = undefined;
            data.feed = feed;
        } else {
            if (!data.videoAsset) {
                throw new Error('No videoAsset or feed data found for new video node.');
            }

            properties.push({
                clientId: uuidv4(),
                name: AssetReference.Video,
                unit: 'id',
                value: data.videoAsset.id
            });

            data.feed = undefined;
        }

        const name = element?.name || data.name!;

        const nodeData: Partial<IVideoElementDataNode> = {
            ...data,
            ...this.getElementPositionFromBox(data),
            name: this.decodeAssetName(name, data.feed !== undefined)
        };

        const node = this.nodeCreator.create(ElementKind.Video, nodeData);

        this.emitElementCreation({
            node,
            values,
            elementProperties: properties
        });

        return node;
    }

    createGroup(nodes: OneOfDataNodes[], groupName?: string): void {
        if (!nodes.length) {
            return;
        }

        const creativeNodes = toFlatNodeList(this.creativeDocument).reverse();

        const parentGroup = creativeNodes.find(node => nodes.some(n => n.id === node.id))?.__parentNode;

        const nodeInRoot = nodes.some(n => !n.__parentNode);

        const currentParent = nodeInRoot ? this.creativeDocument : parentGroup || this.creativeDocument;

        if (!canCreateGroup(nodes, this.creativeDocument)) {
            return;
        }

        const groupNode = this.nodeCreator.create(ElementKind.Group, { name: groupName });

        const firstNodeIndex = currentParent.nodes.findIndex(node => nodes.some(n => n.id === node.id));

        nodes.forEach(node => {
            (node.__parentNode || this.creativeDocument).removeNodeById_m(node.id);
            groupNode.addNode_m(node, 0);
        });

        currentParent.addNode_m(groupNode, firstNodeIndex);

        this.emitElementCreation({
            node: groupNode
        });
    }

    async createElementCopy(
        node: OneOfElementDataNodes,
        elementValues: IElementCreationOptions & ITextCreationOptions,
        omitNodeId: boolean
    ): Promise<OneOfElementDataNodes> {
        try {
            this.isCopyingNode = true;

            const nodeData = omitNodeId ? omit(node, 'kind', 'id') : omit(node, 'kind');

            const newNode = await this.createElement(node.kind, nodeData, elementValues);

            if (!newNode) {
                throw new Error(`Could not create element copy`);
            }

            return newNode;
        } catch (e) {
            throw new Error(e as string);
        } finally {
            this.isCopyingNode = false;
        }
    }

    private getElementPositionFromBox(positionAndSize: IBoundingBox): IBoundingBox {
        const centerX = positionAndSize.x + positionAndSize.width / 2;
        const centerY = positionAndSize.y + positionAndSize.height / 2;

        return {
            ...positionAndSize,
            x: centerX - positionAndSize.width / 2,
            y: centerY - positionAndSize.height / 2
        };
    }

    private scaleText(
        creativeSize: CreativeSize,
        textNode: ITextDataNode,
        clickedOut = false,
        shouldRescale = true
    ): void {
        if (creativeSize.width > 500 && clickedOut) {
            const aspectRatio = creativeSize.width / creativeSize.height;
            const calculatedHeight = creativeSize.height * 0.35;
            textNode.width = aspectRatio > 1 ? calculatedHeight * aspectRatio : calculatedHeight;
            textNode.height = calculatedHeight;
            textNode.fontSize = 80;
        }

        if (clickedOut) {
            const xPos = textNode.x || creativeSize.width / 2;
            const yPos = textNode.y || creativeSize.height / 2;
            const centerX = xPos + textNode.width / 2;
            const centerY = yPos + textNode.height / 2;
            textNode.x = centerX - textNode.width;
            textNode.y = centerY - textNode.height;
        } else if (shouldRescale) {
            textNode.fontSize = clamp(Math.round(textNode.height * 0.3), 12, 288);
        }
    }

    private decodeAssetName(name: string, isFeed: boolean): string {
        return isFeed ? decodeURIComponent(name) : name;
    }

    private getElligibleFontStyle(dataFont?: IFontStyle): IFontStyle | undefined {
        const fontFamilies = this.fontFamilies;

        if (fontFamilies.find(fontFamily => fontFamily.id === dataFont?.fontFamilyId)) {
            return dataFont;
        }
        const nonDeletedFonts = getActiveFontsOnThisBrand(
            fontFamilies,
            this.creativesetDataService.brand.id
        );
        const defaultFont = nonDeletedFonts?.[0]?.fontStyles?.[0];
        return defaultFont
            ? {
                  id: defaultFont.id,
                  src: defaultFont.fontUrl,
                  weight: defaultFont.weight,
                  style: defaultFont.italic ? 'italic' : 'normal',
                  fontFamilyId: defaultFont.fontFamilyId!
              }
            : undefined;
    }

    /**
     * Used when adding elements from Brand library.
     * Any element added from Brand library should have new IDs applied
     * to them in order to prevent IDs to be the same on multiple elements
     * @param  {OneOfElementDataNodes} element
     * @returns void
     */
    private setNewStateAndActionIds<Element extends OneOfElementDataNodes>(element: Element): void {
        for (const state of element.states) {
            const newStateId = uuidv4();

            for (const action of element.actions) {
                action.id = uuidv4();
                for (const operation of action.operations) {
                    if (operation.target) {
                        operation.target = element.id;
                    }

                    if (
                        operation.target &&
                        operation.value !== 'undefined' &&
                        operation.value === state.id
                    ) {
                        operation.value = newStateId;
                    }
                }
            }

            for (const animation of element.animations) {
                animation.id = uuidv4();
                for (const keyframe of animation.keyframes) {
                    keyframe.id = uuidv4();
                    if (keyframe.stateId === state.id) {
                        keyframe.stateId = newStateId;
                    }
                }
            }

            state.id = newStateId;
        }
    }

    private emitElementCreation(
        initializedNodeData: Omit<IInitializedNode, 'isCopyPasting' | 'isWidgetUpdateFromLibrary'>
    ): void {
        const node = initializedNodeData.node;
        if (!this.isCopyingNode && !isGroupDataNode(node)) {
            this.setNewStateAndActionIds(node);
        }

        this._create$.next({
            ...initializedNodeData,
            isCopyPasting: this.isCopyingNode,
            isWidgetUpdateFromLibrary: this.isWidgetUpdateFromLibrary
        });
    }
}

type AddTextTypeOptions<Kind extends ElementKind.Text | ElementKind.Button> = ITextCreationOptions & {
    kind: ElementKind.Text | ElementKind.Button;
    data: Partial<ITextDataNode<Kind>> & IBounds;
};
