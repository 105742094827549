import { IElement } from '@domain/creativeset/element';
import { AssetReference, AssetReferenceValue } from '@domain/creativeset/element-asset';
import { ElementKind } from '@domain/elements';
import { FeededReference, FeededReferenceValue } from '@domain/feed';
import { getFileExtension, getFileLocation, getFileName, removeFileExtension } from './url';

export function getAssetReferenceTypeOfElement(element: IElement): AssetReferenceValue {
    switch (element.type) {
        case ElementKind.Image:
            return AssetReference.Image;
        case ElementKind.Video:
            return AssetReference.Video;
        case ElementKind.Widget:
            return AssetReference.Widget;
    }

    throw new Error(`Can't get asset reference name from an element that does not have any assets.`);
}

export function getFeedReferenceTypeOfElement(element: IElement): FeededReferenceValue {
    switch (element.type) {
        case ElementKind.Image:
            return FeededReference.Image;
        case ElementKind.Video:
            return FeededReference.Video;
    }

    throw new Error(`Can't get feed reference name from an element that does not have any assets.`);
}

export function remapCreativeAssetUrls(url: string, destination: string): string | undefined {
    try {
        const assetDomain = new URL(url).origin;
        const originalFileName = url.substring(url.lastIndexOf('/') + 1);

        return `${assetDomain}/${destination}/${originalFileName}`;
    } catch (e) {
        console.error(`Failed to remap url: ${e}`);
    }
}

export function addChecksumToAssetUrl(url: string, checksum: string): string {
    const extension = getFileExtension(url);
    const fileName = removeFileExtension(getFileName(url));
    const fileLocation = getFileLocation(url);

    return `${fileLocation}/${fileName}_${checksum}.${extension}`;
}

export function updateVideoUrlToManifestUrl(url: string, checksum: string): string {
    const fileName = removeFileExtension(getFileName(url));
    const fileLocation = getFileLocation(url);

    return `${fileLocation}/${fileName}_dash_${checksum}/${fileName}.mpd`;
}
