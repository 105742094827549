import { IAsyncState } from '@domain/store/async';
import { Role, UserPermission } from '@domain/user';
import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface UserPartialState {
    readonly [USER_FEATURE_KEY]: UserState;
}

export interface UserState extends IAsyncState {
    isEmployee: boolean;
    permissions: UserPermission[];
    role?: Role;
}

export const initialState: UserState = {
    loaded: false,
    error: undefined,
    isEmployee: false,
    permissions: [],
    role: undefined
};

export const userReducer = createReducer(
    initialState,
    on(UserActions.loadUser, (state): UserState => ({ ...state, loaded: false, error: undefined })),
    on(
        UserActions.loadUserSuccess,
        (state, { user }): UserState => ({
            ...state,
            isEmployee: !!user.isEmployee,
            permissions: user.permissions || [],
            role: user.role,
            loaded: true
        })
    ),
    on(UserActions.loadUserFailure, (state, { error }): UserState => ({ ...state, error }))
);

export function reducer(state: UserState | undefined, action: Action): UserState {
    return userReducer(state, action);
}
