import { parseColor } from '@creative/color.utils';
import { Action, createReducer, on } from '@ngrx/store';
import { PaletteDto } from '@domain/api/generated/sapi';
import { IBrandLocalization, IBrandPalette, IBrandSize, IBrandSizeGroup } from '@domain/brand';
import { IAsyncState } from '@domain/store/async';
import { creativesetShowcaseActions } from '../../creativeset-showcase/state/creativeset-showcase.actions';
import * as BrandActions from './brand.actions';

export const BRAND_FEATURE_KEY = 'brand';

export interface BrandPartialState {
    readonly [BRAND_FEATURE_KEY]: BrandState;
}

export interface BrandState extends IAsyncState {
    id?: string;
    accountSlug?: string;
    slug?: string;
    brandLogoUrl?: string;
    name?: string;
    localizations: IBrandLocalization[];
    sizeFormats: IBrandSize[];
    sizeFormatGroups: IBrandSizeGroup[];
    palettes: IBrandPalette[];
}

export const initialState: BrandState = {
    loaded: false,
    localizations: [],
    sizeFormats: [],
    sizeFormatGroups: [],
    palettes: []
};

export const brandReducer = createReducer(
    initialState,
    on(BrandActions.loadBrand, (state): BrandState => ({ ...state, loaded: false, error: undefined })),
    on(
        BrandActions.loadBrandSuccess,
        (state, { brand }): BrandState => ({
            ...state,
            ...brand,
            brandLogoUrl: brand.brandLogoUrl ?? undefined,
            palettes: brand.palettes.map(mapColorPalette),
            loaded: true
        })
    ),
    on(BrandActions.loadBrandFailure, (state, { error }): BrandState => ({ ...state, error })),

    on(
        creativesetShowcaseActions.loadShowcaseCreativesetSuccess,
        (state, { brand: { id, localizations } }): BrandState => ({
            ...state,
            loaded: true,
            id,
            localizations: localizations as IBrandLocalization[] // we lie in showcase, but it's fine
        })
    )
);

export function reducer(state: BrandState | undefined, action: Action): BrandState {
    return brandReducer(state, action);
}

function mapColorPalette(palette: PaletteDto): IBrandPalette {
    return { name: palette.name, swatch: palette.swatch.map(s => parseColor(s)) };
}
