import { parseColor } from '@creative/color.utils';
import { createRichTextFromSegments } from '@creative/elements/rich-text/text-nodes';
import {
    CreativeDataNode,
    GroupDataNode,
    createVersionedTextFromText,
    isDapiImageNodeDto,
    isDapiTextLikeNodeDto,
    isDapiVideoNodeDto,
    isDapiWidgetNodeDto,
    isDapiWidgetWithAssetNodeDto,
    isGroupDataNode,
    isImageNode,
    isTextDataElement,
    isVideoNode
} from '@creative/nodes';
import {
    deserializeCharacterProperties,
    deserializeGuidelines,
    deserializeOneOfNodes,
    deserializeSocialGuide
} from '@creative/serialization';
import {
    DesignApiElementOverrideOrElement,
    DesignApiOverrideElement,
    OneOfElementDtos
} from '@domain/api/design-api.interop';
import {
    CreativeDto,
    CreativeSetDto,
    EllipseElementDto,
    EllipseElementOverrideDto,
    GroupNodeDto,
    ImageElementDto,
    ImageElementOverrideDto,
    RectangleElementDto,
    RectangleElementOverrideDto,
    SizeDto,
    TextLikeElementDto,
    TextLikeElementOverrideDto,
    TextSegmentDto,
    VersionDto,
    VideoElementDto,
    VideoElementOverrideDto,
    WidgetElementDto,
    WidgetElementOverrideDto
} from '@domain/api/generated/design-api';
import { ImageLibraryAsset, VideoLibraryAsset } from '@domain/brand/brand-library';
import {
    CreativeSize,
    ElementPropertyUnit,
    ICreativeset,
    IDesign,
    IElement,
    IElementProperty
} from '@domain/creativeset';
import { ApprovalStatus, ICreative } from '@domain/creativeset/creative';
import { AssetReference } from '@domain/creativeset/element-asset';
import { IVersion, IVersionProperty, IVersionedText } from '@domain/creativeset/version';
import { ElementKind } from '@domain/elements';
import { FeededReference } from '@domain/feed';
import { OneOfDataNodes, OneOfGroupDataNodes } from '@domain/nodes';
import { OneOfNodesDto } from '@domain/serialization';
import { ICharacterProperties } from '@domain/text';
import { distinctArrayById } from '@studio/utils/array';
import { createElement, createElementProperty } from '@studio/utils/element.utils';
import { uuidv4 } from '@studio/utils/id';

export function convertDesignApiCreativesetToOldModel(creativeSetDto: CreativeSetDto): ICreativeset {
    const versions: IVersion[] = [];
    const elements: IElement[] = [];

    for (const version of creativeSetDto.versions) {
        const { id: versionId, name, targetUrl, elements: versionElements } = version;

        const migratedVersion: IVersion = {
            id: `${versionId}`,
            localization: {
                id: version.localizationId
            },
            name,
            targetUrl,
            properties: []
        };

        for (const elementId of Object.keys(versionElements)) {
            const element = versionElements[elementId];
            const poolElement = creativeSetDto.elementsPool.find(({ id }) => id === elementId);
            if (poolElement && '__textLikeKind' in poolElement) {
                const existingElement = elements.find(({ id }) => id === elementId);

                const existingProperty = existingElement?.properties.find(
                    property => property.name === 'content'
                );

                const versionPropertyId =
                    existingProperty?.versionPropertyId ?? poolElement.legacy_VersionPropertyId;
                if (!versionPropertyId) {
                    throw new Error('VersionPropertyId not found');
                }

                const versionProperty: IVersionProperty = {
                    id: versionPropertyId,
                    name: 'content',
                    value: createVersionedTextFromText(
                        createRichTextFromSegments(element['textSegments'])
                    )
                };

                migratedVersion.properties.push(versionProperty);

                if (!existingElement) {
                    const migratedElement: IElement = createElement({
                        id: elementId,
                        name: poolElement.name,
                        type: getKindFromDesignApiElement(poolElement),
                        properties: [
                            createElementProperty({
                                id: poolElement.legacy_TextElementPropertyId,
                                name: 'content',
                                versionPropertyId: versionProperty.id
                            })
                        ]
                    });
                    elements.push(migratedElement);
                }
            }
        }

        versions.push(migratedVersion);
    }

    const designs: IDesign[] = [];

    const creativeSizes: CreativeSize[] = creativeSetDto.sizes.map(({ id, width, height, name }) => ({
        id: `${id}`,
        width,
        height,
        name
    }));

    const creatives: ICreative[] = creativeSetDto.creatives.map(creative => {
        const creativeSize = creativeSizes.find(({ id }) => id === `${creative.sizeId}`)!;
        const size = creativeSetDto.sizes.find(({ id }) => id === creative.sizeId)!;
        const version = versions.find(({ id }) => id === `${creative.versionId}`)!;
        const apiElements = compileDesignApiElements(creativeSetDto, creative).map(element => {
            if ('__textLikeKind' in element) {
                const isButton = element.isButton;
                if (isButton) {
                    return { ...element, __buttonKind: true };
                }
                return { ...element, __textKind: true };
            }

            return element;
        });

        apiElements.forEach(element => {
            const existingElement = elements.find(({ id: elId }) => elId === element.id);
            if (!existingElement) {
                elements.push(
                    createElement({
                        id: element.id,
                        name: element.name,
                        type: getKindFromDesignApiElement(element),
                        properties: getPropertiesFromDesignApiElement(element)
                    })
                );
            }
        });

        const designDto = creative.design;

        const creativeDataNode = new CreativeDataNode({
            id: `${creative.design?.legacy_DocumentId}`,
            width: size.width,
            height: size.height,
            fill: parseColor(designDto?.fill ?? '#FFFFFF'),
            startTime: designDto?.startTime,
            stopTime: designDto?.stopTime,
            gifExport: designDto?.gifExport,
            guidelines: designDto?.guidelines ? deserializeGuidelines(designDto.guidelines) : undefined,
            socialGuide: deserializeSocialGuide(designDto?.socialGuide),
            loops: designDto?.loops,
            preloadImage: designDto?.preloadImage
        });

        moveNodesToParents(apiElements, creativeDataNode);

        for (const apiElement of apiElements) {
            if (isDapiTextLikeNodeDto(apiElement)) {
                apiElement.textSegments.forEach((segment: TextSegmentDto, index: number) => {
                    const element = elements.find(({ id }) => id === apiElement.id);
                    const node = creativeDataNode.elements.find(({ id }) => id === element!.id);

                    populateCharacterStylesByTextSegment(
                        segment,
                        index,
                        element,
                        node,
                        version,
                        `${creative.design?.legacy_DocumentId}`
                    );
                });
            }
        }

        let design: IDesign | undefined;

        if (designDto?.legacy_DesignId) {
            design = designs.find(d => d.id === designDto.legacy_DesignId);
            if (!design) {
                design = {
                    id: `${designDto.legacy_DesignId}`,
                    elements: elements.filter(element => size.elements[element.id]),
                    hasHeavyVideo: designDto.hasHeavyVideo,
                    name: designDto.name,
                    document: creativeDataNode
                };
                designs.push(design);
            }
        }

        return {
            id: `${creative.id}`,
            checksum: creative.checksum,
            approvalStatus: ApprovalStatus.None,
            connectedCampaigns: [],
            size: creativeSize,
            design,
            version
        };
    });

    const imageAssets: ImageLibraryAsset[] = designs
        .flatMap(({ document }) => document.elements)
        .filter(e => isImageNode(e) && e.imageAsset)
        .map(element => {
            const poolElement = getPoolElement<ImageElementDto>(creativeSetDto, element.id);
            const asset = poolElement.imageAsset;

            return {
                ...asset,
                id: asset.id.toString(),
                height: asset.original.height,
                url: asset.original.url,
                width: asset.original.width
            } satisfies ImageLibraryAsset;
        });

    const videoAssets: VideoLibraryAsset[] = designs
        .flatMap(({ document }) => document.elements)
        .filter(e => isVideoNode(e) && e.videoAsset)
        .map(element => {
            const poolElement = getPoolElement<VideoElementDto>(creativeSetDto, element.id);
            const asset = poolElement.videoAsset;

            return {
                ...asset,
                id: asset.id.toString()
            } satisfies VideoLibraryAsset;
        });

    // sometimes we can have widget element in design, but widget asset is missing in widgets array.
    // In this case we have decided to skip this items as it was originally (647956, 648039, 648041)
    const widgetAssets = creativeSetDto.elementsPool
        .filter(isDapiWidgetWithAssetNodeDto)
        .map(element => element.widgetAsset);

    const defaultVersion = versions.find(({ id }) => id === `${creativeSetDto.defaultVersionId}`);

    if (!defaultVersion) {
        throw new Error(`Could not find default version.`);
    }

    return {
        id: `${creativeSetDto.id}`,
        brandId: creativeSetDto.brandId,
        name: creativeSetDto.name,
        stateId: creativeSetDto.stateId,
        elements,
        designs,
        sizes: creativeSizes,
        images: distinctArrayById(imageAssets),
        videos: distinctArrayById(videoAssets),
        widgets: distinctArrayById(widgetAssets),
        creatives,
        versions,
        defaultVersion
    };
}

function moveNodesToParents(elements: OneOfElementDtos[], creative: CreativeDataNode): void {
    function addToParentOrCreative(node: OneOfDataNodes, parentNodeId?: string): void {
        if (parentNodeId) {
            const parent = creative.findNodeById_m(parentNodeId, true) as OneOfGroupDataNodes;
            parent?.addNode_m(node);
        } else {
            creative.addNode_m(node);
        }
    }

    (function deserializeElements(elementsDtos: OneOfElementDtos[]): void {
        for (const elementDto of elementsDtos) {
            const element = convertToStudioElement(elementDto);
            const images = isDapiImageNodeDto(elementDto) ? [elementDto.imageAsset] : [];
            const videos = isDapiVideoNodeDto(elementDto) ? [elementDto.videoAsset] : [];
            const node = deserializeOneOfNodes(element, {
                head: {
                    asset: {
                        images: images.map(image => ({
                            id: image.id.toString(),
                            height: image.original.height,
                            src: image.original.url,
                            width: image.original.width,
                            name: image.name
                        })),
                        videos: videos.map(video => ({
                            id: video.id.toString(),
                            name: video.name,
                            fileSize: video.fileSize,
                            height: video.height,
                            url: video.url,
                            width: video.width
                        }))
                    },
                    elements: []
                },
                body: {} as any
            });

            if (creative.findNodeById_m(node.id, true)) {
                continue;
            }

            if (isGroupDataNode(node)) {
                const groupNode = new GroupDataNode(node);
                addToParentOrCreative(groupNode, elementDto.parentNodeId);
                deserializeElements(elementsDtos.filter(({ id }) => id !== node.id));
            } else {
                addToParentOrCreative(node, elementDto.parentNodeId);
            }
        }
    })(elements);
}

function getPropertiesFromDesignApiElement(
    element: DesignApiElementOverrideOrElement
): IElementProperty[] {
    if (isDapiWidgetNodeDto(element)) {
        return getWidgetProperties(element);
    }

    if (isDapiVideoNodeDto(element)) {
        return getVideoProperties(element);
    }

    if (isDapiImageNodeDto(element)) {
        return getImageProperties(element);
    }

    return [];
}

function getWidgetProperties(element: WidgetElementDto): IElementProperty[] {
    return (
        element.legacy_WidgetProperties?.map(property =>
            createElementProperty({
                clientId: uuidv4(),
                id: property.id,
                name: property.name,
                unit: property.unit?.toLowerCase() as ElementPropertyUnit,
                value: property.value
            })
        ) || []
    );
}

function getVideoProperties(element: VideoElementDto): IElementProperty[] {
    if (element.legacy_VideoElementPropertyId) {
        return [
            createElementProperty({
                clientId: uuidv4(),
                id: element.legacy_VideoElementPropertyId,
                unit: 'id',
                name: AssetReference.Video,
                value: element.videoAsset.id.toString()
            })
        ];
    }

    if (element.legacy_FeededVideoElementPropertyId && element.legacy_FeededVideoElementPropertyValue) {
        return [
            createElementProperty({
                clientId: uuidv4(),
                id: element.legacy_FeededVideoElementPropertyId,
                unit: 'id',
                name: FeededReference.Video,
                value: element.legacy_FeededVideoElementPropertyValue
            })
        ];
    }

    return [];
}

function getImageProperties(element: ImageElementDto): IElementProperty[] {
    if (element.legacy_ImageElementPropertyId) {
        return [
            createElementProperty({
                clientId: uuidv4(),
                id: element.legacy_ImageElementPropertyId,
                unit: 'id',
                name: AssetReference.Image,
                value: element.imageAsset.id.toString()
            })
        ];
    }

    if (element.legacy_FeededImageElementPropertyId && element.legacy_FeededImageElementPropertyValue) {
        return [
            createElementProperty({
                clientId: uuidv4(),
                id: element.legacy_FeededImageElementPropertyId,
                unit: 'id',
                name: FeededReference.Image,
                value: element.legacy_FeededImageElementPropertyValue
            })
        ];
    }

    return [];
}

function getPoolElement<ElementDto extends OneOfElementDtos>(
    creativeSetDto: CreativeSetDto,
    elementId: string
): ElementDto {
    const poolElement = creativeSetDto.elementsPool.find(({ id }) => id === elementId) as ElementDto;

    if (!poolElement) {
        throw new Error(`Element with id ${elementId} does not exist in Creativeset.ElementPool.`);
    }

    return poolElement;
}

function populateCharacterStylesByTextSegment(
    segment: TextSegmentDto,
    segmentIndex: number,
    element: IElement | undefined,
    node: OneOfDataNodes | undefined,
    version: IVersion,
    creativeId: string
): void {
    const hasStyles = Object.keys(segment.value).length > 0;
    if (!hasStyles) {
        return;
    }

    const contentProperty = element?.properties.find(({ name }) => name === 'content');
    const versionProperty = version?.properties.find(
        ({ id }) => id === contentProperty?.versionPropertyId
    );
    if (!versionProperty) {
        return;
    }

    // We have no other way to match a segment to a span on vp property than by index.
    // Assuming that all spans are present in vp prop & as segment no matter if it has a style or not
    const versionedStyle = (versionProperty.value as IVersionedText).styles[segmentIndex];

    if (versionedStyle) {
        // Apply character styles to node
        const styleId = uuidv4();
        versionedStyle.styleIds[`${creativeId}`] = styleId;

        if (isTextDataElement(node)) {
            const characterProperties = deserializeCharacterProperties(
                segment.value
            ) as Partial<ICharacterProperties>;
            node.characterStyles.set(styleId, characterProperties);
        }
    }
}

function compileDesignApiElements(
    creativeSetDto: CreativeSetDto,
    creative: CreativeDto
): OneOfElementDtos[] {
    const size = creativeSetDto.sizes.find(({ id }) => id === creative.sizeId)!;

    const sortedElementIds = Object.keys(size.elements).sort((a, b) => {
        const sortIndexA = size.elements[a]?.sortIndex ?? 0;
        const sortIndexB = size.elements[b]?.sortIndex ?? 0;
        return sortIndexA - sortIndexB;
    });

    return sortedElementIds.map(elementId =>
        compileDesignApiElement(creativeSetDto, { elementId, creative, size })
    );
}

function compileDesignApiElement(
    creativeSetDto: CreativeSetDto,
    { elementId, creative, size }: CompileElementOptions
): OneOfElementDtos {
    const elementPool = creativeSetDto.elementsPool;
    const versionId = creative.versionId;
    const version = creativeSetDto.versions.find(({ id }) => id === versionId);

    if (!version) {
        throw new Error();
    }

    const rootElement = elementPool.find(({ id }) => id === elementId)!;

    if (isDapiWidgetNodeDto(rootElement)) {
        return getCompiledWidgetElement(rootElement, { size, creative, version });
    }

    return {
        ...rootElement,
        ...getElementOverrides(rootElement, { size, creative, version })
    };
}

function getKindFromDesignApiElement(element: DesignApiElementOverrideOrElement): ElementKind {
    if ('__textLikeKind' in element && element.isButton) {
        return ElementKind.Button;
    }

    const kindDiscriminator = Object.keys(element).find(
        key => key.startsWith('__') && key.endsWith('Kind')
    )!;

    return kindDiscriminator.replace('__', '').replace(/(Like)?Kind/gi, '') as ElementKind;
}

function getCompiledWidgetElement(
    rootElement: WidgetElementDto,
    { creative, size, version }: OverrideOptions
): WidgetElementDto {
    const override = getElementOverrides(rootElement, { size, creative, version });
    override.customProperties = rootElement.customProperties.map(customProperty => {
        const propertyOverride = override.customProperties?.find(
            ({ name }) => name === customProperty.name
        );

        return {
            ...customProperty,
            value: propertyOverride?.value ?? customProperty.value
        };
    });

    return {
        ...rootElement,
        ...override
    };
}

function convertToStudioElement(node: OneOfElementDtos): OneOfNodesDto {
    if (isDapiImageNodeDto(node)) {
        return { ...node, imageAssetId: node.imageAsset.id.toString() };
    }
    if (isDapiVideoNodeDto(node)) {
        return { ...node, videoAssetId: node.videoAsset.id.toString() };
    }
    return node as OneOfNodesDto;
}

function getElementOverrides<Element extends OneOfElementDtos>(
    rootElement: Element,
    { creative, size, version }: OverrideOptions
): InferredElementOverride<Element> {
    const sizeElement: undefined | DesignApiOverrideElement = size.elements[rootElement.id];
    const versionElement: undefined | DesignApiOverrideElement = version.elements[rootElement.id];
    const creativeElement: undefined | DesignApiOverrideElement = creative.elements[rootElement.id];

    return {
        ...sizeElement,
        ...versionElement,
        ...creativeElement
    } as InferredElementOverride<Element>;
}

type CompileElementOptions = {
    elementId: string;
    size: SizeDto;
    creative: CreativeDto;
};

type OverrideOptions = {
    size: SizeDto;
    version: VersionDto;
    creative: CreativeDto;
};

type RectangleElement<E extends OneOfElementDtos> = E extends RectangleElementDto
    ? RectangleElementOverrideDto
    : never;
type EllipseElement<E extends OneOfElementDtos> = E extends EllipseElementDto
    ? EllipseElementOverrideDto
    : never;
type ImageElement<E extends OneOfElementDtos> = E extends ImageElementDto
    ? ImageElementOverrideDto
    : never;
type VideoElement<E extends OneOfElementDtos> = E extends VideoElementDto
    ? VideoElementOverrideDto
    : never;
type WidgetElement<E extends OneOfElementDtos> = E extends WidgetElementDto
    ? WidgetElementOverrideDto
    : never;
type TextElement<E extends OneOfElementDtos> = E extends TextLikeElementDto
    ? TextLikeElementOverrideDto
    : never;
type GroupElement<E extends OneOfElementDtos> = E extends GroupNodeDto ? GroupNodeDto : never;

type InferredElementOverride<E extends OneOfElementDtos> =
    | RectangleElement<E>
    | EllipseElement<E>
    | ImageElement<E>
    | VideoElement<E>
    | WidgetElement<E>
    | TextElement<E>
    | GroupElement<E>;
